import { DashboardButton } from './DashboardButton';

import ActionBox from './ActionBox';

import CreateContractDialog from 'components/common/Contract/CreateContractDialog';
import { useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { IChatUserThread } from 'global/interfaces/chatThread';

export default function ActionCreateContract() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [openCreateContractDialog, setOpenCreateContractDialog] = useState(false);
  const [selectedThread, setSelectedThread] = useState<IChatUserThread>();

  return (
    <ActionBox
      title="Create contracts"
      body={'Create fixed price or retainer contracts with secure escrow protection for peace of mind.'}
    >
      <DashboardButton variant="contained" onClick={() => setOpenCreateContractDialog(true)}>
        Create contract
      </DashboardButton>
      <CreateContractDialog
        setOpenDialog={setOpenCreateContractDialog}
        fullWidth={isMobile}
        open={openCreateContractDialog}
        selectedThread={selectedThread}
        setSelectedThread={setSelectedThread}
        returnUrl={`dashboard`}
      />
    </ActionBox>
  );
}

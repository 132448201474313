import { FormControl, FormGroup, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import CategorySelect from 'components/common/Select/CategorySelect';
import { FastField, Field, FieldProps, FormikErrors, FormikProps } from 'formik';
import { IEditableUser } from 'global/interfaces/user';
import { nameof } from 'ts-simple-nameof';
import SkillsMultiSelect from 'components/common/Select/SkillsMultiSelect';
import { useEffect, useState } from 'react';
import { ISkill } from 'global/interfaces/skill';
import { getSkills } from 'services/skillService';
import { showError } from 'utils/errorHandler';
import IApiError from 'global/interfaces/api';

const pageSize = 10;

interface ICategoriesSkillProps {
  form: FormikProps<IEditableUser>;
}

export const CategoriesSkillSection: React.FC<ICategoriesSkillProps> = ({ form }) => {
  const [skills, setSkills] = useState<ISkill[]>([]);
  const [skillsLoading, setSkillsLoading] = useState<boolean>(false);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const usedSkills = form.values.skills.map(s => s.id);
  const filteredSkills = skills.filter(s => usedSkills.indexOf(s.id) < 0).slice(0, pageSize);

  const fetchSkills = (value?: string) => {
    setSkillsLoading(true);
    getSkills({ search: value, pageSize: pageSize * 2 })
      .then((retrievedSkills: ISkill[]) => {
        setSkills([...retrievedSkills]);
        setSkillsLoading(false);
      })
      .catch((err: IApiError) => {
        showError(err);
      });
  };

  useEffect(() => {
    if (form.dirty) {
      fetchSkills();
    }
  }, [form.dirty]);

  const formatSkillsError = (error: string | string[] | FormikErrors<ISkill>[] | undefined) => {
    if (typeof error === 'string') {
      return error;
    }

    if (Array.isArray(error)) {
      for (let i = 0; i < error.length; i++) {
        const singleError = error[i];
        if (singleError) {
          return typeof singleError === 'string' ? singleError : singleError.value;
        }
      }
    }
  };

  return (
    <Grid container columnSpacing={isMobileScreen ? 1 : 2.5} rowSpacing={3}>
      <Grid item xs={12}>
        <Typography variant={'h6'}>Category</Typography>
        <Typography variant="body2" mt="4px">
          {form.values.needsVettingValidation ? 'Required: ' : 'Recommended: '}Choose a category that best describes
          your services.
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormGroup>
          <FormControl>
            <FastField name={`${nameof<IEditableUser>(x => x.mainCategory)}`}>
              {({ field, form: { handleChange }, meta }: FieldProps) => (
                <CategorySelect
                  value={field.value ?? ''}
                  name={field.name}
                  onChange={handleChange}
                  label="Category"
                  variant={'outlined'}
                  error={meta.touched && Boolean(meta.error)}
                  helpertext={meta.touched ? meta.error : ''}
                />
              )}
            </FastField>
          </FormControl>
        </FormGroup>
      </Grid>

      <Grid item xs={12} mt={2}>
        <Typography variant={'h6'}>Skills</Typography>
        <Typography variant="body2" mt="4px">
          {form.values.needsVettingValidation ? 'Required: ' : 'Recommended: '}Enter 3-8 skills. These are used to match
          you with buyers so pick the most relevant ones.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <FormGroup>
          <FormControl variant="standard">
            <Field name={nameof<IEditableUser>(x => x.skills)}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <SkillsMultiSelect
                  options={filteredSkills}
                  values={field.value}
                  onChange={changedSkills =>
                    setFieldValue(
                      nameof<IEditableUser>(x => x.skills),
                      changedSkills,
                    )
                  }
                  onInputChange={fetchSkills}
                  loading={skillsLoading}
                  error={meta.touched && Boolean(meta.error)}
                  helpertext={meta.touched ? formatSkillsError(form.errors.skills) : ''}
                />
              )}
            </Field>
          </FormControl>
        </FormGroup>
      </Grid>
    </Grid>
  );
};

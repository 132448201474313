import { SvgIcon, SvgIconProps } from '@mui/material';

export const SellIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10.8333 4.16667C10.8333 5.08714 8.78126 5.83333 6.24996 5.83333C3.71865 5.83333 1.66663 5.08714 1.66663 4.16667M10.8333 4.16667C10.8333 3.24619 8.78126 2.5 6.24996 2.5C3.71865 2.5 1.66663 3.24619 1.66663 4.16667M10.8333 4.16667V5.41667M1.66663 4.16667V14.1667C1.66663 15.0871 3.71865 15.8333 6.24996 15.8333M6.24996 9.16667C6.10951 9.16667 5.97053 9.16437 5.83329 9.15987C3.49725 9.08332 1.66663 8.3694 1.66663 7.5M6.24996 12.5C3.71865 12.5 1.66663 11.7538 1.66663 10.8333M18.3333 9.58333C18.3333 10.5038 16.2813 11.25 13.75 11.25C11.2187 11.25 9.16663 10.5038 9.16663 9.58333M18.3333 9.58333C18.3333 8.66286 16.2813 7.91667 13.75 7.91667C11.2187 7.91667 9.16663 8.66286 9.16663 9.58333M18.3333 9.58333V15.8333C18.3333 16.7538 16.2813 17.5 13.75 17.5C11.2187 17.5 9.16663 16.7538 9.16663 15.8333V9.58333M18.3333 12.7083C18.3333 13.6288 16.2813 14.375 13.75 14.375C11.2187 14.375 9.16663 13.6288 9.16663 12.7083"
      stroke={props.style?.color ?? 'white'}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

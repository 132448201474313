import {
  Avatar,
  FormHelperText,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { AccountSearchParam, EmailSearchParam, ReferralIdSearchParam } from 'global/Constants/SearchParamConstants';
import { AccountTypeOptions } from 'global/enums/accountTypeOptions';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BuyIcon } from 'components/icon/BuyIcon';
import { SellIcon } from 'components/icon/SellIcon';
import { WelcomeGraphicContainer } from 'components/common/WelcomeGraphicContainer';
import { useTitle } from 'utils/router';
import { NavigateNextOutlined } from '@mui/icons-material';
import { RoundButton } from 'components/common/Button/RoundButton';

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[200]}`,
  backgroundColor: theme.palette.common.white,
  padding: '16px',
  borderRadius: '12px',
  '&.Mui-selected': {
    border: `1px solid ${theme.palette.primary[300]}`,
    backgroundColor: '#F7F8FD',
  },
  '&.Mui-selected:hover': {
    border: `1px solid ${theme.palette.primary[400]}`,
    backgroundColor: '#F7F8FD',
  },
  '&:hover': {
    border: `1px solid ${theme.palette.primary[300]}`,
    backgroundColor: '#F7F8FD',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '16px',
  },
}));

export const StyledList = styled(List)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  marginTop: '48px',
}));

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  [theme.breakpoints.down('sm')]: {
    height: '34px',
    width: '34px',
  },
}));

const StyledListItemAvatar = styled(ListItemAvatar)(({ theme }) => ({
  minWidth: theme.spacing(7),

  [theme.breakpoints.down('sm')]: {
    minWidth: theme.spacing(5.5),
  },
}));

const AccountType: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [accountType, setAccountType] = useState<AccountTypeOptions | undefined>();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const initialEmail = searchParams.get(EmailSearchParam) || '';
  const referralId = searchParams.get(ReferralIdSearchParam) || null;

  useTitle('Sign Up');
  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleNextClick = () => {
    setIsSubmitted(true);
    if (accountType !== undefined) {
      let url = `/signup/details?${AccountSearchParam}=${accountType}`;
      if (initialEmail !== undefined && initialEmail !== '') {
        url = url + `&${EmailSearchParam}=${encodeURIComponent(initialEmail)}`;
      }
      if (referralId !== undefined && referralId !== null) {
        url = url + `&${ReferralIdSearchParam}=${referralId}`;
      }
      navigate(url);
    }
  };

  const handleCancelClick = () => {
    navigate('/');
  };

  return (
    <WelcomeGraphicContainer>
      <Stack paddingX={{ xs: 0, sm: 1 }} direction="column" minWidth={{ xs: '100%', sm: 350 }} maxWidth={{ sm: 600 }}>
        <Typography textAlign="center" variant={isMobile ? 'h5' : 'h4'}>
          Welcome to Shoutt
        </Typography>
        <Typography variant="body1" mt={3} textAlign="center">
          Your Shoutt account allows you to buy and sell from one place. You can always change later.
        </Typography>
        <StyledList disablePadding>
          <ListItem disablePadding>
            <StyledListItemButton
              selected={accountType === AccountTypeOptions.Buyer}
              onClick={() => setAccountType(AccountTypeOptions.Buyer)}
            >
              <StyledListItemAvatar>
                <StyledAvatar>
                  <BuyIcon />
                </StyledAvatar>
              </StyledListItemAvatar>
              <ListItemText primary={<Typography variant="subtitle1">Buy services</Typography>} />
            </StyledListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <StyledListItemButton
              selected={accountType === AccountTypeOptions.Seller}
              onClick={() => setAccountType(AccountTypeOptions.Seller)}
            >
              <StyledListItemAvatar>
                <StyledAvatar>
                  <SellIcon />
                </StyledAvatar>
              </StyledListItemAvatar>
              <ListItemText primary={<Typography variant="subtitle1">Sell services</Typography>} />
            </StyledListItemButton>
          </ListItem>
        </StyledList>
        {isSubmitted && accountType === undefined && (
          <FormHelperText error sx={{ marginTop: 2 }}>
            Select an account type
          </FormHelperText>
        )}

        <Stack
          justifyContent={'space-between'}
          alignItems={'center'}
          direction={{ xs: 'column-reverse' }}
          marginTop={{ xs: 6 }}
          gap={{ xs: 4 }}
          width="100%"
        >
          <RoundButton
            variant="text"
            color="inherit"
            type="button"
            size={isMobile ? 'small' : 'large'}
            onClick={handleLoginClick}
            fullWidth
          >
            Already have an account? Sign in
          </RoundButton>
          <Stack direction={'row'} gap={1} width="100%">
            <RoundButton variant="outlined" type="button" size="medium" onClick={handleCancelClick} fullWidth>
              Cancel
            </RoundButton>

            <RoundButton
              endIcon={<NavigateNextOutlined />}
              variant="contained"
              type="button"
              size="medium"
              onClick={handleNextClick}
              fullWidth
            >
              Next
            </RoundButton>
          </Stack>
        </Stack>
      </Stack>
    </WelcomeGraphicContainer>
  );
};

export default AccountType;

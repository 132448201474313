import { IChatMessageDisplay } from 'global/interfaces/chatMessage';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, styled, useTheme } from '@mui/material';
import { UserAuth } from 'contexts/AuthContext';

import { ContractType, IContract, IContractDisplay, RecurrenceType } from 'global/interfaces/contract';
import { getContract, updateContract } from 'services/contractService';
import IApiError from 'global/interfaces/api';
import { showError } from 'utils/errorHandler';
import { SwipeableContractDrawer } from 'components/common/Contract/SwipeableContractDrawer';
import { countriesWithCurrency } from 'components/common/Select/CountrySelect';

import ContractAcceptButtons from 'components/common/Contract/contractAcceptButtons';

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[900],
}));

interface IChatMessagAcceptContractProps extends IChatMessageDisplay {
  user: UserAuth | null;
}

export default function ChatMessagAcceptContract(props: IChatMessagAcceptContractProps): JSX.Element {
  const theme = useTheme();
  // Buyers ask for acceptance so they post the message
  const isBuyer = props.user?.id === props.userId;
  // Seller is the person that receives the message
  const isSeller = props.user?.id !== props.userId;

  const [contractDrawerOpen, setContractDrawerOpen] = React.useState<boolean>(false);
  const [contract, setContract] = React.useState<IContract>();

  const viewContract = (contractId: string) => {
    getContract(contractId)
      .then((res: IContractDisplay) => {
        updateContract(res);
        setContract(res);
        setContractDrawerOpen(true);
      })
      .catch((err: IApiError) => {
        showError(err);
      });
  };

  const currencySymbol: string =
    countriesWithCurrency.find(c => c.currency === props.contract?.currency.toUpperCase())?.currencySymbol ?? '';

  return (
    <React.Fragment>
      <Box>
        {isSeller && (
          <>
            <StyledTypography variant="body2">
              {props.userDisplayName} has offered you {currencySymbol}
              {props.contract?.amount}
              {(props.contract?.contractType === ContractType.Retainer && props.contract.recurrence) ===
                RecurrenceType.Monthly && ' per month'}
              {(props.contract?.contractType === ContractType.Retainer && props.contract.recurrence) ===
                RecurrenceType.Weekly && ' per week'}{' '}
              for the {props.contract?.contractType === ContractType.FixedPrice && 'fixed price'}
              {props.contract?.contractType === ContractType.Retainer && 'retainer'} contract{' '}
              <span
                style={{ textDecoration: 'underline', color: theme.palette.primary.dark, cursor: 'pointer' }}
                onClick={() => viewContract(props.contract!.contractId)}
              >
                {props.contract?.name}
              </span>
              {'. '}
              Please review the contract and accept or decline. {props.userDisplayName} will be asked for payment after
              the contract is accepted. The contract will be activated when payment is received.
            </StyledTypography>
          </>
        )}
        {isBuyer && (
          <StyledTypography variant="body2">
            You have sent a contract offer of {currencySymbol}
            {props.contract?.amount}
            {(props.contract?.contractType === ContractType.Retainer && props.contract.recurrence) ===
              RecurrenceType.Monthly && ' per month'}
            {(props.contract?.contractType === ContractType.Retainer && props.contract.recurrence) ===
              RecurrenceType.Weekly && ' per week'}{' '}
            for the {props.contract?.contractType === ContractType.FixedPrice && 'fixed price'}
            {props.contract?.contractType === ContractType.Retainer && 'retainer'} contract{' '}
            <span
              style={{ textDecoration: 'underline', color: theme.palette.primary.dark, cursor: 'pointer' }}
              onClick={() => viewContract(props.contract!.contractId)}
            >
              {props.contract?.name}
            </span>
            {'. '}
            You will be asked for payment after the contract is accepted. The contract will be activated when payment is
            received.
          </StyledTypography>
        )}
      </Box>
      {isSeller && !props.actioned && props.contract !== undefined && (
        <ContractAcceptButtons
          contractId={props.contract.contractId}
          contractName={props.contract.name}
          returnUrl={`workroom/${props.chatThreadId}`}
        />
      )}
      {isSeller && props.actioned && (
        <Typography variant="caption" color="grey.500" fontStyle="italic" fontWeight={600}>
          You have already accepted or declined this contract.
        </Typography>
      )}
      {contract && (
        <SwipeableContractDrawer contract={contract} drawer={contractDrawerOpen} setDrawer={setContractDrawerOpen} />
      )}
    </React.Fragment>
  );
}

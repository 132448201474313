import { ConciergeConfirmation } from 'components/concierge/conciergeConfirmation';
import ConciergeForm from 'components/concierge/conciergeForm';
import { useState } from 'react';
import { useTitle } from 'utils/router';

export default function Concierge() {
  useTitle('Post a Job');
  const [submitted, setSubmitted] = useState(false);
  return <>{!submitted ? <ConciergeForm setSubmitted={setSubmitted} /> : <ConciergeConfirmation />}</>;
}

import { SvgIcon, SvgIconProps } from '@mui/material';

export const CheckIcon = (props: SvgIconProps) => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
    <rect width="32" height="32" rx="16" fill="#7ADC8F" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.7953 9.85346L13.2487 19.0668L10.7153 16.3601C10.2487 15.9201 9.51534 15.8935 8.982 16.2668C8.462 16.6535 8.31534 17.3335 8.63534 17.8801L11.6353 22.7601C11.9287 23.2135 12.4353 23.4935 13.0087 23.4935C13.5553 23.4935 14.0753 23.2135 14.3687 22.7601C14.8487 22.1335 24.0087 11.2135 24.0087 11.2135C25.2087 9.9868 23.7553 8.9068 22.7953 9.84013V9.85346Z"
      fill="white"
    />
  </SvgIcon>
);

import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import DoneIcon from '@mui/icons-material/Done';
import { Grid, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { StyledCTAButtton, StyledCTALightButtton } from './CTAs';
import EastIcon from '@mui/icons-material/East';
import { useContext } from 'react';
import { AuthContext } from 'contexts/AuthContext';

export interface PriceFeature {
  text: string;
}

interface PricingTableProps {
  freefeatures: PriceFeature[];
  premfeatures: PriceFeature[];
  currencySybmol: string;
  premPrice: number;
}

const StyledPriceBox = styled(Box)(() => ({
  padding: '40px 32px 40px 32px',
  borderRadius: '24px',
  borderStyle: 'solid',
  borderWidth: '1px',
  width: '100%',
  maxWidth: '500px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const StyledFreePriceBox = styled(StyledPriceBox)(() => ({
  backgroundColor: '#FFFFFF66',
  borderColor: '#FFFFFF',
}));

const StyledPremiumPriceBox = styled(StyledPriceBox)(() => ({
  backgroundColor: '#101828',
}));

const PriceTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginTop: '16px',
  fontSize: '40px',

  [theme.breakpoints.up('md')]: {
    fontSize: '60px',
  },
}));

const PriceFeatureTypography = styled(Typography)(({ theme }) => ({
  lineHeight: '140%',
  fontSize: '16px',
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '20px',
  },
}));

const PriceFreeFeatureTypography = styled(PriceFeatureTypography)(() => ({
  color: '#121F31',
}));

const PricePremFeatureTypography = styled(PriceFeatureTypography)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const StyledDoneIcon = styled(DoneIcon)(() => ({
  width: '26px',
  height: '26px',
  color: '#121F31',
  marginRight: '16px',
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: '0px',
  marginTop: '5px',
  [theme.breakpoints.down('md')]: {
    marginTop: '2px',
  },
}));

export default function PricingTable({ freefeatures, premfeatures, currencySybmol, premPrice }: PricingTableProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const handleButtonClick = (): void => {
    navigate('/signup');
  };

  return (
    <Grid container spacing={{ xs: 0, md: 4 }} rowSpacing={4}>
      <Grid item xs={12} md={6} container justifyContent={{ xs: 'center', md: 'flex-end' }}>
        <StyledFreePriceBox>
          <Box>
            <Typography variant="h6" fontWeight={600}>
              Free Account
            </Typography>
            <PriceTypography variant="h2">{currencySybmol}0</PriceTypography>
            <Typography variant="body1" fontWeight={500} color={'#121F31'}>
              per Month
            </Typography>
            <PriceFreeFeatureTypography mt={2} variant="body1">
              All this for free:
            </PriceFreeFeatureTypography>
            <List>
              {freefeatures.map((feature: PriceFeature, p: number) => (
                <ListItem key={p} alignItems="flex-start" disableGutters dense>
                  <StyledListItemIcon>
                    <StyledDoneIcon />
                  </StyledListItemIcon>
                  <ListItemText>
                    <PriceFreeFeatureTypography variant="body1">{feature.text}</PriceFreeFeatureTypography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
          {!authContext.user && (
            <StyledCTAButtton
              variant="contained"
              sx={{
                mt: 2,
                width: '100%',
              }}
              onClick={handleButtonClick}
              endIcon={<EastIcon />}
            >
              Get Started
            </StyledCTAButtton>
          )}
        </StyledFreePriceBox>
      </Grid>
      <Grid item xs={12} md={6} container justifyContent={{ xs: 'center', md: 'flex-start' }}>
        <StyledPremiumPriceBox>
          <Box>
            <Typography variant="h6" fontWeight={600} color={theme.palette.common.white}>
              Premium Account
            </Typography>
            <PriceTypography variant="h2" color={theme.palette.common.white}>
              {currencySybmol}
              {premPrice}
            </PriceTypography>
            <Typography variant="body1" fontWeight={500} color={theme.palette.common.white}>
              per Month
            </Typography>
            <PricePremFeatureTypography mt={2} variant="body1">
              Everything in the free account plus:
            </PricePremFeatureTypography>
            <List>
              {premfeatures.map((feature: PriceFeature, p: number) => (
                <ListItem key={p} alignItems="flex-start" disableGutters dense>
                  <StyledListItemIcon>
                    <StyledDoneIcon sx={{ color: theme.palette.common.white }} />
                  </StyledListItemIcon>
                  <ListItemText>
                    <PricePremFeatureTypography variant="body1">{feature.text}</PricePremFeatureTypography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
          {!authContext.user && (
            <StyledCTALightButtton
              variant="contained"
              sx={{
                mt: 2,
                width: '100%',
              }}
              onClick={handleButtonClick}
              endIcon={<EastIcon />}
            >
              Get Started
            </StyledCTALightButtton>
          )}
        </StyledPremiumPriceBox>
      </Grid>
    </Grid>
  );
}

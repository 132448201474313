import { Dialog, Grid, IconButton, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import { Dispatch } from 'react';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { CompanyAutoComplete } from 'components/common/CompanyAutoComplete';
import { useFormik } from 'formik';
import { INotableClient } from 'global/interfaces/INotableClient';
import { RoundButton } from 'components/common/Button/RoundButton';
import { IClearbitResponse } from 'global/interfaces/clearbitResponse';
import { notableClientValidation } from 'global/validations/notableClient';

interface INotableClientDialogProps {
  open: boolean;
  setOpen: Dispatch<boolean>;
  notableClients: INotableClient[];
  onUpdateNotableClients: (clients: INotableClient[]) => void;
  editIndex: number;
  setEditIndex: Dispatch<number>;
  selectedCompany: INotableClient | undefined;
}

export const NotableClientDialog = ({
  open,
  setOpen,
  notableClients,
  onUpdateNotableClients,
  selectedCompany,
  editIndex,
  setEditIndex,
}: INotableClientDialogProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const handleClose = (): void => {
    setEditIndex(-1);
    setOpen(false);
  };

  const form = useFormik<INotableClient>({
    enableReinitialize: true,
    initialValues: {
      id: selectedCompany?.id ?? -1,
      name: selectedCompany?.name ?? '',
      domain: selectedCompany?.domain ?? '',
      logo: selectedCompany?.logo ?? '',
      description: selectedCompany?.description ?? '',
      role: selectedCompany?.role ?? '',
    },
    onSubmit: (values: INotableClient) => {
      if (editIndex !== -1) {
        notableClients[editIndex] = values;
        onUpdateNotableClients([...notableClients]);
      } else {
        onUpdateNotableClients([...notableClients, values]);
      }
      form.resetForm();
      setOpen(false);
    },
    validationSchema: notableClientValidation,
  });

  const onCompanyChanged = (company: IClearbitResponse) => {
    form.setFieldValue('name', company?.name);
    form.setFieldValue('domain', company?.domain);
    form.setFieldValue('logo', company?.logo);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
      fullScreen={isMobileScreen}
    >
      <form onSubmit={form.handleSubmit}>
        <StyledDialogTitle>
          <Typography color="grey.900" variant={isMobileScreen ? 'h6' : 'h5'}>
            {editIndex > -1 ? 'Edit Notable Experience' : 'Add a Notable Experience'}
          </Typography>
          <IconButton onClick={handleClose}>
            <ClearOutlinedIcon />
          </IconButton>
        </StyledDialogTitle>
        <StyledDialogContent dividers>
          <Grid container columnSpacing={2.5} rowSpacing={2}>
            <Grid item xs={12}>
              <CompanyAutoComplete
                value={form.values.name ?? ''}
                onCompanyChanged={onCompanyChanged}
                error={form.touched.name !== undefined && Boolean(form.errors.name)}
                helperText={form.touched.name !== undefined ? form.errors.name : ''}
                logo={form.values.logo}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="role"
                label="Role *"
                value={form.values.role}
                onChange={form.handleChange}
                error={form.touched.role !== undefined && Boolean(form.errors.role)}
                helperText={form.touched.role !== undefined ? form.errors.role : ''}
                size="medium"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="description"
                label="What did you do there?"
                value={form.values.description}
                onChange={form.handleChange}
                error={form.touched.description !== undefined && Boolean(form.errors.description)}
                helperText={form.touched.description !== undefined ? form.errors.description : ''}
                size="medium"
                fullWidth
                rows={8}
                multiline
              />
            </Grid>
          </Grid>
        </StyledDialogContent>
        <StyledDialogActions>
          <RoundButton fullWidth={isMobileScreen} variant="outlined" onClick={handleClose}>
            Cancel
          </RoundButton>
          <RoundButton fullWidth={isMobileScreen} variant="contained" type="submit">
            {editIndex > -1 ? 'Ok' : 'Add'}
          </RoundButton>
        </StyledDialogActions>
      </form>
    </Dialog>
  );
};

import { Box, Dialog, styled, useMediaQuery, useTheme } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { ContractType, ContractViewType, ICreateContractForm, RecurrenceType } from 'global/interfaces/contract';
import { createContractValidation } from 'global/validations/contract';
import React, { Dispatch, useContext, useState } from 'react';
import { getPayout } from 'services/payoutService';
import { IStripeConnect } from 'global/interfaces/payout';
import IApiError from 'global/interfaces/api';
import { showError } from 'utils/errorHandler';
import { createContract } from 'services/contractService';
import { getUser } from 'services/userService';
import { IUser } from 'global/interfaces/user';
import { CreateFixedPriceContract } from './CreateFixedPriceContract';
import { TeamChatSelectUser } from './TeamChatSelectUser';
import { ChatType, IChatUserThread } from 'global/interfaces/chatThread';
import { AuthContext } from 'contexts/AuthContext';
import { countriesWithCurrency, fallbackContractCountry } from '../Select/CountrySelect';
import AddProfileDetails from './AddProfileDetails';
import { CreateRetainerContract } from './CreateRetainerContract';
import { ChoosePackage } from './ChoosePackage';
import { SelectConnection } from './SelectConnection';
import { useCreateContractDialog } from 'global/hooks/useCreateContractDialog';
import { useNavigate } from 'react-router-dom';
import { ChooseBuySell } from './ChooseBuySell';
import AddPayoutDetails from './AddPayoutDetails';
import { nameof } from 'ts-simple-nameof';
import SubscriptionDialog from './SubscriptionDialog';
export const JustifiedBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export enum CreateContractStep {
  SelectType,
  SelectUserInTeam,
  FillData,
  InviteUser,
  BuyerOrSeller,
  AddPayoutDetails,
}

interface ICreateContractProps {
  fullWidth?: boolean;
  setOpenDialog: Dispatch<boolean>;
  selectedThread?: IChatUserThread | undefined;
  setSelectedThread?: Dispatch<IChatUserThread | undefined>;
  open: boolean;
  returnUrl?: string;
}

export const FIXED_PRICE_PACKAGE = 1;
export const RETAINER_PACKAGE = 2;

export default function CreateContractDialog(props: ICreateContractProps): JSX.Element {
  const [stripeAccount, setStripeAccount] = React.useState<IStripeConnect | null>(null);
  const [user, setUser] = React.useState<IUser | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isProfileDetailsValid, setIsProfileDetailsValid] = useState(false);
  const { openContractDialog } = useCreateContractDialog();
  const [isCreateRedirect, setIsCreateRedirect] = useState(false);
  const [redirectToThread, setRedirectToThread] = useState(false);
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const authContext = useContext(AuthContext);
  const subscriptionActive: boolean = authContext.user?.subscriptionActive ?? false;

  const multiRecipients =
    props.selectedThread?.type == ChatType.Group
      ? props.selectedThread?.recipients.filter(r => r.userId != authContext.user?.id && r.active)
      : undefined;

  const [viewPackage, setViewPackage] = React.useState<CreateContractStep>(CreateContractStep.BuyerOrSeller);

  const handleClose = (): void => {
    setIsCreateRedirect(false);

    props.setOpenDialog(false);
    props.setSelectedThread && props.setSelectedThread(undefined);
    form.resetForm();

    form.setFieldValue(
      nameof<ICreateContractForm>(x => x.currency),
      countriesWithCurrency.find(c => c.code === user?.country)?.currency ?? fallbackContractCountry.currency,
    );
    setLoading(false);
  };

  const form = useFormik<ICreateContractForm>({
    enableReinitialize: false,
    initialValues: {
      name: '',
      buySell: null,
      contractType: null,
      deliverables: [''],
      price: null,
      delivery: 7,
      revisions: 2,
      otherUserId: props.selectedThread?.otherUserId,
      termsAccepted: false,
      currency:
        countriesWithCurrency.find(c => c.currency === user?.currency)?.currency ?? fallbackContractCountry.currency,
      recurrence: RecurrenceType.Monthly,
      duration: -1,
      description: null,
      release: 1.0,
    },
    validationSchema: createContractValidation,
    onSubmit: (values: ICreateContractForm) => {
      setLoading(true);
      createContract(values, props.selectedThread?.chatThreadId)
        .then(() => {
          if (redirectToThread) {
            navigate(`/workroom/${props.selectedThread?.chatThreadId}`);
          }
          handleClose();
        })
        .catch(showError);
    },
  });

  const handleCompleteProfilePic = (): void => {
    getUserFromAPI();
  };

  const getUserFromAPI = (): void => {
    getUser()
      .then((res: IUser) => {
        setUser(res);
        if (res?.title && res?.profileImage) {
          setIsProfileDetailsValid(true);
        }
        form.setFieldValue(
          nameof<ICreateContractForm>(x => x.currency),
          countriesWithCurrency.find(c => c.currency === user?.currency)?.currency ?? fallbackContractCountry.currency,
        );
      })
      .catch((err: IApiError) => {
        if (err.status !== 404) {
          showError(err);
        }
      });
  };

  const refreshUserAndPayout = () => {
    getUserFromAPI();

    getPayout()
      .then((res: IStripeConnect) => {
        setStripeAccount(res);
        if (openContractDialog) {
          props.setOpenDialog(true);
          form.setFieldValue(
            nameof<ICreateContractForm>(x => x.buySell),
            ContractViewType.Sell,
          );
          buySellNext(true, res !== null && res?.payoutsEnabled && res?.ordersEnabled);
        }
      })
      .catch(showError);
  };

  React.useEffect(() => {
    if (props.open) {
      refreshUserAndPayout();
    }
  }, [props.open]);

  React.useEffect(() => {
    refreshUserAndPayout();
    setViewPackage(CreateContractStep.BuyerOrSeller);
  }, []);

  const buySellNavigateNext = () => {
    buySellNext(
      form.values.buySell === ContractViewType.Sell,
      stripeAccount !== null && stripeAccount?.payoutsEnabled && stripeAccount?.ordersEnabled,
    );
  };

  const buySellNext = (isSell: boolean, hasPayout: boolean) => {
    if (isSell && !hasPayout) {
      setViewPackage(CreateContractStep.AddPayoutDetails);
    } else if (!props.selectedThread) {
      setViewPackage(CreateContractStep.InviteUser);
    } else if (props.selectedThread.type == ChatType.OneToOne) {
      setViewPackage(CreateContractStep.SelectType);
    } else {
      setViewPackage(CreateContractStep.SelectUserInTeam);
    }
  };

  React.useEffect(() => {
    setViewPackage(CreateContractStep.BuyerOrSeller);
    setOtherUserId(props.selectedThread?.otherUserId);
  }, [props.open]);

  const setOtherUserId = (userId: string | undefined) => {
    form.setFieldValue(
      nameof<ICreateContractForm>(x => x.otherUserId),
      userId,
    );
  };

  const navigateBack = () =>
    setViewPackage(multiRecipients ? CreateContractStep.SelectUserInTeam : CreateContractStep.SelectType);

  return (
    <Dialog open={props.open || isCreateRedirect} fullWidth fullScreen={isMobileScreen} onClose={() => handleClose()}>
      {!subscriptionActive && <SubscriptionDialog returnUrl={props.returnUrl} onClose={handleClose} />}

      {!isProfileDetailsValid && subscriptionActive && (
        <AddProfileDetails
          handleClose={() => handleClose()}
          handleComplete={handleCompleteProfilePic}
          currentUser={user}
          title={'New Contract'}
        />
      )}
      {isProfileDetailsValid && subscriptionActive && (
        <FormikProvider value={form}>
          <form onSubmit={form.handleSubmit}>
            {viewPackage === CreateContractStep.BuyerOrSeller && (
              <ChooseBuySell
                form={form}
                user={user}
                handleCancel={() => handleClose()}
                next={buySellNavigateNext}
                selectedThread={props.selectedThread}
              />
            )}

            {viewPackage === CreateContractStep.AddPayoutDetails && (
              <AddPayoutDetails isPayoutAdded={false} handleClose={handleClose} returnUrl={props.returnUrl} />
            )}

            {viewPackage === CreateContractStep.SelectType && (
              <ChoosePackage
                form={form}
                next={() => setViewPackage(CreateContractStep.FillData)}
                handleCancel={() => handleClose()}
                user={user}
                selectedThread={props.selectedThread}
              />
            )}

            {multiRecipients && viewPackage === CreateContractStep.SelectUserInTeam && (
              <TeamChatSelectUser
                {...form}
                next={() => setViewPackage(CreateContractStep.SelectType)}
                handleCancel={() => handleClose()}
                loading={loading}
                recipients={multiRecipients}
              />
            )}

            {viewPackage === CreateContractStep.FillData && form.values.contractType === ContractType.FixedPrice && (
              <CreateFixedPriceContract
                {...form}
                user={user}
                back={navigateBack}
                handleCancel={() => handleClose()}
                loading={loading}
                selectedThread={props.selectedThread}
              />
            )}

            {viewPackage === CreateContractStep.FillData && form.values.contractType === ContractType.Retainer && (
              <CreateRetainerContract
                {...form}
                user={user}
                back={navigateBack}
                handleCancel={() => handleClose()}
                loading={loading}
                selectedThread={props.selectedThread}
              />
            )}

            {viewPackage === CreateContractStep.InviteUser && (
              <SelectConnection
                setOpenDialog={props.setOpenDialog}
                setSelectedThread={props.setSelectedThread}
                setViewPackage={setViewPackage}
                setOtherUserId={setOtherUserId}
                otherUserId={form.values.otherUserId}
                setRedirectToThread={setRedirectToThread}
                user={user}
              />
            )}
          </form>
        </FormikProvider>
      )}
    </Dialog>
  );
}

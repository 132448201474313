import {
  Box,
  Button,
  Container,
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  SelectProps,
  Stack,
  TextField,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import { useFormik } from 'formik';
import { IConciergeForm } from 'global/interfaces/concierge';
import { Dispatch, useContext, useEffect, useState } from 'react';
import { nameof } from 'ts-simple-nameof';
import ReCAPTCHA from 'react-google-recaptcha';
import { AuthContext } from 'contexts/AuthContext';
import { ErrorSelectProps } from 'global/interfaces/selects';
import FormSelect from 'components/common/Select/FormSelect';
import { useLocalization } from 'global/hooks/useLocalization';
import ConciergeSteps from './conciergeSteps';
import ConciergeStepCarousel from './conciergeStepCarousel';
import blackShoutt from 'assets/images/blackShoutt.svg';
import { conciergeValidationSchema } from 'global/validations/concierge';
import { sendConciergeDetails } from 'services/conciergeService';
import { showError } from 'utils/errorHandler';
import { useNavigate } from 'react-router-dom';

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: '72px',

  [theme.breakpoints.down('sm')]: {
    marginTop: '32px',
  },
}));

const StyledFormGrid = styled(Grid)(() => ({
  marginTop: '8px',
}));

const StyledButtonGrid = styled(Grid)(() => ({
  marginTop: '20px',
  marginBottom: '40px',
}));

const StyledHelperText = styled(FormHelperText)(({ theme }) => ({
  marginLeft: '14px',
  color: theme.palette.error.main,
}));

const CenteredBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'center',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    alignContent: 'start',
  },
}));

interface StyledBoxProps {
  backgroundColor?: string;
}

const StyledBannerBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'backgroundColor',
})<StyledBoxProps>(({ backgroundColor, theme }) => ({
  background: backgroundColor ? backgroundColor : theme.palette.blue[200],
}));

const ShouttImage = styled('img')(({ theme }) => ({
  width: '400px',
  objectFit: 'contain',
  marginBottom: '16px',

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const BannerStack = styled(Stack)(({ theme }) => ({
  paddingTop: '48px',
  paddingBottom: '48px',

  [theme.breakpoints.up('md')]: {
    paddingTop: '80px',
    paddingBottom: '80px',
  },
}));

interface BudgetSelectProps extends ErrorSelectProps, SelectProps {}

function BudgetSelect({ ...props }: BudgetSelectProps): JSX.Element {
  const { localCurrencySymbol } = useLocalization(29);
  const items = [
    { id: '', label: 'None' },
    { id: `Below ${localCurrencySymbol}500`, label: `Below ${localCurrencySymbol}500` },
    {
      id: `${localCurrencySymbol}500 - ${localCurrencySymbol}1000`,
      label: `${localCurrencySymbol}500 - ${localCurrencySymbol}1000`,
    },
    {
      id: `${localCurrencySymbol}1000 - ${localCurrencySymbol}2500`,
      label: `${localCurrencySymbol}1000 - ${localCurrencySymbol}2500`,
    },
    {
      id: `${localCurrencySymbol}2500 - ${localCurrencySymbol}5000`,
      label: `${localCurrencySymbol}2500 - ${localCurrencySymbol}5000`,
    },
    { id: `${localCurrencySymbol}5000+`, label: `${localCurrencySymbol}5000+` },
  ];

  return <FormSelect {...props} items={items} />;
}

export default function ConciergeForm({ setSubmitted }: { setSubmitted: Dispatch<boolean> }) {
  const [selectedFile, setSelectedFile] = useState<File>();
  const authContext = useContext(AuthContext);
  const isLoggedIn = authContext.user !== null;
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { localCurrencySymbol, currency } = useLocalization(29);
  const navigate = useNavigate();

  const handleCancelClick = (): void => {
    navigate(-1);
  };

  const form = useFormik<IConciergeForm>({
    initialValues: {
      name: '',
      description: '',
      email: '',
      title: '',
      budget: '',
      file: null,
      token: '',
      currency: '',
      userId: undefined,
    },
    onSubmit: values => {
      if (isLoggedIn || (form.values.token !== '' && form.values.token !== null)) {
        setLoading(true);
        sendConciergeDetails(values)
          .then(() => setSubmitted(true))
          .catch(showError);
      }
    },
    validationSchema: conciergeValidationSchema(!isLoggedIn, localCurrencySymbol),
  });

  useEffect(() => {
    if (isLoggedIn) {
      form.setFieldValue(
        nameof<IConciergeForm>(x => x.name),
        authContext.user?.firstName,
      );
      form.setFieldValue(
        nameof<IConciergeForm>(x => x.email),
        authContext.user?.email,
      );
      form.setFieldValue(
        nameof<IConciergeForm>(x => x.userId),
        authContext.user?.id,
      );
    }
  }, [authContext.user]);

  useEffect(() => {
    form.setFieldValue(
      nameof<IConciergeForm>(x => x.currency),
      currency,
    );
  }, [currency]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null) {
      setSelectedFile(e.target.files[0]);
      form.setFieldValue(
        nameof<IConciergeForm>(x => x.file),
        e.target.files[0],
      );
    }
  };

  return (
    <>
      {isMobileScreen && <ConciergeStepCarousel currencySymbol={localCurrencySymbol} />}
      <StyledBannerBox backgroundColor={'#D2ECF0'}>
        <Container maxWidth="lg">
          <BannerStack direction={'column'} rowGap={2}>
            <Typography variant="subtitle1" textAlign={'center'} fontWeight={800} fontSize={{ xs: '16px', md: '18px' }}>
              Looking for vetted Freelancers? Give us a{' '}
            </Typography>
            <CenteredBox>
              <ShouttImage alt="Shoutt" src={blackShoutt} />
            </CenteredBox>
          </BannerStack>
        </Container>
      </StyledBannerBox>
      <StyledContainer maxWidth={'lg'}>
        <form onSubmit={form.handleSubmit}>
          <Grid container>
            {!isMobileScreen && (
              <Grid item xs={0} md={4}>
                <ConciergeSteps currencySymbol={localCurrencySymbol} />
              </Grid>
            )}

            <Grid container item xs={12} md={8}>
              <Grid container item xs={12}>
                <Stack gap={0.5} mt={1}>
                  <Typography variant="body2">
                    Share your brief with us, and we’ll send you vetted proposals from 3 freelancers that are available
                    and match the brief for just {localCurrencySymbol}29. Leave the hard work to us and if you’re not
                    satisfied — get your money back!
                  </Typography>
                </Stack>
              </Grid>

              <StyledFormGrid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant={'h6'}>Basic Info</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormGroup>
                    <FormControl>
                      <TextField
                        name={nameof<IConciergeForm>(x => x.name)}
                        label="Your Name *"
                        value={form.values.name}
                        variant="outlined"
                        fullWidth
                        onChange={form.handleChange}
                        error={form.touched.name !== undefined && Boolean(form.errors.name)}
                        helperText={form.touched.name !== undefined ? form.errors.name : ''}
                        disabled={isLoggedIn}
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormGroup>
                    <FormControl>
                      <TextField
                        name={nameof<IConciergeForm>(x => x.email)}
                        label="Email *"
                        variant="outlined"
                        fullWidth
                        value={form.values.email}
                        onChange={form.handleChange}
                        error={form.touched.email !== undefined && Boolean(form.errors.email)}
                        helperText={form.touched.email !== undefined ? form.errors.email : ''}
                        disabled={isLoggedIn}
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormGroup>
                    <FormControl>
                      <TextField
                        name={nameof<IConciergeForm>(x => x.title)}
                        label="Title *"
                        variant="outlined"
                        fullWidth
                        value={form.values.title}
                        placeholder="i.e Freelance Designer for new Saas Product"
                        onChange={form.handleChange}
                        error={form.touched.title !== undefined && Boolean(form.errors.title)}
                        helperText={form.touched.title !== undefined ? form.errors.title : ''}
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormGroup>
                    <FormControl>
                      <BudgetSelect
                        label="Budget *"
                        name={nameof<IConciergeForm>(x => x.budget)}
                        onChange={form.handleChange}
                        variant={'outlined'}
                        value={form.values.budget}
                        error={form.touched.budget !== undefined && Boolean(form.errors.budget)}
                        helpertext={form.touched.budget !== undefined ? form.errors.budget : ''}
                      ></BudgetSelect>
                    </FormControl>
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={'h6'} mt={2}>
                    Project Details
                  </Typography>
                  <Typography variant="body2" mt="4px">
                    Please describe the freelancer&apos;s responsibilities, your niche, expected deliverables, and
                    timeline. You can also upload a PDF if it&apos;s easier.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControl>
                      <TextField
                        name={nameof<IConciergeForm>(x => x.description)}
                        variant="outlined"
                        fullWidth
                        rows={10}
                        multiline
                        onChange={form.handleChange}
                        value={form.values.description}
                        error={form.touched.description !== undefined && Boolean(form.errors.description)}
                        helperText={form.touched.description !== undefined ? form.errors.description : ''}
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>
                <Grid container alignItems="center" item>
                  <Button
                    variant="outlined"
                    component="label"
                    sx={{
                      padding: '18px 36px',
                      borderRadius: theme.spacing(6),
                      [theme.breakpoints.down('sm')]: {
                        padding: '12px 22px',
                      },
                      marginRight: '10px',
                    }}
                  >
                    Upload File
                    <input
                      type="file"
                      hidden
                      name={nameof<IConciergeForm>(x => x.file)}
                      onChange={e => handleFileChange(e)}
                    />
                  </Button>
                  <label htmlFor={nameof<IConciergeForm>(x => x.file)}>
                    <Typography variant="body2">{selectedFile?.name ?? ''}</Typography>
                  </label>
                  {form.errors && form.errors.file && (
                    <Grid item xs={12}>
                      <StyledHelperText>{form.errors.file}</StyledHelperText>
                    </Grid>
                  )}
                </Grid>
                {!isLoggedIn && (
                  <Grid item>
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_KEY ?? ''}
                      onChange={(value: string | null) => {
                        form.setFieldValue(
                          nameof<IConciergeForm>(x => x.token),
                          value,
                        );
                      }}
                      onErrored={() => console.error('reCAPTCHA initialization error')}
                    />
                    {form.errors.token && form.submitCount > 0 && (
                      <StyledHelperText>{form.errors.token}</StyledHelperText>
                    )}
                  </Grid>
                )}
                <StyledButtonGrid container item xs={12} justifyContent={'flex-end'}>
                  <Grid container item xs={12} sm={6} spacing={1}>
                    <Grid item xs={6}>
                      <RoundButton variant="outlined" fullWidth onClick={handleCancelClick}>
                        Cancel
                      </RoundButton>
                    </Grid>
                    <Grid item xs={6}>
                      <RoundButton loading={loading} variant="contained" type="submit" fullWidth>
                        Submit
                      </RoundButton>
                    </Grid>
                  </Grid>
                </StyledButtonGrid>
              </StyledFormGrid>
            </Grid>
          </Grid>
        </form>
      </StyledContainer>
    </>
  );
}

import { Typography, useMediaQuery, useTheme } from '@mui/material';
import BasicPlan from 'components/subscriptions/basicPlan';
import { StyledContainer } from 'components/userProfileEdits/EditUserProfile';
import { useTitle } from 'utils/router';

export default function Subscriptions() {
  useTitle('My Subscription');
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <StyledContainer maxWidth="md">
      <Typography variant={isMobileScreen ? 'h5' : 'h4'}>Your Subscription</Typography>
      <BasicPlan />
    </StyledContainer>
  );
}

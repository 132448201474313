import { Button, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

const NotFound: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Grid container item xs={12} marginTop={5}>
      <Grid xs={12}>
        <Typography textAlign="center" variant="h5">
          Page Not Found
        </Typography>
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <Grid xs={6}>
          <Typography textAlign="center" variant="body1" pt={2}>
            Oops, looks like you&apos;ve taken a wrong turn in the land of Shoutt! 🗺️🚀 Our freelancers might be hard at
            work, but we couldn&apos;t find the page you&apos;re looking for. No worries, let&apos;s bring back the
            hustle and bustle of our freelancer marketplace in a jiffy. Your dream project or perfect freelancer is just
            around the corner!
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container justifyContent="center" marginTop={2}>
        <Button variant="contained" onClick={() => navigate('/')}>
          Return home
        </Button>
      </Grid>
    </Grid>
  );
};

export default NotFound;

import { Dialog, Typography, useMediaQuery, useTheme } from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import { AuthContext } from 'contexts/AuthContext';
import { format } from 'date-fns';
import { useContext, useState } from 'react';
import { cancelSubscription } from 'services/subscriptionService';
import { showError } from 'utils/errorHandler';

export default function CancelSubscriptionDialog({
  open,
  handleClose,
  subscriptionPeriodEnd,
  loadSubscription,
}: {
  open: boolean;
  handleClose: () => void;
  subscriptionPeriodEnd?: string;
  loadSubscription: () => void;
}) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const handleCancelSubscription = () => {
    setLoading(true);
    cancelSubscription()
      .then(() => {
        authContext.refresh().then(() => {
          loadSubscription();
          handleClose();
          setLoading(false);
        });
      })
      .catch(e => {
        showError(e);
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={!loading ? handleClose : undefined}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      fullScreen={isMobileScreen}
    >
      <StyledDialogTitle>
        <Typography variant="h6" color="grey.900">
          Cancel Subscription
        </Typography>
      </StyledDialogTitle>
      <StyledDialogContent dividers>
        If you cancel your subscription will end on{' '}
        {subscriptionPeriodEnd && format(new Date(subscriptionPeriodEnd), 'MMMM d, yyyy')}. You can still create
        contracts till that date. Would you like to cancel now?
      </StyledDialogContent>
      <StyledDialogActions>
        <RoundButton variant="outlined" fullWidth={isMobileScreen} onClick={handleClose} disabled={loading}>
          Back
        </RoundButton>
        <RoundButton
          variant="contained"
          fullWidth={isMobileScreen}
          onClick={handleCancelSubscription}
          type="submit"
          loading={loading}
        >
          Continue
        </RoundButton>
      </StyledDialogActions>
    </Dialog>
  );
}

import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CountrySelect, { countries, fallbackContractCountry } from 'components/common/Select/CountrySelect';
import ProfileImageEditor from './ProfileImageEditor';
import { FastField, FieldProps, FormikProps } from 'formik';

import { IEditableUser, ILanguageSkill } from 'global/interfaces/user';
import { useState } from 'react';
import LanguageEditor from './LanguageEditor';
import { nameof } from 'ts-simple-nameof';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

import { CategoriesSkillSection } from './CategoriesSkillsSection';
import { ScreeningLinks } from '../EditSellServicesSection/ScreeningLinks';
import { handleProfileImgChange } from 'utils/file';
import { BuyerPlaceholderTitleText, SellerPlaceholderTitleText } from 'global/constants';
import { VettingStatus } from 'global/enums/vettingStatus';
import { Link } from 'react-router-dom';
import { isAdminEdit } from 'utils/adminUtil';
import { TextButton } from 'components/common/Button/TextButton';
import { Add } from '@mui/icons-material';

const LanguageGridItem = styled(Grid)`
  border: 1px solid ${({ theme }) => theme.palette.grey[200]};
  border-radius: 8px;
  padding: 16px 20px;
`;

interface IEditUserHeadSectionProps {
  form: FormikProps<IEditableUser>;
  isApplyPhase: boolean;
}

export const EditUserHeadSection = ({ form, isApplyPhase }: IEditUserHeadSectionProps) => {
  const [languageEditorOpen, setLanguageEditorOpen] = useState<boolean>(false);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const adminEdit: boolean = isAdminEdit();

  const onUpdateLanguages = (languages: ILanguageSkill[]): void => {
    setLanguageEditorOpen(false);
    form.setFieldValue('languages', [...languages]);
  };

  const handleCountryChange = (_: React.SyntheticEvent, value: { label: string; code: string } | null): void => {
    form.setFieldValue('country', value?.code, true);

    if (value?.code) {
      const matchingCountry = countries.find(c => c.code === value.code);
      form.setFieldValue('currency', matchingCountry?.currency ?? fallbackContractCountry.currency, true);
    }
  };

  const handleShowFullNameChange = (form: FormikProps<IEditableUser>) => {
    form.setFieldValue('hideLastName', !form.values.hideLastName);
  };

  const handleApplyChange = (form: FormikProps<IEditableUser>) => {
    form.setFieldValue('needsVettingValidation', !form.values.needsVettingValidation);
  };

  const profilePath = form.values.profileImage ? form.values.profileImage.path : '';

  return (
    <>
      {!isApplyPhase && form.values.isSellingServices && form.values.vetting.status == VettingStatus.NotSubmitted && (
        <Stack gap={0.5} mb={3}>
          <Typography variant="h6">Marketplace</Typography>
          <Typography variant="body2">
            Optional: Would you like to apply to be listed on our{' '}
            <Link
              to={'/talent'}
              target="_blank"
              style={{
                color: theme.palette.primary[600],
              }}
            >
              marketplace
            </Link>
            ?
            <>
              {' '}
              Please review our{' '}
              <Link
                to={process.env.REACT_APP_MODERATION_URL ?? ''}
                target="_blank"
                style={{
                  color: theme.palette.primary[600],
                }}
              >
                moderation policy
              </Link>{' '}
              first. Note, you can still use our contract, payment and workspace tools without being on the marketplace.
            </>
          </Typography>
          <FormControlLabel
            control={<Switch checked={form.values.needsVettingValidation} sx={{ width: '49px', paddingRight: 0 }} />}
            label={undefined}
            sx={{ mr: 0 }}
            onChange={() => handleApplyChange(form)}
          />
        </Stack>
      )}
      <Box mb={3}>
        <Typography variant={'h6'}>Basic Info</Typography>
      </Box>
      <Stack gap={3}>
        <Grid container columnSpacing={isMobileScreen ? 1 : 2.5} rowSpacing={3}>
          <Grid item xs={12} md={6}>
            <FormGroup>
              <FormControl variant="standard">
                <FastField name={nameof<IEditableUser>(x => x.email)}>
                  {({ field, form: { handleChange } }: FieldProps) => (
                    <TextField
                      fullWidth
                      name={field.name}
                      value={field.value}
                      onChange={handleChange}
                      type="email"
                      label="Email *"
                      variant={'outlined'}
                      disabled
                    />
                  )}
                </FastField>
              </FormControl>
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormGroup>
              <FormControl>
                <FastField name={nameof<IEditableUser>(x => x.title)}>
                  {({ field, form: { handleChange }, meta }: FieldProps) => (
                    <TextField
                      fullWidth
                      name={field.name}
                      value={field.value ?? ''}
                      onChange={handleChange}
                      label="Profile Title *"
                      variant="outlined"
                      placeholder={
                        !form.values.isSellingServices ? BuyerPlaceholderTitleText : SellerPlaceholderTitleText
                      }
                      InputProps={{
                        autoComplete: 'off',
                        endAdornment: (
                          <Tooltip
                            arrow
                            enterTouchDelay={0}
                            placement="top"
                            title={
                              !form.values.isSellingServices
                                ? 'Use title case and format nearly. For e.g. "Tech Influencer" not "Tech influencer"'
                                : 'This is the first thing buyers will see, so be creative and precise! If you offer multiple services use a title that captures everything or try using slashes for e.g. “Video Editor / Brand Designer”. Use title case and format nearly.'
                            }
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  maxWidth: '320px',
                                  borderRadius: '12px',
                                },
                              },
                            }}
                          >
                            <IconButton color="default">
                              <InfoRoundedIcon color="inherit" />
                            </IconButton>
                          </Tooltip>
                        ),
                      }}
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched ? meta.error : ''}
                    />
                  )}
                </FastField>
              </FormControl>
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormGroup>
              <FormControl variant="standard">
                <FastField name={nameof<IEditableUser>(x => x.firstName)}>
                  {({ field, form: { handleChange }, meta }: FieldProps) => (
                    <TextField
                      fullWidth
                      name={field.name}
                      value={field.value}
                      onChange={handleChange}
                      label="First Name *"
                      variant="outlined"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched ? meta.error : ''}
                    />
                  )}
                </FastField>
              </FormControl>
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormGroup>
              <FormControl variant="standard">
                <FastField name={nameof<IEditableUser>(x => x.lastName)}>
                  {({ field, form: { handleChange }, meta }: FieldProps) => (
                    <TextField
                      fullWidth
                      name={field.name}
                      value={field.value}
                      onChange={handleChange}
                      label="Last Name *"
                      variant="outlined"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched ? meta.error : ''}
                    />
                  )}
                </FastField>
              </FormControl>
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormGroup>
              <FormControl variant="standard">
                <FastField name={nameof<IEditableUser>(x => x.country)}>
                  {({ field, meta }: FieldProps) => (
                    <CountrySelect
                      value={field.value}
                      size="medium"
                      onChange={handleCountryChange}
                      label="Country *"
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched ? meta.error : ''}
                      disabled={form.values.countryEditable !== undefined && !form.values.countryEditable}
                    />
                  )}
                </FastField>
              </FormControl>
            </FormGroup>
          </Grid>
        </Grid>

        <Stack gap={0.5} mt={1}>
          <Typography variant={'h6'}>Privacy</Typography>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'}>
              Hide Last Name
              <Tooltip
                arrow
                enterTouchDelay={0}
                placement="top"
                title="When turned on, only your initial will be shown on your Shoutt profile instead of your full last name. Your Shoutt profile will not be found on search engines when someone searches for your full name."
              >
                <IconButton color="default">
                  <InfoRoundedIcon color="inherit" />
                </IconButton>
              </Tooltip>
            </Typography>
            <FormControlLabel
              control={<Switch checked={form.values.hideLastName} sx={{ width: '49px', paddingRight: 0 }} />}
              label={undefined}
              sx={{ mr: 0 }}
              onChange={() => handleShowFullNameChange(form)}
            />
          </Grid>
        </Stack>

        <Stack gap={0.5} mt={1}>
          <Typography variant="h6">Bio</Typography>
          <Typography variant="body2">
            {form.values.isSellingServices
              ? (form.values.needsVettingValidation ? 'Required: ' : 'Recommended: ') +
                'Introduce yourself with a short summary about your background, skills and experience. This is one of the first things potential buyers will see so take the time to craft a concise and compelling pitch!'
              : 'Optional: Save time by introducing yourself with a few short lines. '}
          </Typography>
        </Stack>

        <FormGroup>
          <FormControl>
            {/* Added two fast fields to maintain performance for launch */}
            {!isMobileScreen && (
              <FastField name={nameof<IEditableUser>(x => x.bio)}>
                {({ field, form: { handleChange }, meta }: FieldProps) => (
                  <TextField
                    fullWidth
                    name={field.name}
                    value={field.value ?? ''}
                    onChange={handleChange}
                    variant={'outlined'}
                    multiline
                    rows={4}
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched ? meta.error : ''}
                    InputProps={{
                      style: { overflow: 'hidden' },
                      endAdornment: (
                        <InputAdornment position="end" style={{ alignSelf: 'flex-end' }}>
                          <FormHelperText
                            style={{
                              color: theme.palette.primary.dark,
                              fontSize: '10px',
                            }}
                          >
                            {form.values.bio?.length}
                          </FormHelperText>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </FastField>
            )}
            {isMobileScreen && (
              <FastField name={nameof<IEditableUser>(x => x.bio)}>
                {({ field, form: { handleChange }, meta }: FieldProps) => (
                  <TextField
                    fullWidth
                    name={field.name}
                    value={field.value ?? ''}
                    onChange={handleChange}
                    variant={'outlined'}
                    multiline
                    rows={6}
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched ? meta.error : ''}
                    InputProps={{
                      style: { overflow: 'hidden' },
                      endAdornment: (
                        <InputAdornment position="end" style={{ alignSelf: 'flex-end' }}>
                          <FormHelperText
                            style={{
                              color: theme.palette.primary.main,
                              fontSize: '10px',
                            }}
                          >
                            {form.values.bio?.length}
                          </FormHelperText>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </FastField>
            )}
          </FormControl>
        </FormGroup>

        {form.values.isSellingServices && (
          <Grid item xs={12} mt={2}>
            <ScreeningLinks form={form} />
          </Grid>
        )}

        <Box my={1.5}>
          <ProfileImageEditor
            error={form.errors.profileImage}
            isTouched={form.touched.profileImage !== undefined}
            handleProfileImgChange={(file: File) => handleProfileImgChange(file, form)}
            imagePath={profilePath}
            isSellingServices={form.values.isSellingServices}
            disabled={adminEdit}
            hasChanged={form.initialValues.profileImage?.path !== form.values.profileImage?.path}
          />
        </Box>

        <Stack gap={3} alignItems="flex-start">
          <Stack width="100%" direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant={'h6'}>Languages</Typography>
          </Stack>

          <Grid
            container
            display="grid"
            gridTemplateColumns={isMobileScreen ? 'repeat(2, 1fr)' : 'repeat(5, 1fr)'}
            gap={2}
          >
            {form.values.languages.map((language, i) => (
              <Grid item xs={12} key={i}>
                <LanguageGridItem container alignItems="center">
                  <Grid item xs={10}>
                    <Typography variant="subtitle1">{language.language}</Typography>
                    <Typography variant="body2">{language.proficiency}</Typography>
                  </Grid>
                </LanguageGridItem>
              </Grid>
            ))}
            {form.touched.languages !== undefined && form.errors.languages && (
              <Grid sx={{ p: 1 }} item xs={12}>
                <FormHelperText error>{form.errors.languages.toString()}</FormHelperText>
              </Grid>
            )}
          </Grid>
          <Box mb={1.5}>
            <TextButton
              size="large"
              variant="text"
              startIcon={<Add />}
              onClick={() => setLanguageEditorOpen(true)}
              fullWidth={false}
            >
              Add Language
            </TextButton>
          </Box>

          <LanguageEditor
            languages={form.values.languages}
            error={form.touched.languages !== undefined && Boolean(form.errors.languages)}
            onUpdateLanguages={onUpdateLanguages}
            onCloseClick={() => setLanguageEditorOpen(false)}
            open={languageEditorOpen}
          />

          {form.values.isSellingServices && <CategoriesSkillSection form={form} />}
        </Stack>
      </Stack>
    </>
  );
};

import { Close } from '@mui/icons-material';
import { Dialog, FormControl, Grid, IconButton, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { createGroupThread } from 'services/chatThreadService';
import { showError } from 'utils/errorHandler';
import { IChatUserThread } from 'global/interfaces/chatThread';
import React, { useState } from 'react';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import { RoundButton } from 'components/common/Button/RoundButton';

interface AddTeamDialogProps {
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  setThreads: React.Dispatch<React.SetStateAction<IChatUserThread[]>>;
  setSelectedThread: React.Dispatch<IChatUserThread>;
}

export const AddTeamDialog: React.FC<AddTeamDialogProps> = ({ open, setOpen, setThreads, setSelectedThread }) => {
  const [isCreating, setIsCreating] = useState(false);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  const form = useFormik<{ name: string }>({
    initialValues: {
      name: '',
    },
    onSubmit: values => {
      setIsCreating(true);
      createGroupThread(values.name)
        .then((res: IChatUserThread) => {
          setThreads(prevItems => [...prevItems, res]);
          setSelectedThread(res);
          setOpen(false);
          form.resetForm();
        })
        .catch(showError)
        .finally(() => {
          setIsCreating(false);
        });
    },
    validationSchema: object({
      name: string().required('Enter the team name'),
    }),
  });

  return (
    <Dialog open={open} fullWidth fullScreen={isMobileScreen}>
      <StyledDialogTitle>
        <Typography variant="h6" color={theme.palette.grey[900]}>
          Create Team
        </Typography>
        <IconButton onClick={() => setOpen(false)} sx={{ mr: '-12px' }}>
          <Close />
        </IconButton>
      </StyledDialogTitle>

      <form onSubmit={form.handleSubmit}>
        <StyledDialogContent dividers>
          <Grid pb={5}>
            <FormControl fullWidth>
              <TextField
                name="name"
                value={form.values.name}
                onChange={form.handleChange}
                error={form.touched.name && form.errors.name !== undefined}
                helperText={form.touched.name && form.errors.name}
                fullWidth
                label="Enter a team name"
              />
            </FormControl>
          </Grid>
        </StyledDialogContent>

        <StyledDialogActions>
          <RoundButton loading={isCreating} fullWidth={isMobileScreen} variant="contained" type="submit">
            Create Team
          </RoundButton>
        </StyledDialogActions>
      </form>
    </Dialog>
  );
};

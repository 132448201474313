import { DoubleArrowIcon } from 'components/icon/DoubleArrowIcon';
import { ArrowBackIcon } from 'components/icon/ArrowBackIcon';
import Typography from '@mui/material/Typography';
import { ChatType, IChatUserThread } from 'global/interfaces/chatThread';
import { Box, Button, Grid, IconButton, Stack, styled, useMediaQuery, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { TeamMembers } from './teamMembers';
import { AuthContext } from 'contexts/AuthContext';
import { useContext } from 'react';
import { JustifiedBox } from 'components/common/StyledWorkroom/WorkroomStyles';
import { CreateContractIcon } from 'components/icon/CreateContractIcon';
import { useNavigate } from 'react-router-dom';

interface IMessagesSectionHeaderProps {
  selectedThread: IChatUserThread | undefined;
  isRightSectionCollapsed: boolean;
  handleLeftClick: () => void;
  handleRightClick: () => void;
  handleRightCollapseClick: () => void;
  teams: IChatUserThread[];
  onCreateContractButtonClick: (isCreating: boolean) => void;
  isCreatingContract: boolean;
  haveContracts: boolean;
  addMemberDialogOpen: boolean;
  setAddMemberDialogOpen: React.Dispatch<boolean>;
  setSelectedThread: React.Dispatch<React.SetStateAction<IChatUserThread | undefined>>;
}

interface MobileProps {
  isMobile: boolean;
}

interface IconButtonProps {
  rotate: boolean;
}

const MessageHeaderBox = styled(Box, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== 'isMobile',
})<MobileProps>(({ isMobile, theme }) => ({
  minHeight: isMobile ? '52px' : '68px',
  paddingLeft: isMobile ? '8px' : '0px',
  paddingRight: isMobile ? '8px' : '0px',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '1px',
  border: `1px solid ${theme.palette.grey[200]}`,
  borderTop: 0,

  [theme.breakpoints.down('md')]: {
    border: 'none',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
}));

const FlexGridItem = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const RightFlexGridItem = styled(Grid, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== 'isMobile',
})<MobileProps>(() => ({
  display: 'flex',
  alignItems: 'center',
  alignContent: 'flex-end',
  flexWrap: 'wrap',
  flexDirection: 'column',
  marginTop: '0px',
}));

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const ActionFlexBox = styled(FlexBox)(() => ({
  height: '100%',
  marginRight: '8px',
}));

const StyledStack = styled(Stack)(() => ({
  height: '28px',
  marginLeft: '16px',
}));

const EllipsisWrapperDiv = styled('div')`
  display: flex;
  max-width: 150px;
  white-space: nowrap;
`;

const EllipsisTypography = styled(Typography)(() => ({
  display: 'table-cell',
}));

const StyledCollapseArrowButton = styled(IconButton, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== 'rotate',
})<IconButtonProps>(({ theme, rotate }) => ({
  marginLeft: '8px',
  transform: rotate ? 'rotate(-180deg)' : 'none',
  color: theme.palette.grey[600],
}));

const CreateContractButton = styled(Button)(({ theme }) => ({
  minWidth: 'max-content',
  color: theme.palette.primary.dark,
}));

const ArrowBackIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[600],
}));

const DetailsButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[600],
}));

const AddContractIconButton = styled(IconButton)(() => ({
  padding: '5px',

  '& .MuiSvgIcon-root': {
    height: '18px',
    width: '18px',
  },
}));

EllipsisTypography.defaultProps = {
  noWrap: true,
};

export default function MessagesSectionHeader(props: IMessagesSectionHeaderProps): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const handleUserClick = () => {
    navigate(`/users/${props.selectedThread?.otherUserId}`);
  };

  return (
    <MessageHeaderBox isMobile={isMobile}>
      <Grid container>
        <FlexGridItem item xs={12} md={4}>
          <FlexBox>
            {isMobile && (
              <ArrowBackIconButton onClick={props.handleLeftClick} size="small">
                <ArrowBackIcon />
              </ArrowBackIconButton>
            )}

            <StyledStack direction="column" spacing={0} justifyContent="center">
              <EllipsisWrapperDiv>
                {props.selectedThread?.type == ChatType.Group && (
                  <EllipsisTypography variant="subtitle1">
                    {'# '}
                    {props.selectedThread?.displayName}
                  </EllipsisTypography>
                )}
                {props.selectedThread?.type == ChatType.OneToOne && (
                  <EllipsisTypography variant="subtitle1" onClick={handleUserClick} style={{ cursor: 'pointer' }}>
                    {props.selectedThread?.displayName}
                  </EllipsisTypography>
                )}
              </EllipsisWrapperDiv>
            </StyledStack>
            {!isMobile && props.selectedThread?.type === ChatType.Group && (
              <TeamMembers
                selectedThread={props.selectedThread}
                user={authContext.user}
                addMemberDialogOpen={props.addMemberDialogOpen}
                setAddMemberDialogOpen={props.setAddMemberDialogOpen}
                setSelectedThread={props.setSelectedThread}
              />
            )}
          </FlexBox>
          {isMobile && (
            <JustifiedBox gap={1}>
              {props.selectedThread?.type === ChatType.Group && (
                <TeamMembers
                  selectedThread={props.selectedThread}
                  user={authContext.user}
                  addMemberDialogOpen={props.addMemberDialogOpen}
                  setAddMemberDialogOpen={props.setAddMemberDialogOpen}
                  setSelectedThread={props.setSelectedThread}
                />
              )}
            </JustifiedBox>
          )}
          {isMobile && (
            <>
              {props.selectedThread?.active && (
                <AddContractIconButton size="small" onClick={() => props.onCreateContractButtonClick(true)}>
                  <CreateContractIcon style={{ color: theme.palette.grey[500] }} />
                </AddContractIconButton>
              )}
              <DetailsButton size="small" onClick={props.handleRightClick}>
                <DoubleArrowIcon />
              </DetailsButton>
            </>
          )}
        </FlexGridItem>
        <RightFlexGridItem item md={8} isMobile={isMobile}>
          <ActionFlexBox>
            {!isMobile && (
              <Stack direction="row" gap={1} alignItems="center">
                {props.selectedThread?.active && (
                  <CreateContractButton
                    variant="text"
                    onClick={() => props.onCreateContractButtonClick(true)}
                    fullWidth
                    startIcon={<AddIcon />}
                  >
                    <Typography variant="subtitle2" color="primary.dark">
                      Create Contract
                    </Typography>
                  </CreateContractButton>
                )}

                {(!props.isRightSectionCollapsed || props.haveContracts) && (
                  <StyledCollapseArrowButton
                    onClick={props.handleRightCollapseClick}
                    rotate={props.isRightSectionCollapsed}
                  >
                    <DoubleArrowIcon />
                  </StyledCollapseArrowButton>
                )}
              </Stack>
            )}
          </ActionFlexBox>
        </RightFlexGridItem>
      </Grid>
    </MessageHeaderBox>
  );
}

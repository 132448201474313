import {
  Avatar,
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { NotableClientDialog } from './NotableClientDialog';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { INotableClient } from 'global/interfaces/INotableClient';
import NoNotableClientIcon from 'components/common/StyledIcons/NoNotableClientIcon';

interface INotableClientProps {
  companies: INotableClient[];
  onUpdateNotableClients: (companies: INotableClient[]) => void;
  error?: string;
}

const AddHistoryButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.dark,
  paddingInline: 0,

  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const HistoryContainer = styled(Grid)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.grey[300],
  borderRadius: '8px',
  padding: '16px 24px',

  [theme.breakpoints.down('sm')]: {
    paddingRight: '16px',
  },
}));

export const NotableClientHistoryEditor = (props: INotableClientProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const [editIndex, setEditIndex] = useState<number>(-1);
  const [selectedCompany, setSelectedCompany] = useState<INotableClient>();

  const handleClickOpen = (index = -1): void => {
    if (index > -1) {
      setSelectedCompany(props.companies[index]);
      setEditIndex(index);
    } else {
      setSelectedCompany(undefined);
    }
    setOpen(true);
  };

  const handleDelete = (index: number): void => {
    props.companies.splice(index, 1);
    props.onUpdateNotableClients(props.companies);
  };

  return (
    <>
      <Box width="100%" mb={props.companies?.length === 0 ? 0 : 3} mt={3}>
        <Stack gap={0.5} mt={1} mb={2}>
          <Typography variant="h6">Notable Experience</Typography>
          <Typography variant="body2">
            Optional. Please add any well known companies you have worked for or with. This adds a lot of credibility to
            attract potential buyers!
          </Typography>
        </Stack>

        <Grid container display="grid" gridTemplateColumns={isMobileScreen ? '1fr' : 'repeat(2, 1fr)'} gap={2}>
          {props.companies.map((history, index) => (
            <HistoryContainer item xs={12} key={index}>
              <Stack direction="row" justifyContent="space-between">
                <Stack width="100%" direction="row" gap={2}>
                  {!isMobileScreen && (
                    <Avatar
                      sx={{
                        width: 54,
                        height: 54,
                        mt: 1.5,
                      }}
                      src={history.logo}
                      variant="square"
                    ></Avatar>
                  )}
                  <Stack mt={!isMobileScreen ? 2 : undefined} direction="column">
                    <Typography variant="subtitle1" align="left" color="grey.900">
                      {history.name}
                    </Typography>
                    <Typography variant="body2" align="left" color="grey.900">
                      {history.role}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack direction="row" alignItems="flex-start">
                  <IconButton aria-label="edit" size="small" onClick={() => handleClickOpen(index)}>
                    <BorderColorOutlinedIcon fontSize="large" />
                  </IconButton>
                  <IconButton size="small" aria-label="delete" onClick={() => handleDelete(index)}>
                    <HighlightOffIcon fontSize="large" />
                  </IconButton>
                </Stack>

                <Box></Box>
              </Stack>
            </HistoryContainer>
          ))}
        </Grid>
      </Box>

      {props.companies?.length === 0 && <NoNotableClientIcon />}

      <Grid container mt={3}>
        <Grid item xs={12} sm={12} md={12}>
          <AddHistoryButton startIcon={<AddOutlinedIcon />} size="large" onClick={() => handleClickOpen()}>
            Add a Notable Experience
          </AddHistoryButton>
        </Grid>
      </Grid>
      {props.error && (
        <Grid sx={{ p: 1 }} item xs={12}>
          <FormHelperText error>{props.error}</FormHelperText>
        </Grid>
      )}

      <NotableClientDialog
        open={open}
        setOpen={setOpen}
        notableClients={props.companies}
        onUpdateNotableClients={props.onUpdateNotableClients}
        editIndex={editIndex}
        setEditIndex={setEditIndex}
        selectedCompany={selectedCompany}
      />
    </>
  );
};

import { IconButton, Paper, Popover, styled } from '@mui/material';
import IosShareIcon from '@mui/icons-material/IosShare';
import { pushToDataLayer } from 'utils/tagHelper';
import { shareUserProfile } from 'global/constants';
import { useState } from 'react';
import ShareIcon from '@mui/icons-material/Share';
import { isStableShareDevice } from 'utils/device';

const StyledPaper = styled(Paper)(() => ({
  padding: '10px',
  backgroundColor: 'black',
  color: 'white',
  width: '300px',
  textAlign: 'center',
}));

export default function ShareButton({ userId }: { userId: string }) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const shareUrl = `${process.env.REACT_APP_SHARE_URL}/Share/${userId}`;

  const shareProfileFallback = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
    navigator.clipboard.writeText(shareUrl).then(() => {
      setOpen(true);
      pushToDataLayer(shareUserProfile, {
        userId: userId,
      });
      setTimeout(() => {
        setOpen(false);
      }, 2000);
    });
  };

  const shareProfile = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    navigator
      .share({
        title: document.title,
        url: shareUrl,
      })
      .then(() =>
        pushToDataLayer(shareUserProfile, {
          userId: userId,
        }),
      )
      .catch(() => {
        shareProfileFallback(e);
      });
  };

  return (
    <IconButton
      style={{ marginLeft: '3px', padding: '3px' }}
      onClick={isStableShareDevice() ? e => shareProfile(e) : e => shareProfileFallback(e)}
    >
      {isStableShareDevice() ? <IosShareIcon /> : <ShareIcon />}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <StyledPaper>Sharing link copied</StyledPaper>
      </Popover>
    </IconButton>
  );
}

import { countries, countriesWithCurrency, fallbackContractCountry } from 'components/common/Select/CountrySelect';

export function getUsdRateForCountry(countryCode: string | undefined): number {
  return countries.find(c => c.code === countryCode)?.conversionRate ?? 1;
}

export function convertToViewerCurrency(rateInUsd: number, viewerCountryCode: string): number {
  if (viewerCountryCode === fallbackContractCountry.code) {
    return rateInUsd;
  }

  const viewerCountryConversionRate: number = getUsdRateForCountry(viewerCountryCode);
  const rateInViewerCurrency = Math.floor(rateInUsd / viewerCountryConversionRate);
  return rateInViewerCurrency;
}

export function LocalToUsd(rateInLocalCurrency: number, viewerCountryCode: string): number {
  if (viewerCountryCode === fallbackContractCountry.code) {
    return rateInLocalCurrency;
  }

  const viewerCountryConversionRate: number = getUsdRateForCountry(viewerCountryCode);
  const rateInViewerCurrency = Math.ceil(rateInLocalCurrency * viewerCountryConversionRate);
  return rateInViewerCurrency;
}

export function getBasicPlanPriceFromCurrency(currencySymbol: string): number {
  const price = currencySymbol == '£' ? 14.99 : currencySymbol == '€' ? 17.99 : 19.99;
  return price;
}

export function getPlanPricing(countryCode: string): string {
  const userCountry = countriesWithCurrency.find(c => c.code === countryCode);
  if (userCountry?.currency === 'GBP') {
    return '£14.99 / month';
  }
  if (userCountry?.currency === 'EUR') {
    return '€17.99 / month';
  }
  return '$19.99 / month';
}

import { MainSectionBox } from './MainSectionBox';
import { Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { IContractEarnings } from 'global/interfaces/contract';
import { getEarnings } from 'services/contractService';
import { showError } from 'utils/errorHandler';
import { getCurrencySymbol } from 'components/common/Select/CountrySelect';
import { IFrontUser } from 'global/interfaces/user';

interface IItemEarningsProps {
  user: IFrontUser;
}

export default function ItemEarnings({ user }: IItemEarningsProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [contractEarnings, setContractEarnings] = useState<IContractEarnings[]>([]);

  const numberFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  useEffect(() => {
    loadEarnings();
  }, []);

  const loadEarnings = () => {
    setLoading(true);
    setContractEarnings([]);

    getEarnings()
      .then((res: IContractEarnings[]) => {
        setContractEarnings(res);
        setLoading(false);
      })
      .catch(showError);
  };
  return !loading ? (
    <MainSectionBox>
      <Stack direction="row" spacing={1.5} mb={2}>
        <Typography variant="subtitle1" fontWeight={600}>
          {contractEarnings.length > 0 ? 'Total Earnings' : 'Completed Contracts'}
        </Typography>
      </Stack>
      {contractEarnings.length > 0 && (
        <Stack direction="column" spacing={0.75} mt={1} mb={1}>
          {contractEarnings.map((contractEarnings, index: number) => {
            const currencySymbol = getCurrencySymbol(contractEarnings.currency);

            return (
              <Typography key={index} variant="h5" fontWeight={700}>
                {currencySymbol} {numberFormatter.format(contractEarnings.amount)}
              </Typography>
            );
          })}
        </Stack>
      )}
      {contractEarnings.length == 0 && (
        <Stack direction="column" spacing={0.75} mt={1} mb={1}>
          <Typography variant="h5" fontWeight={700}>
            {user.contractStats.contractsCount}
          </Typography>
        </Stack>
      )}
    </MainSectionBox>
  ) : (
    <></>
  );
}

import { styled, useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Box, Container, Grid, useMediaQuery } from '@mui/material';
import { desktopHeaderHeight, mobileBottomNavHeight, mobileHeaderHeight } from 'global/Constants/HeaderFooterConstants';

interface LPSectionProps {
  image: string;
  chipText?: string;
  title: string;
  children: React.ReactElement;
  background?: string;
  backgroundColor?: string;
  imageOnRight?: boolean;
  minHeight?: string;
  darkMode?: boolean;
  extra?: React.ReactNode;
  chipBgColor?: string;
  chipTextColor?: string;
}

const StyledImage = styled('img')(({ theme }) => ({
  width: '100%',
  objectFit: 'contain',
  maxWidth: '560px',
  maxHeight: '560px',
  marginLeft: 'auto',
  marginRight: 'auto',
  [theme.breakpoints.down('md')]: {
    maxWidth: '500px',
    maxHeight: '500px',
  },
}));

interface StyledBoxProps {
  background?: string;
  backgroundColor?: string;
  minHeight?: string;
  isMobile?: boolean;
}

export const LPSectionUl = styled('ul')(({ theme }) => ({
  paddingInlineStart: '40px',
  [theme.breakpoints.down('md')]: {
    paddingInlineStart: '30px',
  },
}));

export const LPSectionOl = styled('ol')(({ theme }) => ({
  paddingInlineStart: '40px',
  [theme.breakpoints.down('md')]: {
    paddingInlineStart: '30px',
  },
}));

export const LPSectionLI = styled('li')(({ theme }) => ({
  paddingTop: '4px',
  paddingBottom: '4px',
  [theme.breakpoints.down('md')]: {
    paddingTop: '3px',
    paddingBottom: '3px',
  },
}));

export interface LPSectionTypographyProps extends LPTextProps {
  children?: React.ReactNode;
}

export function LPSectionTypography({ darkMode, children }: LPSectionTypographyProps) {
  const theme = useTheme();
  return (
    <Typography
      component={'div'}
      sx={{
        color: darkMode ? theme.palette.common.white : theme.palette.grey[600],
        fontSize: '20px',
        [theme.breakpoints.down('lg')]: {
          fontSize: '18px',
        },
        [theme.breakpoints.down('md')]: {
          fontSize: '16px',
        },
      }}
    >
      {children}
    </Typography>
  );
}

export interface LPTextProps {
  darkMode?: boolean;
}

interface LPChipProps extends LPTextProps {
  chipBgColor?: string;
}

const LPChip = styled('span', {
  shouldForwardProp: prop => prop !== 'darkMode' && prop !== 'chipBgColor',
})<LPChipProps>(({ darkMode, chipBgColor, theme }) => ({
  backgroundColor: chipBgColor ? chipBgColor : darkMode ? '#D4E0FC26' : '#D4E0FC',
  borderRadius: '20px',
  paddingTop: '12px',
  paddingRight: '16px',
  paddingBottom: '12px',
  paddingLeft: '16px',
  [theme.breakpoints.down('md')]: {
    paddingTop: '8px',
    paddingBottom: '8px',
  },
}));

export const StyledLPBox = styled(Box, {
  shouldForwardProp: prop =>
    prop !== 'backgroundColor' && prop !== 'background' && prop !== 'isMobile' && prop !== 'minHeight',
})<StyledBoxProps>(({ backgroundColor, background, minHeight, isMobile }) => ({
  backgroundColor: backgroundColor ? backgroundColor : undefined,
  background: background ? background : undefined,
  minHeight: minHeight
    ? minHeight
    : isMobile
    ? `calc(100vh - ${mobileHeaderHeight}px - ${mobileBottomNavHeight}px)`
    : `calc(100vh - ${desktopHeaderHeight}px)`,
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'center',
  // This is for iPad Pro Portrait
  '@media screen and ( max-width: 1050px ) and ( min-height: 1000px )': {
    minHeight: '700px',
  },
  // This is for iPad Pro Landscape
  '@media screen and ( max-width: 1400px ) and ( min-height: 1000px )': {
    minHeight: '800px',
  },
  position: 'relative',
  zIndex: 1,
}));

export default function LPSection({
  image,
  chipText,
  title,
  background,
  backgroundColor,
  children,
  imageOnRight,
  minHeight,
  darkMode,
  extra,
  chipBgColor,
  chipTextColor,
}: LPSectionProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <StyledLPBox
      background={background}
      backgroundColor={backgroundColor}
      isMobile={isMobile}
      pt={{ xs: 9, md: 12 }}
      pb={{ xs: 5, md: 12 }}
      minHeight={minHeight}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={{ xs: 4, md: 10 }}
          direction={{ xs: 'column-reverse', md: imageOnRight ? 'row-reverse' : 'row' }}
        >
          <Grid item xs={12} md={6} container>
            <StyledImage src={image} loading="lazy" />
          </Grid>
          <Grid item xs={12} md={6} container alignItems={'center'}>
            <Stack direction="column" rowGap={3} alignContent={'flex-start'}>
              {chipText && (
                <Typography
                  variant="subtitle2"
                  fontWeight={600}
                  fontSize={{ xs: '14px', md: '16px' }}
                  mb={2}
                  sx={{
                    color: chipTextColor ? chipTextColor : darkMode ? theme.palette.common.white : '#1F4DE2',
                  }}
                >
                  <LPChip darkMode={darkMode} chipBgColor={chipBgColor}>
                    {chipText}
                  </LPChip>
                </Typography>
              )}
              <Typography
                variant="h3"
                color={darkMode ? theme.palette.common.white : theme.palette.common.black}
                fontWeight={700}
                fontFamily={'Inter'}
                fontSize={{ xs: '24px', md: '36px', lg: '48px' }}
              >
                {title}
              </Typography>
              {children}
            </Stack>
          </Grid>
        </Grid>
        {extra}
      </Container>
    </StyledLPBox>
  );
}

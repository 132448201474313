import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { DetailContainer, CenteredBox } from 'components/terms/Containers';
import Template from 'components/terms/Template';
import { useTitle } from 'utils/router';

interface MobileProps {
  isMobile: boolean;
  isTablet: boolean;
}

const BannerContent = ({ isMobile, isTablet }: MobileProps): JSX.Element => (
  <CenteredBox>
    <Typography variant={isTablet ? (isMobile ? 'h5' : 'h3') : 'h2'}>About Us</Typography>
  </CenteredBox>
);

export default function About(): JSX.Element {
  useTitle('About Us');
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isTabletScreen = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <>
      <Template
        backgroundColor="#D2F0E0"
        bannerContent={<BannerContent isMobile={isMobileScreen} isTablet={isTabletScreen} />}
      >
        <DetailContainer>
          <Typography variant={isMobileScreen ? 'h6' : 'h5'}>Why we built Shoutt</Typography>
          <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
            Shoutt was created to transform how freelancers and businesses connect, collaborate and transact. Our team
            of marketers and engineers from the UK and around the globe grew tired of frustrations posed by generalist
            platforms. We knew there had to be a better way—so we built it.
          </Typography>
          <Typography variant={isMobileScreen ? 'h6' : 'h5'} mt={3}>
            Scale your freelance business to the next level
          </Typography>
          <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
            Freelancing isn’t just about chasing gigs; it’s about building a sustainable business. Shoutt isn’t just
            another freelancer marketplace; it’s a platform designed to empower your independence and financial
            security. We combine the best aspects of Slack, Upwork, and Stripe into one seamless, easy-to-use
            platform—without taking a commission from your hard-earned money.
            <br />
            <br />
            Everything happens in a dynamic, multi-functional chat space. Here, you can create contracts, issue
            invoices, submit work, get paid, and, of course, chat with clients. Our tools are designed to make your
            workflow as frictionless as possible, with contract and payment systems that protect both sides, ensuring
            clear and concise agreements every time.
            <br />
            <br />
            Not only does Shoutt help to expand your client base by getting discovered on our vetted marketplace, it
            also makes subcontracting easy. Bring other freelancers on board to handle overflow work, expand your
            service offerings, and free up your precious time.
          </Typography>

          <Typography variant={isMobileScreen ? 'h6' : 'h5'} mt={3}>
            Hiring digital talent made easy
          </Typography>
          <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
            Shoutt makes it easier than ever to find, hire, and pay freelancers from around the world. We screen every
            freelancer to ensure your time is not wasted sifting through low quality proposals or profiles. Say goodbye
            to GPT boilerplate pitches, and get your projects moving faster than ever before.
            <br />
            <br />
            All transactions on Shoutt are run through our secure payment protection system, which you also use to pay
            any freelancer independently of our vetted marketplace. Transactions are always 100% commission-free for
            both sides.
            <br />
            <br />
            Need help finding the right talent? Use our concierge service and leave the hard work to us. Simply submit
            your brief and our team will source 3 highly relevant proposals.
            <br />
            <br />
            With Shoutt, you’re not just hiring for a one-off project—you’re building a powerful, long-term network of
            freelancers that grows alongside your business.
          </Typography>
        </DetailContainer>
      </Template>
    </>
  );
}

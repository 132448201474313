import {
  IConnection,
  IConnectionSearchRequest,
  IConnectionCreateRequest,
  IConnectionCount,
  IInvitationCreateRequest,
} from 'global/interfaces/connection';
import { authorizedDelete, authorizedGet, authorizedPost, authorizedPut } from './baseApiService';
import { getQueryParams } from 'utils/url';
import { ConnectionDirection } from 'global/enums/connectionDirection';
import { ConnectionStatus } from 'global/enums/connectionStatus';
import { IEmailInvitationForm, IEmailInvitationResponse } from 'global/interfaces/emailInvitation';

const baseUrl = 'network';
const connectionsUrl = 'connections';

export async function getActiveConnections(): Promise<IConnection[]> {
  const req: IConnectionSearchRequest = {
    statuses: [ConnectionStatus.Active],
  };
  return getConnections(req);
}

export async function getConnections(req: IConnectionSearchRequest): Promise<IConnection[]> {
  return await authorizedGet(`${baseUrl}/${connectionsUrl}?${getQueryParams(req)}`);
}

export async function removeConnection(connectionId: string): Promise<boolean> {
  return await authorizedDelete(`${baseUrl}/${connectionsUrl}/${connectionId}`);
}

export async function acceptConnectionRequest(connectionId: string): Promise<IConnection> {
  const createReq: IConnectionCreateRequest = {
    connectionId,
    accept: true,
  };
  return await createConnection(createReq);
}

export async function rejectConnectionRequest(connectionId: string, reason?: string): Promise<IConnection> {
  const createReq: IConnectionCreateRequest = {
    connectionId,
    reason,
    accept: false,
  };
  return await createConnection(createReq);
}

async function createConnection(createReq: IConnectionCreateRequest): Promise<IConnection> {
  return await authorizedPost(`${baseUrl}/${connectionsUrl}`, createReq);
}

export async function getConnectionToUser(userId: string): Promise<IConnection | null> {
  const req: IConnectionSearchRequest = {
    otherUserId: userId,
  };
  const connections = await getConnections(req);
  if (connections && connections.length > 0) {
    return connections[0];
  }
  return null;
}

export async function createInvitation(createReq: IInvitationCreateRequest): Promise<IConnection> {
  return await authorizedPost(`${baseUrl}/invitations`, createReq);
}

export async function createEmailInvitation(createReq: IEmailInvitationForm): Promise<IEmailInvitationResponse> {
  return await authorizedPost(`${baseUrl}/email-invitation`, createReq);
}

export async function getOrCreateConnectionToUser(userId: string): Promise<IConnection> {
  const connection = await getConnectionToUser(userId);
  if (connection) {
    return connection;
  } else {
    const request: IInvitationCreateRequest = {
      userId: userId,
    };
    return await createInvitation(request);
  }
}

export async function getInvitations(): Promise<IConnection[]> {
  const req: IConnectionSearchRequest = {
    statuses: [ConnectionStatus.Invitation, ConnectionStatus.Rejected],
  };
  return await authorizedGet(`${baseUrl}/${connectionsUrl}?${getQueryParams(req)}`);
}

export async function getReceivedInvitationCount(): Promise<IConnectionCount> {
  const req: IConnectionSearchRequest = {
    direction: ConnectionDirection.Receive,
    statuses: [ConnectionStatus.Invitation],
  };
  return await authorizedGet<IConnectionCount>(`${baseUrl}/counts?${getQueryParams(req)}`);
}

export async function getConnectionsCount(): Promise<IConnectionCount> {
  const req: IConnectionSearchRequest = {
    statuses: [ConnectionStatus.Active, ConnectionStatus.Removed],
  };
  return await authorizedGet<IConnectionCount>(`${baseUrl}/counts?${getQueryParams(req)}`);
}

export async function withdrawConnectionRequest(connectionId: string): Promise<boolean> {
  return authorizedPut(`${baseUrl}/${connectionsUrl}/withdraw/${connectionId}`, {});
}

import { Divider, Grid, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { IFrontUser } from 'global/interfaces/user';
import UserProfileHeadSection, { PaddedBox } from './sections/UserProfileHeadSection/UserProfileHeadSection';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import UserPortfolioSection from './sections/UserPortfolioSection/UserPortfolioSection';
import UserWorkHistorySection from './sections/UserWorkHistorySection';
import UserLanguagesSection from './sections/UserLanguagesSection';
import { IFileMetadata } from 'global/interfaces/file';
import UserSkillSection from './sections/UserSkillSection';
import UserPricing, { StyledSwipeableDrawer } from './sections/UserPricing';
import { useContext, useState } from 'react';
import { UserConnectionSection } from './sections/UserConnectionSection/UserConnectionSection';
import { PackageType } from 'global/enums/packageType';
import { AuthContext } from 'contexts/AuthContext';
import { AddBasicDetails } from './sections/UserConnectionSection/AddBasicDetails';
import { IConnection, IInvitationCreateRequest } from 'global/interfaces/connection';
import { createInvitation } from 'services/connectionService';
import { pushToDataLayer } from 'utils/tagHelper';
import { connectionRequestEvent } from 'global/constants';
import { showError } from 'utils/errorHandler';
import { useNavigate, useParams } from 'react-router-dom';
import IApiError from 'global/interfaces/api';
import UserRatingSection from './sections/UserRatingSection';
import { UserPersonalLinks } from './sections/UserPersonalLinkSection';
import { ClearBitLink } from 'components/common/ClearBitLink';
import VettingAlert from 'components/common/VettingAlert';
import UserNotableClientSection from './sections/UserNotableClientSection';
import { isAdminEdit } from 'utils/adminUtil';

interface IViewUserProfileProps {
  user: IFrontUser;
}

export const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: '16px',
  marginRight: '24px',
  backgroundColor: theme.palette.grey[200],

  [theme.breakpoints.down('sm')]: {
    marginLeft: '16px',
    marginRight: '16px',
  },
}));

export const PricingDetailStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  backgroundColor: 'transparent',
  border: 'none',
  gap: theme.spacing(2),
  alignItems: 'center',
  cursor: 'pointer',
  overflow: 'hidden',

  '&:hover': {
    opacity: 0.7,
  },
}));

export const StyledBackArrowIcon = styled(ArrowBackOutlinedIcon)(() => ({
  fontSize: '24px',
  marginLeft: '-4px',
}));

const AboutMeBox = styled(PaddedBox)`
  overflow: hidden;
  word-break: break-word;
`;

export const ViewUserProfile = ({ user }: IViewUserProfileProps) => {
  const processedFiles: IFileMetadata[] | undefined = user?.portfolioFiles?.filter(x => x.isProcessed);
  const [drawer, setDrawer] = useState<boolean>(false);
  const [requestLoading, setRequestLoading] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<PackageType>(PackageType.Loud);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const authContext = useContext(AuthContext);
  const { id } = useParams();
  const navigate = useNavigate();

  const createConnection = async (): Promise<void> => {
    setRequestLoading(true);
    const createInvitationRequest: IInvitationCreateRequest = {
      userId: id,
    };
    return await new Promise<void>((resolve, reject) => {
      createInvitation(createInvitationRequest)
        .then((connection: IConnection) => {
          pushToDataLayer(connectionRequestEvent);
          setRequestLoading(false);
          resolve();
          navigate(`/workroom/${connection.chatThreadId}`);
        })
        .catch((err: IApiError) => {
          reject();
          showError(err);
        });
    });
  };

  return (
    <Grid container columnSpacing={3} marginBottom={3}>
      <>
        {user.id == authContext?.user?.id && (
          <Grid item xs={12}>
            <VettingAlert sx={{ mb: !isMobile ? 3 : 0 }} darkerInfo={isMobile} />
          </Grid>
        )}

        <Grid item lg={8} md={7.5} sm={12} xs={12}>
          <UserProfileHeadSection user={user} />

          {isMobile && (
            <>
              <UserConnectionSection
                openDetailsDrawer={() => setDrawer(true)}
                requestLoading={requestLoading}
                createConnection={createConnection}
              />
              <Grid item xs={12}>
                <StyledSwipeableDrawer
                  anchor="right"
                  open={drawer}
                  onClose={() => setDrawer(false)}
                  onOpen={() => setDrawer(true)}
                >
                  <AddBasicDetails
                    closeCancel={() => setDrawer(false)}
                    createConnection={createConnection}
                    requestLoading={requestLoading}
                  />
                </StyledSwipeableDrawer>
              </Grid>
            </>
          )}

          {user.bio !== undefined &&
            user.bio?.length > 0 && [
              <PaddedBox key="bio-heading">
                <Typography variant="h6">About</Typography>
              </PaddedBox>,
              <AboutMeBox key="bio-text">
                <Typography variant="body1" whiteSpace="break-spaces">
                  {user.bio}
                </Typography>
              </AboutMeBox>,
            ]}

          {user.notableClients.length > 0 && <UserNotableClientSection notableClients={user.notableClients} />}

          {user.links.length > 0 && <UserPersonalLinks links={user.links} />}

          {processedFiles !== undefined && processedFiles.length > 0 && (
            <UserPortfolioSection isSeller={user.isSellingServices} processedFiles={processedFiles} />
          )}

          {!!user.skills.length && <UserSkillSection user={user} />}

          {user.workHistories !== undefined && user.workHistories.length > 0 && isAdminEdit() && (
            <UserWorkHistorySection workHistories={user.workHistories} />
          )}
        </Grid>

        <Grid item lg={4} md={4.5} sm={12} xs={12}>
          <UserPricing
            user={user}
            drawer={drawer}
            requestLoading={requestLoading}
            setDrawer={setDrawer}
            setTabValue={setTabValue}
            tabValue={tabValue}
            createConnection={createConnection}
          />

          <Grid container>
            <Grid item xs={12}>
              <UserLanguagesSection user={user} />
            </Grid>
          </Grid>
          {user.contractStats.reviewRate !== null && (
            <Grid item xs={12}>
              <UserRatingSection />
            </Grid>
          )}
        </Grid>

        {(user.links.length > 0 || user.notableClients.length > 0) && (
          <Grid item xs={12} mt={'20px'} marginLeft={isMobile ? '16px' : undefined}>
            <ClearBitLink />
          </Grid>
        )}
      </>
    </Grid>
  );
};

export default ViewUserProfile;

import { IConciergeForm } from 'global/interfaces/concierge';
import { SchemaOf, mixed, object, string } from 'yup';

export const conciergeValidationSchema = (
  shouldValidateToken: boolean,
  currencySymbol: string,
): SchemaOf<IConciergeForm> => {
  const baseSchema = object({
    name: string()
      .required('Name is required')
      .trim()
      .min(2, 'Minimum of 2 characters needed')
      .max(30, 'Maximum of 30 characters allowed'),
    email: string()
      .required('Email is required')
      .trim()
      .max(150, 'Maximum of 150 characters allowed')
      .email('Invalid email address'),
    description: string()
      .required('Project details are required')
      .min(20, 'Minimum of 20 characters needed')
      .max(2000, 'Maximum of 2000 characters allowed'),
    title: string()
      .required('Title is required')
      .min(2, 'Minimum of 2 characters needed')
      .max(100, 'Maximum of 100 characters allowed'),
    budget: string()
      .required('Budget is required')
      .test(
        'fileSize',
        `Minimum budget for this service is ${currencySymbol}500`,
        value => value != `Below ${currencySymbol}500`,
      ),
    file: mixed().test('fileSize', 'File size must be no more than 25MB', value => {
      if (!value) return true;

      const maxSize = 25 * 1024 * 1024;
      return value.size <= maxSize;
    }),
    currency: string().required('Currency is required'),
    userId: string().notRequired(),
  });

  if (shouldValidateToken) {
    return baseSchema.shape({
      token: string().required('You must complete the reCAPTCHA'),
    });
  }

  return baseSchema.shape({
    token: string().notRequired(),
  });
};

import { Grid, Skeleton, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import IApiError from 'global/interfaces/api';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getConnectionToUser } from 'services/connectionService';
import { showError } from 'utils/errorHandler';
import PeopleIcon from '@mui/icons-material/People';
import MessageIcon from '@mui/icons-material/Message';
import { BorderedBox } from '../../common/BorderedBox';
import { ConnectionStatus } from 'global/enums/connectionStatus';
import { AuthContext } from 'contexts/AuthContext';
import { redirectToSignUp } from 'utils/router';
import { RoundButton } from 'components/common/Button/RoundButton';
import { ConnectionDirection } from 'global/enums/connectionDirection';
import { IConnection } from 'global/interfaces/connection';
import { UserReceiveConnect } from './UserReceiveConnect';
import { getUser } from 'services/userService';
import { validateBasicUserInfo } from 'global/validations/user';
import { completeBuyerProfileEvent } from 'global/constants';
import { pushToDataLayer } from 'utils/tagHelper';

export const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 20px;
`;

const StyledBorderedBox = styled(BorderedBox)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginBottom: '32px',
  },
}));

enum ConnectionStep {
  NotSent = 0,
  Sent = 1,
  Active = 2,
  NotShown = 3,
  Received = 4,
}

interface IUserConnectionSectionProps {
  openDetailsDrawer: () => void;
  requestLoading: boolean;
  createConnection: () => void;
}

export const UserConnectionSection = ({
  openDetailsDrawer,
  requestLoading,
  createConnection,
}: IUserConnectionSectionProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>();
  const [connection, setConnection] = useState<IConnection | null>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { id } = useParams();
  const authContext = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    if (authContext.user && id !== undefined && id !== authContext.user?.id) {
      setLoading(true);
      getConnectionToUser(id)
        .then(connection => {
          setConnection(connection);
          setLoading(false);
        })
        .catch((err: IApiError) => {
          setLoading(false);
          showError(err);
        });
    } else if (!authContext.user) {
      setConnection(null);
    }
  }, [id, authContext.user?.id]);

  const handleOnConnect = () => {
    if (authContext.user) {
      getUser()
        .then(async currentUser => {
          const isValid = await validateBasicUserInfo(currentUser);
          if (!isValid) {
            pushToDataLayer(completeBuyerProfileEvent, {
              transaction_id: authContext.user?.id,
            });
            openDetailsDrawer();
            return;
          }
          createConnection();
        })
        .catch(showError);
    } else {
      location.state = {
        ...location.state,
        sellerId: id,
      };
      redirectToSignUp(navigate, location);
    }
  };

  if (id === authContext.user?.id) {
    return null;
  }

  const getConnectionStep = () => {
    if (connection === undefined) {
      return ConnectionStep.NotShown;
    } else if (connection === null) {
      return ConnectionStep.NotSent;
    } else if (connection.status === ConnectionStatus.Active) {
      return ConnectionStep.Active;
    } else if (connection.status === ConnectionStatus.Withdraw) {
      return ConnectionStep.NotSent;
    } else if (connection.status === ConnectionStatus.Invitation) {
      if (connection.direction == ConnectionDirection.Receive) {
        return ConnectionStep.Received;
      } else if (connection.direction == ConnectionDirection.Send) {
        return ConnectionStep.Sent;
      }
    }

    return ConnectionStep.NotShown;
  };

  if (loading) {
    return (
      <Grid>
        {[...Array(2)].map((e, index) => (
          <Grid marginTop={2} key={index}>
            <StyledSkeleton variant="rounded" />
          </Grid>
        ))}
      </Grid>
    );
  }

  const connectionStep = getConnectionStep();
  return (
    <StyledBorderedBox showBorder={!isMobile}>
      {connectionStep === ConnectionStep.NotSent && (
        <Grid container justifyContent="center" gap={2.5}>
          <RoundButton
            startIcon={<MessageIcon />}
            variant="contained"
            size="large"
            fullWidth
            onClick={() => handleOnConnect()}
            loading={requestLoading}
          >
            Message
          </RoundButton>
        </Grid>
      )}

      {connectionStep === ConnectionStep.Sent && (
        <Stack alignItems="center" gap={2.5}>
          <RoundButton color="success" variant="outlined" disabled fullWidth startIcon={<PeopleIcon />}>
            Connection request is sent.
          </RoundButton>
          <Typography variant="subtitle1">Waiting for a reply...</Typography>
        </Stack>
      )}

      {connectionStep === ConnectionStep.Received && connection && (
        <UserReceiveConnect
          connection={connection}
          onReject={() =>
            setConnection(currConn => ({
              ...currConn,
              status: ConnectionStatus.Rejected,
            }))
          }
        />
      )}

      {connectionStep === ConnectionStep.Active && (
        <Grid container>
          <RoundButton
            startIcon={<MessageIcon />}
            variant="contained"
            size="large"
            fullWidth
            onClick={() => navigate(`/workroom/${connection?.chatThreadId}`)}
          >
            Message
          </RoundButton>
        </Grid>
      )}
    </StyledBorderedBox>
  );
};

import { Box, Chip, Divider, IconButton, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { EllipsisTypography } from '../Typography/EllipsisTypography';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Link, useSearchParams } from 'react-router-dom';
import {
  ContractDeliverableStatus,
  ContractPaymentStatus,
  ContractStatus,
  ContractType,
  ContractViewType,
  IContractDisplay,
  IContractRetainerPayment,
  IDeliverable,
  StatusColourDictionary,
} from 'global/interfaces/contract';
import { getContractInvoiceUrl, getRetainerPayments } from 'services/contractService';
import IApiError from 'global/interfaces/api';
import { showError } from 'utils/errorHandler';
import { dateStringFromDate } from 'utils/dateFormat';
import { getContractStatusText, getPayoutStatusText, getRetainerPaymentStatusText } from 'utils/contractTextHelper';
import { getCurrencyDetails } from '../Select/CountrySelect';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { AccessTime, Tune } from '@mui/icons-material';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import { DeliverableTypography, IndentSmallTypography, JustifiedBox } from '../StyledWorkroom/WorkroomStyles';
import { DrawerBody, DrawerHeader } from '../StyledDrawer/DrawerStyles';
import ContractPaymentButtons from './contractPaymentButtons';
import ContractAcceptButtons from './contractAcceptButtons';
import { getReceiveText } from './CreateRetainerContract';

const StyledDivider = styled(Divider)(() => ({
  marginTop: '30px',
  width: '100%',
}));

export const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const StyledGrid = styled(Grid)(() => ({
  marginBottom: '16px',
}));

const StyledTypography = styled(Typography)(() => ({
  overflowWrap: 'break-word',
}));

export const DeliverablesBox = styled(FlexBox)(() => ({
  alignItems: 'flex-start',
}));

export interface IContractDetailsProps {
  contract: IContractDisplay;
  setDrawer?: React.Dispatch<boolean>;
  hideHeading?: boolean;
  successfulPaymentRedirect?: boolean;
}

export default function ContractDetails({
  contract,
  hideHeading,
  setDrawer,
  successfulPaymentRedirect,
}: IContractDetailsProps) {
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isBuyer = Boolean(contract.buyerAdditionalDetails);
  const contractStatus = successfulPaymentRedirect ? ContractStatus.InProgress : contract.status;
  const paymentDate = successfulPaymentRedirect ? new Date().toISOString() : contract.paymentDate;

  const [retainerPayments, setRetainerPayments] = useState<IContractRetainerPayment[]>([]);

  const hasRevisions = (deliverable: IDeliverable) =>
    deliverable.status !== ContractDeliverableStatus.Approved && deliverable.revisionsRequested > 0;

  const currencyDetails = getCurrencyDetails(contract.currency);

  useEffect(() => {
    if (contract.contractType === ContractType.Retainer) {
      getRetainerPayments(contract.contractId ?? '')
        .then((res: IContractRetainerPayment[]) => {
          setRetainerPayments(res);
        })
        .catch((err: IApiError) => {
          showError(err);
        });
    } else {
      setRetainerPayments([]);
    }
  }, [contract]);

  const openInvoice = (invoiceId: string, isRetainerInvoice: boolean): void => {
    getContractInvoiceUrl(invoiceId, isRetainerInvoice)
      .then((res: string) => {
        window.open(res, '_blank');
      })
      .catch((err: IApiError) => {
        showError(err);
      });
  };

  return (
    <>
      <Grid container>
        <>
          {!isMobileScreen ? (
            <>
              {!hideHeading && (
                <DrawerHeader container sx={isMobileScreen ? { marginBottom: '16px' } : undefined}>
                  <Stack justifyContent="center" gap={1} width="100%">
                    <Stack direction="row" width="100%" gap={1} justifyContent="space-between" alignItems="center">
                      <EllipsisTypography variant="h6">
                        {`Contract with ${
                          isBuyer
                            ? contract.buyerAdditionalDetails?.sellerDisplayName
                            : contract.sellerAdditionalDetails?.buyerDisplayName
                        }`}
                      </EllipsisTypography>

                      <IconButton onClick={() => setDrawer && setDrawer(false)} sx={{ mr: '-12px' }}>
                        <CloseOutlinedIcon />
                      </IconButton>
                    </Stack>

                    {isBuyer &&
                      (contract.paymentStatus == ContractPaymentStatus.Awaiting ||
                        contract.paymentStatus == ContractPaymentStatus.Failed) &&
                      contract.status != ContractStatus.Cancelled &&
                      contract.status != ContractStatus.Offered && (
                        <ContractPaymentButtons
                          contractId={contract.contractId}
                          contractName={contract.name}
                          firstPayment={
                            contract.contractType === ContractType.FixedPrice || retainerPayments.length == 0
                          }
                          largeWidth={6}
                          contractType={contract.contractType}
                          recurrence={contract.recurrence}
                        />
                      )}
                    {!isBuyer && contract.status == ContractStatus.Offered && (
                      <ContractAcceptButtons
                        contractId={contract.contractId}
                        contractName={contract.name}
                        largeWidth={6}
                        onCompletion={() => setDrawer && setDrawer(false)}
                        returnUrl={`workroom/${contract?.chatThreadId}`}
                      />
                    )}
                  </Stack>
                </DrawerHeader>
              )}
            </>
          ) : (
            <>
              {!hideHeading && (
                <DrawerHeader>
                  <Stack justifyContent="center" gap={2} width="100%">
                    <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
                      <EllipsisTypography variant="h6">
                        {`Contract with ${
                          isBuyer
                            ? contract.buyerAdditionalDetails?.sellerDisplayName
                            : contract.sellerAdditionalDetails?.buyerDisplayName
                        }`}
                      </EllipsisTypography>

                      <IconButton onClick={() => setDrawer && setDrawer(false)} sx={{ mr: '-12px' }}>
                        <CloseOutlinedIcon />
                      </IconButton>
                    </Stack>

                    {isBuyer &&
                      (contract.paymentStatus == ContractPaymentStatus.Awaiting ||
                        contract.paymentStatus == ContractPaymentStatus.Failed) &&
                      contract.status != ContractStatus.Cancelled &&
                      contract.status != ContractStatus.Offered && (
                        <ContractPaymentButtons
                          contractId={contract.contractId}
                          contractName={contract.name}
                          firstPayment={
                            contract.contractType === ContractType.FixedPrice || retainerPayments.length == 0
                          }
                          contractType={contract.contractType}
                          recurrence={contract.recurrence}
                        />
                      )}
                    {!isBuyer && contract.status == ContractStatus.Offered && (
                      <ContractAcceptButtons
                        contractId={contract.contractId}
                        contractName={contract.name}
                        onCompletion={() => setDrawer && setDrawer(false)}
                        returnUrl={`workroom/${contract?.chatThreadId}`}
                      />
                    )}
                  </Stack>
                </DrawerHeader>
              )}
            </>
          )}
        </>

        {hideHeading && <StyledDivider sx={{ mt: '24px' }} />}
      </Grid>

      <DrawerBody container className="contractDrawerBody">
        <StyledGrid item xs={12}>
          <StyledTypography variant="subtitle1">Contract information</StyledTypography>
        </StyledGrid>

        <Stack gap={1} width="100%">
          <Grid container alignItems="center" justifyContent="space-between">
            <StyledTypography variant="subtitle2">Contract Title</StyledTypography>

            <StyledTypography variant="body2">{contract.name}</StyledTypography>
          </Grid>

          <Grid container alignItems="center" justifyContent="space-between">
            <StyledTypography variant="subtitle2">Contract ID</StyledTypography>

            <StyledTypography variant="body2">{contract.contractId}</StyledTypography>
          </Grid>

          <Grid container alignItems="center" justifyContent="space-between">
            <StyledTypography variant="subtitle2">Contract date</StyledTypography>

            <StyledTypography variant="body2">{dateStringFromDate(contract.createdDate)}</StyledTypography>
          </Grid>

          <Grid container alignItems="center" justifyContent="space-between">
            <StyledTypography variant="subtitle2">Contract status</StyledTypography>

            <Chip
              sx={contractStatus === ContractStatus.Complete ? { color: '#ffff' } : undefined}
              label={
                <Typography
                  variant="caption"
                  color={
                    ContractStatus.Created === contractStatus || ContractStatus.Offered == contractStatus
                      ? theme.palette.common.black
                      : theme.palette.common.white
                  }
                >
                  {getContractStatusText(contract, successfulPaymentRedirect)}
                </Typography>
              }
              color={StatusColourDictionary[contractStatus]}
              size="small"
            />
          </Grid>

          <Grid container alignItems="center" justifyContent="space-between">
            <StyledTypography variant="subtitle2">{isBuyer ? 'Seller name' : 'Buyer name'}</StyledTypography>

            <StyledTypography variant="body2">
              {isBuyer
                ? contract.buyerAdditionalDetails?.sellerDisplayName
                : contract.sellerAdditionalDetails?.buyerDisplayName}
            </StyledTypography>
          </Grid>

          {searchParams.get('successfulPayment') !== 'true' && (
            <Grid container alignItems="center" justifyContent="space-between">
              <StyledTypography variant="subtitle2">Completed date</StyledTypography>
              <StyledTypography variant="body2">
                {contract.completedDate !== null ? dateStringFromDate(contract.completedDate) : 'N/A'}
              </StyledTypography>
            </Grid>
          )}

          <Grid container alignItems="center" justifyContent="space-between">
            <StyledTypography variant="subtitle2">Type</StyledTypography>

            <StyledTypography variant="body2">
              {contract?.viewType === ContractViewType.Sell ? 'Sell' : 'Purchase'}
            </StyledTypography>
          </Grid>
          <Grid container alignItems="center" justifyContent="space-between">
            <StyledTypography variant="subtitle2">Contract type</StyledTypography>

            <StyledTypography variant="body2">
              {contract.contractType === ContractType.FixedPrice ? ' Fixed Price' : contract.contractType}
            </StyledTypography>
          </Grid>
          {contract.contractType === ContractType.FixedPrice && (
            <Grid container alignItems="center" justifyContent="space-between">
              <StyledTypography variant="subtitle2">Payment release</StyledTypography>
              {contract.endRelease === 1 && <StyledTypography variant="body2">100% at end</StyledTypography>}
              {contract.endRelease === 0.75 && (
                <StyledTypography variant="body2">25% up front / 75% at end</StyledTypography>
              )}
              {contract.endRelease === 0.5 && (
                <StyledTypography variant="body2">50% up front / 50% at end</StyledTypography>
              )}
              {contract.endRelease === 0.25 && (
                <StyledTypography variant="body2">75% up front / 25% at end</StyledTypography>
              )}
              {contract.endRelease === 0.0 && (
                <StyledTypography variant="body2">100% up front (7 days)</StyledTypography>
              )}
            </Grid>
          )}
          <Grid container alignItems="center" justifyContent="space-between">
            <StyledTypography variant="subtitle2">Contract total</StyledTypography>

            <StyledTypography variant="body2">
              {currencyDetails?.currencySymbol}
              {contract.displayAmount?.toFixed(2)}
            </StyledTypography>
          </Grid>
          {contract.status === ContractStatus.Offered &&
            !isBuyer &&
            contract.contractType === ContractType.FixedPrice && (
              <>
                <Grid container alignItems="center" justifyContent="space-between">
                  <Link to="https://stripe.com/pricing" target="_blank">
                    <StyledTypography variant="subtitle2" color={theme.palette.primary.dark}>
                      Stripe Fee (approx 3%)
                    </StyledTypography>
                  </Link>

                  <StyledTypography variant="body2">
                    {currencyDetails?.currencySymbol}
                    {((contract.displayAmount ?? 0) * 0.03).toFixed(2)}
                  </StyledTypography>
                </Grid>
                <Grid container alignItems="center" justifyContent="space-between">
                  <StyledTypography variant="subtitle2" fontWeight={700}>
                    You will receive approx
                  </StyledTypography>

                  <StyledTypography variant="subtitle2" fontWeight={700}>
                    {currencyDetails?.currencySymbol}
                    {((contract.displayAmount ?? 0) * 0.97).toFixed(2)}
                  </StyledTypography>
                </Grid>
              </>
            )}

          {contract.settlementRefund && isBuyer && (
            <Grid container alignItems="center" justifyContent="space-between">
              <StyledTypography variant="subtitle2">Total refund</StyledTypography>

              <StyledTypography variant="body2">
                {currencyDetails?.currencySymbol}
                {contract.settlementRefund.contractRefundTotal?.toFixed(2)}
              </StyledTypography>
            </Grid>
          )}
        </Stack>

        {hideHeading && <StyledDivider />}

        <Stack gap={1} width="100%">
          {contract?.viewType === ContractViewType.Buy &&
            contract?.buyerAdditionalDetails !== undefined &&
            contract?.buyerAdditionalDetails?.buyerFee > 0 && (
              <Grid container alignItems="center" justifyContent="space-between">
                <StyledTypography variant="subtitle2">Shoutt Service Fee</StyledTypography>

                <StyledTypography variant="body2">
                  {currencyDetails?.currencySymbol}
                  {contract?.buyerAdditionalDetails?.buyerFee?.toFixed(2)}
                </StyledTypography>
              </Grid>
            )}
        </Stack>

        {contract.contractType == ContractType.FixedPrice && (
          <>
            <StyledGrid item xs={12} mt="32px">
              <JustifiedBox>
                <FlexBox>
                  <StyledTypography variant="subtitle1">Deliverables</StyledTypography>
                </FlexBox>
                {contract.deliverables.some(d => hasRevisions(d)) && (
                  <FlexBox>
                    <Typography variant="subtitle2">Revisions</Typography>
                  </FlexBox>
                )}
              </JustifiedBox>
            </StyledGrid>
            <Stack gap={1} width="100%">
              {contract.deliverables.map((deliverable: IDeliverable, p: number) => (
                <React.Fragment key={p}>
                  <JustifiedBox component="div">
                    <DeliverablesBox>
                      {deliverable.status === ContractDeliverableStatus.Approved && (
                        <CheckCircleOutlinedIcon color="success" />
                      )}
                      {deliverable.status === ContractDeliverableStatus.WaitingApproval && (
                        <AccessTime color="warning" titleAccess="Awaiting Approval" />
                      )}
                      {deliverable.status !== ContractDeliverableStatus.Approved &&
                        deliverable.status !== ContractDeliverableStatus.WaitingApproval && (
                          <CheckBoxOutlineBlankOutlinedIcon color="disabled" />
                        )}
                      <DeliverableTypography variant="subtitle2" mt="2px">
                        {deliverable.name}
                      </DeliverableTypography>
                    </DeliverablesBox>
                    {hasRevisions(deliverable) && (
                      <FlexBox>
                        <IndentSmallTypography variant="body2">
                          {deliverable.revisionsRequested}/{contract.revisions}
                        </IndentSmallTypography>
                      </FlexBox>
                    )}
                  </JustifiedBox>
                </React.Fragment>
              ))}
            </Stack>

            <StyledGrid item xs={12} mt="32px">
              <StyledTypography variant="subtitle1">Terms</StyledTypography>
            </StyledGrid>

            <Stack gap={1}>
              <FlexBox>
                <AccessTime />
                <IndentSmallTypography variant="subtitle2">{contract.delivery} days delivery</IndentSmallTypography>
              </FlexBox>

              <FlexBox>
                <Tune />
                <IndentSmallTypography variant="subtitle2">{contract.revisions} Revisions</IndentSmallTypography>
              </FlexBox>
            </Stack>
          </>
        )}

        {contract.contractType == ContractType.Retainer && (
          <>
            <StyledGrid item xs={12} mt="32px">
              <StyledTypography variant="subtitle1">Retainer Information</StyledTypography>
            </StyledGrid>
            <Grid container alignItems="center" justifyContent="space-between">
              <StyledTypography variant="subtitle2">Recurrence</StyledTypography>

              <StyledTypography variant="body2">{contract.recurrence}</StyledTypography>
            </Grid>
            <Grid container alignItems="center" justifyContent="space-between" mt={1}>
              <StyledTypography variant="subtitle2">Recurring amount</StyledTypography>

              <StyledTypography variant="body2">
                {currencyDetails?.currencySymbol}
                {contract.recurringAmount?.toFixed(2)}
              </StyledTypography>
            </Grid>
            {contract.status === ContractStatus.Offered && !isBuyer && (
              <>
                <Grid container alignItems="center" justifyContent="space-between" mt={1}>
                  <Link to="https://stripe.com/pricing" target="_blank">
                    <StyledTypography variant="subtitle2" color={theme.palette.primary.dark}>
                      Stripe Fee (approx 3%)
                    </StyledTypography>
                  </Link>

                  <StyledTypography variant="body2">
                    {currencyDetails?.currencySymbol}
                    {((contract.displayAmount ?? 0) * 0.03).toFixed(2)}
                  </StyledTypography>
                </Grid>
                <Grid container alignItems="center" justifyContent="space-between" mt={1}>
                  <StyledTypography variant="subtitle2" fontWeight={700}>
                    You will receive approx
                  </StyledTypography>

                  <StyledTypography variant="subtitle2" fontWeight={700}>
                    {currencyDetails?.currencySymbol}
                    {((contract.displayAmount ?? 0) * 0.97).toFixed(2)}
                  </StyledTypography>
                </Grid>
              </>
            )}

            <Grid container alignItems="center" justifyContent="space-between" mt={1}>
              <StyledTypography variant="subtitle2">Duration</StyledTypography>

              <StyledTypography variant="body2">{contract.durationText}</StyledTypography>
            </Grid>
            <Grid container alignItems="center" justifyContent="space-between" mt={1}>
              <StyledTypography variant="subtitle2">End date</StyledTypography>

              <StyledTypography variant="body2">
                {contract.endDate != null ? dateStringFromDate(contract.endDate) : 'N/A'}
              </StyledTypography>
            </Grid>
            <Grid container alignItems="center" justifyContent="space-between" mt={1}>
              <StyledTypography variant="subtitle2">Payment release</StyledTypography>

              <StyledTypography variant="body2">{contract.releaseText}</StyledTypography>
            </Grid>
            {contract.status === ContractStatus.Offered && !isBuyer && (
              <>
                <Grid container justifyContent="space-between" item mt={1}>
                  <StyledTypography variant="subtitle2" fontWeight={700}>
                    Your first payout will be
                  </StyledTypography>

                  <StyledTypography variant="subtitle2" fontWeight={700}>
                    {' '}
                    {' ' + getReceiveText(contract.recurrence, contract.release ?? 0, new Date())}
                  </StyledTypography>
                </Grid>
              </>
            )}
            <StyledGrid item xs={12} mt="32px">
              <JustifiedBox>
                <FlexBox>
                  <StyledTypography variant="subtitle1">Services</StyledTypography>
                </FlexBox>
              </JustifiedBox>
              <JustifiedBox>
                <FlexBox>
                  <StyledTypography variant="body2" mt={1}>
                    {contract.description}
                  </StyledTypography>
                </FlexBox>
              </JustifiedBox>
            </StyledGrid>
          </>
        )}

        {contract.contractType == ContractType.Retainer && retainerPayments.length > 0 && (
          <>
            <StyledGrid item xs={12} mt="16px">
              <JustifiedBox>
                <FlexBox>
                  <StyledTypography variant="subtitle1">Retainer Invoices</StyledTypography>
                </FlexBox>
              </JustifiedBox>
            </StyledGrid>
            <Stack gap={1} width="100%">
              {retainerPayments.map((retPayment: IContractRetainerPayment, p: number) => (
                <React.Fragment key={p}>
                  <JustifiedBox component="div">
                    <FlexBox>
                      {retPayment.invoiceId !== null ? (
                        <Typography
                          variant="subtitle2"
                          style={{ textDecoration: 'underline', color: theme.palette.primary.dark, cursor: 'pointer' }}
                          onClick={() => openInvoice(retPayment.invoiceId ?? '', true)}
                        >
                          {dateStringFromDate(retPayment.date)}
                        </Typography>
                      ) : (
                        <Typography variant="subtitle2">{dateStringFromDate(retPayment.date)}</Typography>
                      )}
                    </FlexBox>
                    <FlexBox>
                      <Typography variant="body2">{getRetainerPaymentStatusText(retPayment)}</Typography>
                    </FlexBox>
                  </JustifiedBox>
                </React.Fragment>
              ))}
            </Stack>
          </>
        )}

        {contract?.viewType === ContractViewType.Sell && (
          <>
            <StyledGrid item xs={12} mt="24px">
              <StyledTypography variant="subtitle1">Items</StyledTypography>
            </StyledGrid>

            <Stack gap={1} width="100%">
              <Grid container alignItems="center" justifyContent="space-between">
                <StyledTypography variant="subtitle2">{contract.name}</StyledTypography>
                <StyledTypography variant="body2">
                  {currencyDetails?.currencySymbol}
                  {contract.displayAmount?.toFixed(2)}
                </StyledTypography>
              </Grid>
              {contract?.sellerAdditionalDetails?.sellerFee !== undefined &&
                contract?.sellerAdditionalDetails?.sellerFee > 0 && (
                  <Grid container alignItems="center" justifyContent="space-between">
                    <StyledTypography variant="subtitle2">Shoutt Service Fee</StyledTypography>
                    <StyledTypography variant="body2">
                      {currencyDetails?.currencySymbol}
                      {contract?.sellerAdditionalDetails?.sellerFee?.toFixed(2)}
                    </StyledTypography>
                  </Grid>
                )}
              {contract?.sellerAdditionalDetails?.stripeFee !== undefined &&
                contract?.sellerAdditionalDetails?.stripeFee > 0 && (
                  <Grid container alignItems="center" justifyContent="space-between">
                    <StyledTypography variant="subtitle2">Stripe Fee</StyledTypography>
                    <StyledTypography variant="body2">
                      {currencyDetails?.currencySymbol}
                      {contract?.sellerAdditionalDetails?.stripeFee?.toFixed(2)}
                    </StyledTypography>
                  </Grid>
                )}
              {contract?.settlementRefund?.contractRefundTotal !== undefined && (
                <Grid container alignItems="center" justifyContent="space-between">
                  <StyledTypography variant="subtitle2">Total Refund</StyledTypography>
                  <StyledTypography variant="body2">
                    {currencyDetails?.currencySymbol}
                    {contract?.settlementRefund.contractRefundTotal?.toFixed(2)}
                  </StyledTypography>
                </Grid>
              )}
              <Grid container alignItems="center" justifyContent="space-between">
                <StyledTypography variant="subtitle2">Total Earnings</StyledTypography>
                {contract.sellerAdditionalDetails?.totalSellerEarnings ? (
                  <StyledTypography variant="body2">
                    {contract?.sellerAdditionalDetails?.totalSellerEarnings < 0 ? '-' : ''}
                    {currencyDetails?.currencySymbol}
                    {contract?.sellerAdditionalDetails?.totalSellerEarnings < 0
                      ? (-contract.sellerAdditionalDetails.totalSellerEarnings).toFixed(2)
                      : contract.sellerAdditionalDetails?.totalSellerEarnings.toFixed(2)}
                  </StyledTypography>
                ) : (
                  <StyledTypography variant="body2">N/A</StyledTypography>
                )}
              </Grid>
            </Stack>
          </>
        )}

        {isBuyer && (
          <>
            <StyledGrid item xs={12} mt="32px">
              <StyledTypography variant="subtitle1">Payment Information</StyledTypography>
            </StyledGrid>

            {contract.contractType != ContractType.Retainer && (
              <Grid container alignItems="center" justifyContent="space-between">
                <StyledTypography variant="subtitle2">Payment date:</StyledTypography>

                <StyledTypography variant="body2">
                  {paymentDate !== null ? dateStringFromDate(paymentDate) : 'Not paid'}
                </StyledTypography>
              </Grid>
            )}
          </>
        )}

        {isBuyer && !(successfulPaymentRedirect && contract?.buyerAdditionalDetails?.paymentCardBrand == null) && (
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            mt={contract.contractType != ContractType.Retainer ? 1 : 0}
          >
            <StyledTypography variant="subtitle2">Payment method:</StyledTypography>

            <StyledTypography variant="body2">
              {contract?.buyerAdditionalDetails?.paymentCardBrand
                ? `${contract?.buyerAdditionalDetails?.paymentCardBrand} ${contract?.buyerAdditionalDetails?.paymentCardLast4}`
                : 'N/A'}
            </StyledTypography>
          </Grid>
        )}

        {isBuyer &&
          contract.contractType != ContractType.Retainer &&
          contract.status != ContractStatus.Created &&
          contract.status != ContractStatus.Offered &&
          contract?.buyerAdditionalDetails?.invoiceNumber !== null && (
            <Grid container alignItems="center" justifyContent="space-between" mt={1}>
              <StyledTypography variant="subtitle2">Invoice Number:</StyledTypography>
              <StyledTypography
                variant="body2"
                style={{ textDecoration: 'underline', color: theme.palette.primary.dark, cursor: 'pointer' }}
                onClick={() => openInvoice(contract?.buyerAdditionalDetails?.invoiceId ?? '', false)}
              >
                {contract?.buyerAdditionalDetails?.invoiceNumber}{' '}
              </StyledTypography>{' '}
            </Grid>
          )}

        {!isBuyer && contract.contractType === ContractType.FixedPrice && (
          <>
            {hideHeading && <StyledDivider />}
            <StyledGrid item xs={12} mt="32px">
              <StyledTypography variant="subtitle1">Payout Information</StyledTypography>
            </StyledGrid>

            <Stack gap={1} width="100%">
              <Grid container alignItems="center" justifyContent="space-between">
                <StyledTypography variant="subtitle2">Type</StyledTypography>

                <StyledTypography variant="body2">
                  {contract?.viewType === ContractViewType.Sell ? 'Sell' : 'Purchase'}
                </StyledTypography>
              </Grid>

              <Grid container alignItems="center" justifyContent="space-between">
                <StyledTypography variant="subtitle2">Payout Status</StyledTypography>

                <StyledTypography variant="body2">{getPayoutStatusText(contract)}</StyledTypography>
              </Grid>

              <Grid container alignItems="center" justifyContent="space-between">
                <StyledTypography variant="subtitle2">Payout Amount</StyledTypography>

                <StyledTypography variant="body2">
                  {contract?.sellerAdditionalDetails?.payoutAmount !== null
                    ? `${
                        currencyDetails?.currencySymbol
                      }${contract?.sellerAdditionalDetails?.totalSellerEarnings?.toFixed(2)} = ${
                        contract?.sellerAdditionalDetails?.payoutCurrency
                      } ${contract?.sellerAdditionalDetails?.payoutAmount?.toFixed(2)}`
                    : 'N/A'}
                </StyledTypography>
              </Grid>

              <Grid container alignItems="center" justifyContent="space-between">
                <StyledTypography variant="subtitle2">Paid out date</StyledTypography>

                <StyledTypography variant="body2">
                  {contract.sellerAdditionalDetails?.paidOutDate !== null
                    ? dateStringFromDate(contract.sellerAdditionalDetails?.paidOutDate)
                    : 'Not paid out'}
                </StyledTypography>
              </Grid>

              <Grid container alignItems="center" justifyContent="space-between">
                <StyledTypography variant="subtitle2">Estimated Arrival Date</StyledTypography>

                <StyledTypography variant="body2">
                  {contract?.sellerAdditionalDetails?.payoutArrivalDate !== null
                    ? dateStringFromDate(contract?.sellerAdditionalDetails?.payoutArrivalDate)
                    : 'N/A'}
                </StyledTypography>
              </Grid>
            </Stack>
          </>
        )}
      </DrawerBody>
    </>
  );
}

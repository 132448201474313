import Add from '@mui/icons-material/Add';
import {
  Button,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FieldArray, FieldArrayRenderProps, FormikProps } from 'formik';
import { ContractViewType, ICreateContractForm } from 'global/interfaces/contract';
import { IUser } from 'global/interfaces/user';
import FormSelect from '../Select/FormSelect';
import DeliveryLookups from 'json/DeliveryLookups.json';
import RevisionLookups from 'json/RevisionLookups.json';
import { FormTermsAndConditions } from '../form/FormTermsAndConditions';
import DeleteIcon from '@mui/icons-material/Delete';
import { RoundButton } from '../Button/RoundButton';
import { StyledDialogActions, StyledDialogTitle } from 'components/common/Dialog';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useState } from 'react';
import { ChatType, IChatUserThread } from 'global/interfaces/chatThread';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Link } from 'react-router-dom';
import { countriesWithCurrency, getCountryCurrencySelectItems } from '../Select/CountrySelect';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { StyledLoadingButton } from 'components/payouts/payoutHandler';
import ReleaseLookups from 'json/FixedReleaseLookups.json';

const StyledFormControl = styled(FormControl)(() => ({
  marginBottom: '12px',
}));

const StyledContentContainer = styled(Grid)(() => ({
  marginTop: '18px',
}));

export const ReceivePackagePriceContainer = styled(Grid)(() => ({
  padding: '12px',
  backgroundColor: '#E6E9F9',
  border: '1px solid',
  borderRadius: '8px',
  borderColor: '#E6E9F9',
  gap: '8px',
  marginTop: '24px',
}));

export const StyledTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    paddingLeft: '0px',
  },
}));

export const StyledAdornment = styled(InputAdornment)(() => ({
  fieldset: {
    marginTop: '6px',
    marginBottom: '6px',
    borderTopWidth: '0px !important',
    borderBottomWidth: '0px !important',
    borderRightColor: 'rgba(0, 0, 0, 0.23) !important',
    borderRightWidth: '1px !important',
    borderLeftWidth: '0px !important',
    borderTopRightRadius: '0px !important',
    borderBottomRightRadius: '0px !important',
  },
}));

export const StyledHourlyDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: '24px 24px !important',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',

  [theme.breakpoints.down('sm')]: {
    padding: '20px 16px !important',
    minHeight: 'calc(100svh - 140px)',
  },
}));

const AddDeliverableButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.dark,
  marginTop: '8px',
  paddingInline: 0,

  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const RemoveDeliverableIconButton = styled(IconButton)(({ theme }) => ({
  border: '1px solid',
  color: theme.palette.grey[500],
  borderColor: theme.palette.grey[300],
  borderRadius: '4px',
  height: '36px',
  width: '36px',
}));

export interface IContractHoursProps extends FormikProps<ICreateContractForm> {
  user: IUser | null;
  back: () => void;
  handleCancel: () => void;
  loading: boolean;
  selectedThread: IChatUserThread | undefined;
}

export const CreateFixedPriceContract: React.FC<IContractHoursProps> = ({
  back,
  handleCancel,
  loading,
  selectedThread,
  ...form
}) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isOneToOneChat = selectedThread?.type == ChatType.OneToOne;
  const [currencySelectItems] = useState(() => getCountryCurrencySelectItems());

  const currencySymbol = countriesWithCurrency.find(c => c.currency === form.values.currency)?.currencySymbol;

  return (
    <>
      <StyledDialogTitle>
        <Stack direction="row" gap={1.5} alignItems="center">
          <IconButton onClick={back} sx={{ marginLeft: '-12px' }}>
            <ArrowBackIcon />
          </IconButton>

          <Typography variant="h6">New Fixed Price {isOneToOneChat && `for ${selectedThread.displayName}`}</Typography>
        </Stack>
        <Link to={process.env.REACT_APP_CONTRACTS_URL ?? ''} target="_blank">
          <IconButton sx={{ marginRight: '-6px' }}>
            <HelpOutlineOutlinedIcon />
          </IconButton>
        </Link>
      </StyledDialogTitle>

      <StyledHourlyDialogContent dividers>
        <Grid xs={12} item>
          <Typography variant="subtitle1">What&apos;s included</Typography>
          <Typography variant="body2" mb={1}>
            {form.values.buySell === ContractViewType.Buy
              ? 'Enter the main deliverables that you will receive.'
              : 'Enter the main deliverables.'}
          </Typography>
          <StyledFormControl
            error={form.touched.deliverables !== undefined && Boolean(form.errors.deliverables)}
            fullWidth
          >
            <FieldArray
              name="deliverables"
              render={(arrayHelpers: FieldArrayRenderProps) => (
                <div>
                  {form.values.deliverables?.map((deliverable, index) => (
                    <Grid container spacing={1} alignItems="center" key={index}>
                      <Grid item xs={10} sm={11}>
                        <TextField
                          name={`deliverables.${index}`}
                          margin="dense"
                          value={form.values.deliverables !== undefined ? form.values.deliverables[index] : ''}
                          onChange={form.handleChange}
                          variant={'outlined'}
                          placeholder="i.e Editing for 30s max video"
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <Tooltip
                                title={`Deliverables form the basis of your contracts, so be concise and accurate for best results. Examples include: “Social media management on Instagram for 1 month” or “Editing 1 video max 5m length”.`}
                                enterTouchDelay={0}
                                leaveTouchDelay={15000}
                                describeChild
                                arrow
                                placement={!isMobileScreen ? 'top' : 'bottom'}
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      lineHeight: '150%',
                                      borderRadius: '12px',
                                    },
                                  },
                                }}
                              >
                                <IconButton color="default">
                                  <InfoRoundedIcon color="inherit" />
                                </IconButton>
                              </Tooltip>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={2} sm={1} container alignItems="center" height="100%">
                        {index > 0 && (
                          <RemoveDeliverableIconButton
                            aria-label="Delete"
                            color="warning"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <DeleteIcon />
                          </RemoveDeliverableIconButton>
                        )}
                      </Grid>
                    </Grid>
                  ))}

                  {form.values.deliverables !== undefined && form.values.deliverables?.length < 30 && (
                    <AddDeliverableButton
                      variant="text"
                      startIcon={<Add />}
                      aria-label="Add"
                      onClick={() => arrayHelpers.push('')}
                    >
                      Add Deliverable
                    </AddDeliverableButton>
                  )}
                </div>
              )}
            />
            <FormHelperText>{form.touched.deliverables !== undefined ? form.errors.deliverables : ''}</FormHelperText>
          </StyledFormControl>

          <Typography variant="subtitle1">Terms</Typography>
          <Stack gap={2} mt={3}>
            <Grid container spacing={1} alignItems="top">
              <Grid item xs={6}>
                <FormSelect
                  items={DeliveryLookups}
                  label="Delivery"
                  name="delivery"
                  value={form.values.delivery !== null ? form.values.delivery : ''}
                  onChange={form.handleChange}
                  margin="dense"
                  error={form.touched.delivery !== undefined && Boolean(form.errors.delivery)}
                  helpertext={form.touched.delivery !== undefined ? form.errors.delivery : ''}
                />
              </Grid>
              <Grid item xs={6}>
                <FormSelect
                  items={[
                    {
                      id: 0,
                      label: '0',
                    },
                    ...RevisionLookups,
                  ]}
                  label="Revisions"
                  name="revisions"
                  value={form.values.revisions !== null ? form.values.revisions : ''}
                  onChange={form.handleChange}
                  margin="dense"
                  error={form.touched.revisions !== undefined && Boolean(form.errors.revisions)}
                  helpertext={form.touched.revisions !== undefined ? form.errors.revisions : ''}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="top">
              <Grid item xs={6}>
                <StyledTextField
                  type="number"
                  label="Price"
                  name="price"
                  value={form.values.price !== null ? form.values.price : ''}
                  margin="dense"
                  variant={'outlined'}
                  onChange={form.handleChange}
                  error={form.touched.price !== undefined && Boolean(form.errors.price)}
                  helperText={form.touched.price !== undefined ? form.errors.price : ''}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <StyledAdornment position="start">
                        <FormSelect
                          type="number"
                          name="currency"
                          value={form.values.currency !== null ? form.values.currency : ''}
                          margin="dense"
                          variant={'outlined'}
                          onChange={form.handleChange}
                          error={form.touched.currency !== undefined && Boolean(form.errors.currency)}
                          fullWidth
                          items={currencySelectItems}
                          helpertext={form.touched.currency !== undefined ? form.errors.currency : ''}
                        />
                      </StyledAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="name"
                  label="Contract Title"
                  variant={'outlined'}
                  placeholder="Designs for ABC Corp"
                  value={form.values.name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    form.handleChange(e);
                  }}
                  margin="dense"
                  error={form.touched.name !== undefined && Boolean(form.errors.name)}
                  helperText={form.touched.name !== undefined ? form.errors.name : ''}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Stack>
          <>
            <Typography variant="subtitle1" mt={2}>
              Payment Release
            </Typography>
            <Typography variant="body2" mb={1}>
              {form.values.buySell === ContractViewType.Buy
                ? 'You will pay 100% at the start of the contract.  Please choose when payment will be released to the seller (allow 7 days processing time).'
                : 'Clients always pay 100% at the start of the contract. Please choose when payment will be released to you (allow 7 days processing time).'}
            </Typography>
            <Grid container spacing={1} alignItems="top" mt={2} mb={2}>
              <Grid item xs={12}>
                <FormSelect
                  items={ReleaseLookups}
                  label="Release Schedule"
                  name="release"
                  value={form.values.release !== null ? form.values.release : ''}
                  onChange={form.handleChange}
                  margin="dense"
                  error={form.touched.release !== undefined && Boolean(form.errors.release)}
                  helpertext={form.touched.release !== undefined ? form.errors.release : ''}
                />
              </Grid>
            </Grid>
          </>
          {form.values.buySell === ContractViewType.Sell && (
            <ReceivePackagePriceContainer container>
              <Grid container justifyContent="space-between" item xs={12}>
                <Typography variant="subtitle2">Contract Total</Typography>
                <Typography variant="subtitle2">
                  {currencySymbol}
                  {form.values.price ? (form.values.price ?? 0).toFixed(2) : 0}
                </Typography>
              </Grid>

              <Grid container justifyContent="space-between" item xs={12}>
                <Link to="https://stripe.com/pricing" target="_blank">
                  <Typography variant="subtitle2" color={theme.palette.primary.dark}>
                    Stripe Fee (approx 3%)
                  </Typography>
                </Link>

                <Typography variant="subtitle2">
                  {currencySymbol}
                  {((form.values.price ?? 0) * 0.03).toFixed(2)}
                </Typography>
              </Grid>
              <Grid container justifyContent="space-between" item xs={12}>
                <Typography variant="subtitle2" fontWeight={700}>
                  You will receive approx
                </Typography>
                <Typography variant="subtitle2" fontWeight={700}>
                  {currencySymbol}
                  {((form.values.price ?? 0) * 0.97).toFixed(2)}
                </Typography>
              </Grid>
            </ReceivePackagePriceContainer>
          )}
          {form.values.buySell === ContractViewType.Buy && (
            <ReceivePackagePriceContainer container>
              <Grid container justifyContent="space-between" item xs={12}>
                <Typography variant="subtitle2" fontWeight={700}>
                  Contract Total
                </Typography>
                <Typography variant="subtitle2" fontWeight={700}>
                  {currencySymbol}
                  {form.values.price ? (form.values.price ?? 0).toFixed(2) : 0}
                </Typography>
              </Grid>
            </ReceivePackagePriceContainer>
          )}

          <StyledContentContainer container>
            <FormTermsAndConditions
              isAccepted={form.values.termsAccepted}
              onChange={form.handleChange}
              touched={form.touched.termsAccepted}
              error={form.errors.termsAccepted}
              isSeller
            />
          </StyledContentContainer>
        </Grid>
      </StyledHourlyDialogContent>

      <StyledDialogActions>
        <RoundButton fullWidth={isMobileScreen} variant="outlined" onClick={() => handleCancel()}>
          Cancel
        </RoundButton>
        <StyledLoadingButton
          fullWidth={isMobileScreen}
          loading={loading}
          variant="contained"
          onClick={() => form.handleSubmit()}
        >
          Send
        </StyledLoadingButton>
      </StyledDialogActions>
    </>
  );
};

import { Close } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Dialog,
  FormControl,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import IApiError from 'global/interfaces/api';
import { IChatUserThread } from 'global/interfaces/chatThread';
import { IConnection } from 'global/interfaces/connection';
import { ITeamMember } from 'global/interfaces/teamMember';
import { useEffect, useState } from 'react';
import { addRecipient } from 'services/chatThreadService';
import { getActiveConnections } from 'services/connectionService';
import { showError } from 'utils/errorHandler';
import { object, string } from 'yup';
import { mapConnectionToSelectItem, mapConnectionsToSelectItems } from 'utils/connectionUtils';
import {
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
  WrappedTypography,
} from 'components/common/Dialog';
import { RoundButton } from 'components/common/Button/RoundButton';

interface AddMemberDialogProps {
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  selectedThread: IChatUserThread | undefined;
}

export const AddMemberDialog: React.FC<AddMemberDialogProps> = ({ setOpen, open, selectedThread }) => {
  const [isAdding, setIsAdding] = useState(false);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  const form = useFormik<ITeamMember>({
    initialValues: {
      recipientUserId: '',
      chatThreadId: selectedThread?.chatThreadId,
    },
    onSubmit: value => {
      setIsAdding(true);
      addRecipient(value)
        .then(() => {
          close();
        })
        .catch((err: IApiError) => {
          if (err.status === 409) {
            form.setErrors({ recipientUserId: 'This user is already a member of this team' });
            return;
          }
          showError(err);
        })
        .finally(() => setIsAdding(false));
    },
    validationSchema: object({
      recipientUserId: string().required('You must enter a member'),
    }),
  });

  const close = () => {
    setOpen(false);
    form.resetForm();
  };

  useEffect(() => {
    form.setFieldValue('chatThreadId', selectedThread?.chatThreadId);
  }, [selectedThread]);

  const [connections, setConnections] = useState<IConnection[]>();

  useEffect(() => {
    getActiveConnections()
      .then(res => {
        setConnections(res);
      })
      .catch(showError);
  }, []);

  const selectedConnection = connections?.find(x => x.otherUser?.userId == form.values.recipientUserId);
  const selectedItem = selectedConnection ? mapConnectionToSelectItem(selectedConnection) : null;

  const existingUsers = new Map(selectedThread?.recipients.filter(r => r.active).map(r => [r.userId, true]));
  const uniqConnections = connections?.filter(c => c.otherUser?.userId && !existingUsers.get(c.otherUser.userId));

  return (
    <Dialog open={open} onClose={close} fullWidth fullScreen={isMobileScreen}>
      <StyledDialogTitle>
        <WrappedTypography variant="h6" color={theme.palette.grey[900]}>
          Add people to #{selectedThread?.displayName}
        </WrappedTypography>

        <IconButton onClick={close}>
          <Close />
        </IconButton>
      </StyledDialogTitle>

      <form onSubmit={form.handleSubmit}>
        <StyledDialogContent dividers>
          <Box pb={6}>
            <FormControl fullWidth>
              {uniqConnections && uniqConnections.length > 0 && (
                <Autocomplete
                  value={selectedItem}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(_, value) => form.setFieldValue('recipientUserId', value?.id)}
                  options={mapConnectionsToSelectItems(uniqConnections ?? [])}
                  renderInput={params => (
                    <TextField
                      {...params}
                      error={form.touched.recipientUserId && form.errors.recipientUserId !== undefined}
                      helperText={form.touched.recipientUserId && form.errors.recipientUserId}
                      fullWidth
                      label="Enter a connection"
                    />
                  )}
                  loading
                />
              )}
              {connections && connections.length == 0 && (
                <Typography variant="subtitle1">You don&apos;t have any connections!</Typography>
              )}
              {connections && connections.length > 0 && uniqConnections && uniqConnections.length == 0 && (
                <Typography variant="subtitle1">All of the connections are already part of the team!</Typography>
              )}
            </FormControl>
          </Box>
        </StyledDialogContent>

        <StyledDialogActions>
          <RoundButton
            loading={isAdding}
            fullWidth={isMobileScreen}
            disabled={!(uniqConnections && uniqConnections.length > 0)}
            variant="contained"
            type="submit"
          >
            Add
          </RoundButton>
        </StyledDialogActions>
      </form>
    </Dialog>
  );
};

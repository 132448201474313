import { IFrontUserBase } from 'global/interfaces/user';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, Chip, Grid, Skeleton, styled, useMediaQuery, useTheme } from '@mui/material';
import { UserAvatar } from 'components/common/UserAvatar';
import { countries } from 'components/common/Select/CountrySelect';
import { useInView } from 'react-intersection-observer';
import React, { useContext, useEffect, useState } from 'react';
import { UserCarousel } from './UserCarousel';
import { useIntersectionObserver } from 'global/hooks/useIntersection';
import { VerifiedBadgeIcon } from 'components/icon/VerifiedBadgeIcon';
import { isTouchScreen } from 'utils/device';
import { pauseAllMedia } from 'utils/media';
import { useNavigate } from 'react-router-dom';
import { useLocalization } from 'global/hooks/useLocalization';
import { AuthContext } from 'contexts/AuthContext';
import { IFileMetadata } from 'global/interfaces/file';
import { FileType } from 'global/enums/fileTypes';

export const mediaHeight: string = (1024 / 5).toString();

interface StyledCardProps {
  isUserSection: boolean;
}

const StyledCard = styled(Card, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== 'isUserSection',
})<StyledCardProps>(({ theme }) => ({
  cursor: 'pointer',
  backgroundColor: theme.palette.common.white,
  borderRadius: '11px',
  border: `1px solid ${theme.palette.grey[300]}`,
  boxShadow: 'none',
  marginInline: '12px',
  marginBottom: '1px',
  WebkitTapHighlightColor: 'transparent',
  WebkitTouchCallout: 'none',
  WebkitUserSelect: 'none',
  KhtmlUserSelect: 'none',
  MozUserSelect: 'none',
  msUserSelect: 'none',
  userSelect: 'none',
  [theme.breakpoints.down('sm')]: {
    marginInline: 0,
  },
}));

const StyledCardContent = styled(CardContent)(() => ({
  position: 'relative',
  padding: '14px 12px',

  '&:last-child': {
    paddingBottom: '10px',
  },
}));

const StyledAvatar = styled(UserAvatar)(({ theme }) => ({
  width: '56px',
  height: '56px',
  border: `1px solid ${theme.palette.grey[300]}`,
}));

const EllipsisWrapperDiv = styled('div')`
  display: table;
  table-layout: fixed;
  width: 100%;
  white-space: nowrap;
`;

const EllipsisTypography = styled(Typography)(() => ({
  display: 'table-cell',
}));

EllipsisTypography.defaultProps = {
  noWrap: true,
};

const BioTypography = styled(Typography)(({ theme }) => ({
  display: '-webkit-box',
  WebkitLineClamp: '4',
  WebkitBoxOrient: 'vertical',
  marginTop: '16px',
  color: theme.palette.black[500],
  lineHeight: '140%',
  height: '79px',
}));

const SkillsBox = styled(Box, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== 'isUserSection',
})<StyledCardProps>(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  marginTop: '16px',
  marginBottom: '12px',
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.black[200],
  color: theme.palette.black[500],
  borderRadius: '4px',
}));

const HeadSection = styled(Box)(() => ({
  position: 'relative',
  borderRadius: '12px 6px 0 0',
}));

const StyledVerifiedBadge = styled(VerifiedBadgeIcon)(() => ({
  position: 'absolute',
  fontSize: '21px',
  zIndex: 1,
  left: '40px',
  bottom: '-1px',
}));

const StyledAvatarGrid = styled(Box)(() => ({
  position: 'absolute',
  zIndex: 9999,
  left: '12px',
  top: '-5px',
}));

interface IVerticalUserCardProps {
  user: IFrontUserBase;
  onInView?: (id: string) => void;
  muted: boolean;
  setMuted: React.Dispatch<React.SetStateAction<boolean>>;
  isUserSection: boolean;
  isActiveUser: boolean;
  setActiveUser: (userId: string, active: boolean) => void;
}

const VerticalUserCard = React.memo(
  ({ user, onInView, muted, setMuted, isUserSection, isActiveUser, setActiveUser }: IVerticalUserCardProps) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [paused, setPaused] = useState(false);
    const [ref, inView] = useInView({
      triggerOnce: true,
      rootMargin: '0px 0px 0px 0px',
    });
    const country = countries.find(c => c.code === user.country)?.label;
    const { localCurrencySymbol, rateInCurrency } = useLocalization(user.usdHourlyRate);
    const authContext = useContext(AuthContext);

    const renderChip = (value: JSX.Element | string) => (
      <StyledChip label={<Typography variant="caption">{value}</Typography>} size="small" />
    );

    useEffect(() => {
      if (onInView && inView) {
        onInView(user.id);
      }
    }, [inView]);

    const pausePlay = (paused: boolean) => {
      if (!isActiveUser) {
        setActiveUser(user.id, true);
      }
      setPaused(paused);
    };

    const playVideoOnHover = (): void => {
      if (isTouchScreen()) {
        return;
      }

      pauseAllMedia();
      setActiveUser(user.id, true);
    };

    const pauseVideoOnLeave = (): void => {
      if (isTouchScreen()) {
        return;
      }

      pauseAllMedia();
      setActiveUser(user.id, false);
      setPaused(false);
    };

    const mediaIntersectionHandler = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (!isTouchScreen()) {
          return;
        }

        if (entry.isIntersecting && setActiveUser) {
          setActiveUser(user.id, true);
        }
        return;
      });
    };

    const handleOnClick = (userId: string): void => {
      if (isMobile) {
        navigate(`/users/${userId}?backnav=true`);
      } else {
        navigate(`/users/${userId}`);
      }
    };

    const mediaTargetRef = useIntersectionObserver(
      (entries: IntersectionObserverEntry[]) => mediaIntersectionHandler(entries),
      {
        root: null,
        rootMargin: '0px 0px -50px 0px',
        threshold: !isUserSection ? 1 : 0.55,
      },
    );

    const filteredFiles: IFileMetadata[] = user.portfolioFiles
      .filter(
        x =>
          x.isProcessed === true &&
          (x.type === FileType.Image || x.type === FileType.Video || x.type === FileType.Audio),
      )
      .slice(0, 10);

    return (
      <StyledCard
        ref={!paused && isMobile ? mediaTargetRef : null}
        onClick={() => handleOnClick(user.id)}
        onMouseEnter={playVideoOnHover}
        onMouseLeave={pauseVideoOnLeave}
        isUserSection={isUserSection}
      >
        <HeadSection ref={ref}>
          <UserCarousel
            portfolioFiles={user.portfolioFiles}
            muted={muted}
            setMuted={setMuted}
            inView={inView}
            paused={paused}
            pausePlay={pausePlay}
            isActiveUser={isActiveUser}
            profileImage={user.profileImage}
          />
        </HeadSection>
        <StyledCardContent>
          {filteredFiles.length > 1 && (
            <StyledAvatarGrid>
              <StyledAvatar userId={user.id} /> <StyledVerifiedBadge />
            </StyledAvatarGrid>
          )}
          <Grid container alignItems="center">
            <Grid item xs={12} sx={{ marginLeft: filteredFiles.length > 1 ? '67px' : undefined }}>
              <EllipsisWrapperDiv>
                <EllipsisTypography
                  color="black.500"
                  variant="subtitle2"
                  lineHeight="20px"
                  fontWeight={600}
                  textTransform="capitalize"
                  title={user.title}
                >
                  {user.title}
                </EllipsisTypography>
              </EllipsisWrapperDiv>
              <EllipsisWrapperDiv>
                <EllipsisTypography color="grey.500" variant="caption" lineHeight="150%">
                  {user.firstName} {user.lastName}
                </EllipsisTypography>
              </EllipsisWrapperDiv>
            </Grid>
          </Grid>
          <BioTypography textOverflow="ellipsis" variant="body2" overflow="hidden">
            {user.bio}
          </BioTypography>
          <SkillsBox isUserSection={isUserSection}>
            {user.mainCategory && renderChip(country ?? '')}
            {renderChip(
              localCurrencySymbol && authContext.viewerCountry && user?.usdHourlyRate ? (
                `${localCurrencySymbol}${rateInCurrency}/hr`
              ) : (
                <Skeleton variant="text" width={'60px'} />
              ),
            )}
          </SkillsBox>
        </StyledCardContent>
      </StyledCard>
    );
  },
);

VerticalUserCard.displayName = 'VerticalUserCard';

export default VerticalUserCard;

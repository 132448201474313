import * as React from 'react';
import Typography from '@mui/material/Typography';
import { IContractAcceptResult, ContractAcceptResultType } from 'global/interfaces/contract';
import { acceptOrDeclineContract } from 'services/contractService';
import IApiError from 'global/interfaces/api';
import { showError } from 'utils/errorHandler';
import Box from '@mui/material/Box';
import { Dialog, DialogContentText, Grid, styled, useMediaQuery, useTheme } from '@mui/material';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import { FormTermsAndConditions } from 'components/common/form/FormTermsAndConditions';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import { RoundButton } from 'components/common/Button/RoundButton';
import { LoadingButton } from '@mui/lab';
import useProgressBar from 'global/hooks/useProgressBar';

import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import AddPayoutDetails from './AddPayoutDetails';
import { getLowCostMethodsToTurnOn, getPaymentMethods, getPayout } from 'services/payoutService';
import { IPaymentMethods, IStripeConnect } from 'global/interfaces/payout';
import { Link } from 'react-router-dom';

interface IContractAcceptButtonProps {
  contractId: string;
  contractName: string;
  largeWidth?: number;
  onCompletion?: () => void;
  returnUrl: string;
}

const StyledFormTermsAndConditions = styled(FormTermsAndConditions)(() => ({
  marginBottom: '8px',
}));

export const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  padding: '12px 18px',
  borderRadius: theme.spacing(6),
  height: '35px',
}));

export default function ContractAcceptButtons(props: IContractAcceptButtonProps): JSX.Element {
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [result, setResult] = React.useState<IContractAcceptResult | undefined>(undefined);

  const [termsAccepted, setTermsAccepted] = React.useState<boolean>();
  const [isPayoutAdded, setIsPayoutAdded] = React.useState<boolean>(true);
  const [paymentMethods, setPaymentMethods] = React.useState<string[]>([]);

  const [progress, showProgress] = useProgressBar();

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleAcceptClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    event.preventDefault();
    if (termsAccepted) {
      acceptOrDecline(true);
    } else {
      setTermsAccepted(false);
    }
  };

  const handleDeclineClick = (): void => {
    acceptOrDecline(false);
  };

  const acceptOrDecline = (accept: boolean): void => {
    setDialogOpen(false);
    showProgress(true);
    if (accept) {
      getPayout()
        .then((res: IStripeConnect) => {
          const hasPayout = res?.payoutsEnabled && res?.ordersEnabled;
          setIsPayoutAdded(hasPayout);
          if (hasPayout) {
            getPaymentMethods()
              .then((res: IPaymentMethods) => {
                const newMethods = getLowCostMethodsToTurnOn(res);
                setPaymentMethods(newMethods);
                if (newMethods.length > 0) {
                  showProgress(false);
                  setDialogOpen(true);
                } else {
                  executeAcceptOrDecline(true);
                }
              })
              .catch(showError);
          } else {
            showProgress(false);
            setDialogOpen(true);
          }
        })
        .catch(showError);
    } else {
      executeAcceptOrDecline(false);
    }
  };

  const executeAcceptOrDecline = (accept: boolean): void => {
    acceptOrDeclineContract(props.contractId, accept)
      .then((res: IContractAcceptResult) => {
        setResult(res);
        if (res.result === ContractAcceptResultType.Done) {
          showProgress(false);
          if (props.onCompletion) props.onCompletion();
        } else {
          showProgress(false);
          setDialogOpen(true);
        }
      })
      .catch((err: IApiError) => {
        showProgress(false);
        showError(err);
      });
  };

  const handleDialogClose = (): void => {
    setDialogOpen(false);
  };

  return (
    <>
      <Box>
        <StyledFormTermsAndConditions
          isAccepted={termsAccepted ?? false}
          onChange={(_, checked) => setTermsAccepted(checked)}
          touched={termsAccepted != undefined}
          error={!termsAccepted}
          isSeller={false}
        />
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <Grid item xs={6} lg={props.largeWidth ?? 4}>
            <StyledLoadingButton
              variant="contained"
              type="button"
              fullWidth
              size="small"
              onClick={e => handleAcceptClick(e)}
              startIcon={<DoneAllOutlinedIcon />}
            >
              Accept
            </StyledLoadingButton>
          </Grid>

          <Grid item xs={6} lg={props.largeWidth ?? 4}>
            <StyledLoadingButton
              variant={'outlined'}
              type="button"
              size="small"
              fullWidth
              startIcon={<ClearOutlinedIcon />}
              onClick={handleDeclineClick}
            >
              Decline
            </StyledLoadingButton>
          </Grid>
        </Grid>
      </Box>

      <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="xs" fullScreen={isMobileScreen}>
        {!isPayoutAdded && (
          <AddPayoutDetails
            isPayoutAdded={isPayoutAdded}
            handleClose={handleDialogClose}
            returnUrl={props.returnUrl}
            title="Accept Contract"
            message="Please add your payout details via our partner Stripe prior to accepting contracts."
          />
        )}
        {isPayoutAdded && paymentMethods.length > 0 && (
          <>
            <React.Fragment>
              <StyledDialogTitle>
                <Typography variant="h6" color="grey.900">
                  Turn on Low Fee Payment Methods
                </Typography>
              </StyledDialogTitle>
              <StyledDialogContent dividers>
                <DialogContentText>
                  <Typography variant="body1">
                    Prior to accepting the contract we recommend you click{' '}
                    <Link to={process.env.REACT_APP_PAY_MET_URL ?? ''} target="_blank">
                      <Typography variant="subtitle1" color={theme.palette.primary.dark} component={'span'}>
                        here
                      </Typography>
                    </Link>{' '}
                    to enable the following payment methods in your Stripe account so your clients can use them and you
                    can save on fees:{' '}
                    <Typography variant="body2" component={'div'} fontWeight={700} mt={2}>
                      {paymentMethods.join(', ')}
                    </Typography>
                    <Typography variant="body1" component={'div'} mt={2}>
                      Would you like to accept the offer now?
                    </Typography>
                  </Typography>
                </DialogContentText>
              </StyledDialogContent>
              <StyledDialogActions>
                <RoundButton onClick={handleDialogClose} autoFocus variant="outlined">
                  Cancel
                </RoundButton>
                <RoundButton
                  variant="contained"
                  onClick={() => {
                    showProgress(true);
                    setPaymentMethods([]);
                    executeAcceptOrDecline(true);
                  }}
                  autoFocus
                >
                  Accept Offer
                </RoundButton>
              </StyledDialogActions>
            </React.Fragment>
          </>
        )}
        {isPayoutAdded && paymentMethods.length == 0 && (
          <>
            {result?.result === ContractAcceptResultType.AlreadyAccepted && (
              <React.Fragment>
                <StyledDialogTitle>
                  <Typography variant="h6" color="grey.900">
                    Already Accepted
                  </Typography>
                </StyledDialogTitle>
                <StyledDialogContent dividers>
                  <DialogContentText>
                    <Typography variant="body1">{`You have already accepted or declined contract ${props.contractName}`}</Typography>
                  </DialogContentText>
                </StyledDialogContent>
                <StyledDialogActions>
                  <RoundButton onClick={handleDialogClose} autoFocus>
                    OK
                  </RoundButton>
                </StyledDialogActions>
              </React.Fragment>
            )}
            {result?.result === ContractAcceptResultType.Cancelled && (
              <React.Fragment>
                <StyledDialogTitle>
                  <Typography variant="h6" color="grey.900">
                    Cancelled
                  </Typography>
                </StyledDialogTitle>
                <StyledDialogContent dividers>
                  <DialogContentText>
                    <Typography variant="body1">{`Contract ${props.contractName} has been cancelled and no longer requires acceptance.`}</Typography>
                  </DialogContentText>
                </StyledDialogContent>
                <StyledDialogActions>
                  <RoundButton variant="contained" onClick={handleDialogClose} autoFocus>
                    Got It
                  </RoundButton>
                </StyledDialogActions>
              </React.Fragment>
            )}
          </>
        )}
      </Dialog>
      {progress}
    </>
  );
}

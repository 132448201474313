import { Box, Container, Grid, IconButton, Stack, styled, useMediaQuery, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';
import { useContext } from 'react';
import { AccountTypeOptions } from 'global/enums/accountTypeOptions';
import { RoundButton } from 'components/common/Button/RoundButton';
import { CheckMarkCircleIcon } from 'components/icon/CheckMarkCircleIcon';
import YoutubeIcon from '@mui/icons-material/YouTube';
import { TikTokIcon } from 'components/common/StyledIcons/TikTokIcon';
import { InstagramIcon } from 'components/common/StyledIcons/InstagramIcon';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const StyledBox = styled(Box)(({ theme }) => ({
  padding: '20px 24px',
  borderRadius: '24px',
  backgroundColor: theme.palette.grey[50],
  borderWidth: '1px',
  borderStyle: 'solid',

  borderColor: theme.palette.grey[200],
  [theme.breakpoints.down('sm')]: {
    padding: '20px',
  },
}));

const FollowBox = styled(StyledBox)(({ theme }) => ({
  backgroundColor: '#EBF2FE',
  borderColor: '#EBF2FE',
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    alignSelf: 'stretch',
    flexDirection: 'column',
    gap: '4px',
  },
}));

const StyledCheckMarkCircleIcon = styled(CheckMarkCircleIcon)(({ theme }) => ({
  stroke: theme.palette.primary[600],
  strokeWidth: '1.5px',
  width: '48px',
  height: '48px',
  fill: 'none',
}));

const StyledIconButton = styled(IconButton)`
  & .MuiSvgIcon-root {
    fill: ${({ theme }) => theme.palette.grey[700]};
    &:hover {
      opacity: 0.8;
    }
  },
  width: 40px;
  height: 40px;
`;

const StyledYouTubeIcon = styled(YoutubeIcon)`
  width: 30px;
  height: 30px;
`;

const StyledTikTokIcon = styled(TikTokIcon)`
  width: 24px;
  height: 24px;
`;

const StyledInstagramIcon = styled(InstagramIcon)`
  width: 24px;
  height: 24px;
`;

interface MobileProps {
  isMobile: boolean;
}

const NumberTypography = styled(Typography, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== 'isMobile',
})<MobileProps>(({ isMobile }) => ({
  display: 'inline-flex',
  borderRadius: '1000px',
  borderStyle: 'solid',
  borderWidth: '1px',
  marginRight: '8px',
  height: isMobile ? '24px' : ' 32px',
  width: isMobile ? '24px' : ' 32px',
  padding: '12px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledOl = styled('ol')`
  margin-bottom: 0;
  margin-top: 16px;
`;

const FollowLinks = () => (
  <>
    <Stack direction="row" alignItems="center" justifyContent={{ xs: 'flex-start', md: 'flex-end' }}>
      <Link to="https://www.youtube.com/@shoutt_co" target="_blank">
        <StyledIconButton sx={{ marginLeft: '-8px' }}>
          <StyledYouTubeIcon />
        </StyledIconButton>
      </Link>
      <Link to="https://www.tiktok.com/@shoutt_co" target="_blank">
        <StyledIconButton>
          <StyledTikTokIcon />
        </StyledIconButton>
      </Link>
      <Link to="https://www.instagram.com/shoutt_co" target="_blank">
        <StyledIconButton sx={{ marginRight: '-8px' }}>
          <StyledInstagramIcon />
        </StyledIconButton>
      </Link>
    </Stack>
  </>
);

interface IFollowUsProps {
  stepNumber?: number;
}

const FollowUs = (props: IFollowUsProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <FollowBox position="relative" width="100%" justifyContent={!isMobile ? 'space-between' : 'flex-start'}>
        <Stack gap="12px" direction="column">
          <Typography variant={isMobile ? 'subtitle1' : 'h6'}>
            {props.stepNumber !== undefined && (
              <NumberTypography variant={isMobile ? 'subtitle1' : 'h6'} isMobile={isMobile}>
                {props.stepNumber}
              </NumberTypography>
            )}
            Don’t forget to follow us
          </Typography>
          <Typography variant="subtitle2" lineHeight="20px">
            For the latest news and updates
          </Typography>
        </Stack>
        <FollowLinks />
      </FollowBox>
    </>
  );
};

const UserSubmitted = () => {
  const authContext = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const handleTalentClick = (): void => {
    navigate('/');
  };

  return (
    <Container maxWidth="md">
      <Grid
        container
        flexDirection="column"
        alignItems="center"
        minHeight={'calc(100vh - 158px)'}
        pb={9}
        maxWidth="690px"
        marginLeft="auto"
        marginRight="auto"
      >
        <Grid container item xs={12} pt={isMobileScreen ? 5 : 10} columnGap={2.5} rowGap={3.5} justifyContent="left">
          {searchParams.get('accountType') === `${AccountTypeOptions.Seller}` ? (
            <>
              {!isMobileScreen && <StyledCheckMarkCircleIcon />}
              <Typography
                textAlign="left"
                variant={isMobileScreen ? 'h5' : 'h4'}
                component="span"
                sx={{ display: 'inline-flex', alignItems: 'center' }}
              >
                You’re all set! So what’s next?
              </Typography>
              <Typography textAlign="left" variant="body1" color="grey.500">
                Thanks for completing your{' '}
                <Link
                  to={`/users/${authContext.user?.id}`}
                  style={{ color: theme.palette.primary[600], textDecoration: 'none' }}
                >
                  profile
                </Link>
                . We will be in touch within 2 business days. Please add support@shoutt.co to your contact list so you
                don’t miss our emails.
              </Typography>
              <FollowUs />
            </>
          ) : (
            <>
              {!isMobileScreen && <StyledCheckMarkCircleIcon />}
              <Typography
                textAlign="left"
                variant={isMobileScreen ? 'h5' : 'h4'}
                component="span"
                sx={{ display: 'inline-flex', alignItems: 'center' }}
              >
                You’re all set!
              </Typography>
              <Typography textAlign="left" variant="body1" color="grey.500" component="div">
                Thanks for signing up with us! You’re ready to{' '}
                <Link to="/talent" style={{ color: theme.palette.primary[600], textDecoration: 'none' }}>
                  start connecting
                </Link>{' '}
                and collaborating with our network of creative geniuses! Here’s how it works:
                <StyledOl>
                  <li>Browse or search profiles</li>
                  <li>Obtain quotes</li>
                  <li>Chat, pay and approve all from your workroom</li>
                </StyledOl>
              </Typography>
              <RoundButton
                variant="contained"
                color="primary"
                startIcon={<ChevronRightIcon />}
                onClick={handleTalentClick}
              >
                Get Started
              </RoundButton>
              <FollowUs />
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default UserSubmitted;

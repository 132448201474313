import {
  Autocomplete,
  Box,
  Dialog,
  FormControl,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { IChatUserThread } from 'global/interfaces/chatThread';
import { Close } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { getActiveConnections } from 'services/connectionService';
import { IConnection } from 'global/interfaces/connection';
import IApiError from 'global/interfaces/api';
import { showError } from 'utils/errorHandler';
import { mapConnectionsToSelectItems } from 'utils/connectionUtils';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { newOneToOneThread } from 'services/chatThreadService';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import { RoundButton } from 'components/common/Button/RoundButton';

interface StartChatDialogProps {
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  setSelectedThread: React.Dispatch<IChatUserThread>;
}

export const StartChatDialog: React.FC<StartChatDialogProps> = ({ open, setOpen, setSelectedThread }) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [connections, setConnections] = useState<IConnection[]>();

  useEffect(() => {
    getActiveConnections()
      .then(res => {
        setConnections(res);
      })
      .catch(showError);
  }, []);

  const form = useFormik<{ recipientUserId: string }>({
    initialValues: {
      recipientUserId: '',
    },
    onSubmit: values => {
      newOneToOneThread(values.recipientUserId)
        .then(res => {
          setSelectedThread(res);
          form.resetForm();
          setOpen(false);
        })
        .catch((err: IApiError) => {
          if (err.status === 409) {
            form.setErrors({ recipientUserId: 'This user is already a member of this team' });
            return;
          }
          showError(err);
        });
    },
    validationSchema: object({
      recipientUserId: string().required('You must enter a member'),
    }),
  });

  return (
    <Dialog open={open} fullWidth fullScreen={isMobileScreen}>
      <StyledDialogTitle>
        <Typography variant="h6" color={theme.palette.grey[900]}>
          Start Chat
        </Typography>
        <IconButton onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </StyledDialogTitle>

      <form onSubmit={form.handleSubmit}>
        <StyledDialogContent dividers>
          <Box pb={5}>
            <FormControl fullWidth>
              <Autocomplete
                onChange={(e, value) => form.setFieldValue('recipientUserId', value?.id)}
                options={mapConnectionsToSelectItems(connections ?? [])}
                renderInput={params => (
                  <TextField
                    {...params}
                    error={form.touched.recipientUserId && form.errors.recipientUserId !== undefined}
                    helperText={form.touched.recipientUserId && form.errors.recipientUserId}
                    fullWidth
                    label="Enter a connection"
                  />
                )}
                loading
              />
            </FormControl>
          </Box>
        </StyledDialogContent>

        <StyledDialogActions>
          <RoundButton fullWidth={isMobileScreen} variant="contained" type="submit">
            Start Chat
          </RoundButton>
        </StyledDialogActions>
      </form>
    </Dialog>
  );
};

import { AuthContext } from 'contexts/AuthContext';
import { useContext, useEffect, useState } from 'react';
import { StyledCard, StyledSubsciprionTypography, SubHeader } from './basicPlan';
import { CardContent, CardHeader, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import { editCardDetails, getCurrentUserSubscription, stopCancellation } from 'services/subscriptionService';
import { showError } from 'utils/errorHandler';
import { format } from 'date-fns/esm';
import CancelSubscriptionDialog from './cancelSubscriptionDialog';
import { getPlanPricing } from 'utils/currency';
import { UserSubscription } from 'global/interfaces/userSubscription';

export default function ActiveSubscription() {
  const authContext = useContext(AuthContext);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [subscription, setSubscription] = useState<UserSubscription>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const cancellationSet: boolean =
    subscription?.subscriptionCancelAtPeriodEnd === true && authContext.user?.subscriptionActive === true;

  const loadSubscription = () => {
    getCurrentUserSubscription()
      .then(res => setSubscription(res))
      .catch(showError);
  };

  const handleStopCancellation = () => {
    setSubscriptionLoading(true);
    stopCancellation()
      .then(() => {
        authContext.refresh().then(() => {
          loadSubscription();
          setSubscriptionLoading(false);
        });
      })
      .catch(e => {
        showError(e);
        setSubscriptionLoading(false);
      });
  };

  const handleEdit = () => {
    setEditLoading(true);
    editCardDetails()
      .then(res => {
        window.location.href = res;
      })
      .catch(e => {
        setEditLoading(false);
        showError(e);
      });
  };

  useEffect(() => {
    loadSubscription();
  }, []);

  return (
    <StyledCard>
      <CardHeader
        title={<Typography variant="h6">Basic Plan</Typography>}
        subheader={
          <SubHeader
            text="Your Basic Plan allows you to create contracts"
            active={authContext.user?.subscriptionActive ?? false}
            activeText={subscription?.subscriptionCancelAtPeriodEnd ? 'Cancellation Pending' : 'Active'}
          />
        }
      ></CardHeader>
      <CardContent>
        <Grid container alignItems="center">
          <Grid container item xs={12} sm={6} spacing={1} mb={2}>
            <Grid item xs={6}>
              <StyledSubsciprionTypography variant="subtitle2">Billing Cycle</StyledSubsciprionTypography>
            </Grid>
            <Grid item xs={6}>
              <StyledSubsciprionTypography variant="subtitle2">Monthly</StyledSubsciprionTypography>
            </Grid>
            <Grid item xs={6}>
              <StyledSubsciprionTypography variant="subtitle2">Next Billing Date</StyledSubsciprionTypography>
            </Grid>
            <Grid item xs={6}>
              <StyledSubsciprionTypography variant="subtitle2">
                {authContext.user && subscription
                  ? format(new Date(subscription.subscriptionCurrentPeriodEnd), 'MMMM d, yyyy')
                  : 'N/A'}
              </StyledSubsciprionTypography>
            </Grid>
            <Grid item xs={6}>
              <StyledSubsciprionTypography variant="subtitle2">Subscription Started</StyledSubsciprionTypography>
            </Grid>
            <Grid item xs={6}>
              <StyledSubsciprionTypography variant="subtitle2">
                {authContext.user && subscription
                  ? format(new Date(subscription.subscriptionRenewedOn), 'MMMM d, yyyy')
                  : 'N/A'}
              </StyledSubsciprionTypography>
            </Grid>
            <Grid item xs={6}>
              <StyledSubsciprionTypography variant="subtitle2">Card Details</StyledSubsciprionTypography>
            </Grid>
            <Grid item xs={6}>
              <StyledSubsciprionTypography variant="subtitle2">
                **** **** **** {subscription?.cardLast4}
              </StyledSubsciprionTypography>
            </Grid>
            <Grid item xs={6}>
              <StyledSubsciprionTypography variant="subtitle2">Amount</StyledSubsciprionTypography>
            </Grid>
            <Grid item xs={6}>
              <StyledSubsciprionTypography variant="subtitle2">
                {getPlanPricing(authContext.viewerCountry ?? '')}
              </StyledSubsciprionTypography>
            </Grid>
          </Grid>
          <Grid container justifyContent={!isMobile ? 'right' : 'left'} xs={12} sm={6} gap={1}>
            {!cancellationSet && (
              <RoundButton
                color="primary"
                variant="outlined"
                onClick={() => handleEdit()}
                loading={editLoading}
                disabled={subscriptionLoading}
              >
                Edit Payment Details
              </RoundButton>
            )}
            {cancellationSet ? (
              <RoundButton
                variant="outlined"
                onClick={() => handleStopCancellation()}
                loading={subscriptionLoading}
                disabled={editLoading}
              >
                Continue Subscription
              </RoundButton>
            ) : (
              <RoundButton
                variant="outlined"
                color="error"
                onClick={() => setOpen(true)}
                loading={subscriptionLoading}
                disabled={editLoading}
              >
                Cancel
              </RoundButton>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <CancelSubscriptionDialog
        open={open}
        handleClose={() => setOpen(false)}
        subscriptionPeriodEnd={subscription?.subscriptionCurrentPeriodEnd}
        loadSubscription={loadSubscription}
      />
    </StyledCard>
  );
}

import React, { useContext, useState } from 'react';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from '../Dialog';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { RoundButton } from '../Button/RoundButton';
import { StyledLoadingButton } from 'components/payouts/payoutHandler';
import { createProductCheckout } from 'services/subscriptionService';
import { showError } from 'utils/errorHandler';
import { AuthContext } from 'contexts/AuthContext';
import { getPlanPricing } from 'utils/currency';

export default function SubscriptionDialog({
  returnUrl,
  onClose,
}: {
  returnUrl: string | undefined;
  onClose: () => void;
}) {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const authContext = useContext(AuthContext);

  const createSubscriptionSession = () => {
    setLoading(true);
    createProductCheckout(`${returnUrl}?create-contract`)
      .then(res => {
        window.location.href = res;
      })
      .catch(e => {
        setLoading(false);
        showError(e);
      });
  };

  return (
    <React.Fragment>
      <StyledDialogTitle>
        <Stack direction="row">
          <Typography variant="h6" color={theme.palette.grey[900]}>
            Upgrade Subscription
          </Typography>
        </Stack>
      </StyledDialogTitle>
      <StyledDialogContent dividers>
        <Typography variant="body2">Upgrade to a basic subscription to start creating contracts.</Typography>
        <Typography variant="body2">
          A Shoutt subscription costs {getPlanPricing(authContext.viewerCountry ?? '')} with no long term contract and a
          30 day money back guarantee. We don’t charge any commission on transactions. A small Stripe payment processing
          fee of 1-3% applies, which is automatically deducted from payouts only.
        </Typography>
      </StyledDialogContent>
      <StyledDialogActions>
        <RoundButton fullWidth={isMobileScreen} variant="outlined" onClick={() => onClose()}>
          Cancel
        </RoundButton>
        <StyledLoadingButton
          fullWidth={isMobileScreen}
          loading={loading}
          variant="contained"
          onClick={() => createSubscriptionSession()}
        >
          Upgrade Now
        </StyledLoadingButton>
      </StyledDialogActions>
    </React.Fragment>
  );
}

import { DashboardButton } from './DashboardButton';

import ActionBox from './ActionBox';
import { useNavigate } from 'react-router-dom';

export default function ActionFindTalent() {
  const navigate = useNavigate();
  return (
    <ActionBox
      title="Post a job"
      body={'Leave the hard work to us! Submit your brief and we’ll source 3 vetted proposals that match your brief.'}
    >
      <DashboardButton variant="contained" onClick={() => navigate(`/concierge`)}>
        Get 3 proposals
      </DashboardButton>
    </ActionBox>
  );
}

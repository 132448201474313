import { Box, Grid, Typography } from '@mui/material';

export const ConciergeConfirmation: React.FC = () => (
  <Grid container item xs={12} justifyContent="center">
    <Box maxWidth={600}>
      <Grid container item xs={11} pt="72px" gap={2} spacing={3} justifyContent="center">
        <Grid xs={12} item>
          <Typography textAlign="center" variant="h4">
            Request Received
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Typography textAlign="center" variant="body1">
            We will be in touch within 24 hours Monday - Friday.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  </Grid>
);

import { AuthContext } from 'contexts/AuthContext';
import { useContext, useState } from 'react';
import { createProductCheckout } from 'services/subscriptionService';
import { showError } from 'utils/errorHandler';
import { CardContent, CardHeader, Grid, styled, Typography } from '@mui/material';
import { StyledCard, StyledSubsciprionTypography, SubHeader } from './basicPlan';
import { RoundButton } from 'components/common/Button/RoundButton';
import IApiError from 'global/interfaces/api';
import { getPlanPricing } from 'utils/currency';

const StyledButtonGrid = styled(Grid)(({ theme }) => ({
  justifyContent: 'right',

  [theme.breakpoints.down('sm')]: {
    justifyContent: 'left',
  },
}));

export default function InactiveSubscription() {
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const handleCheckout = () => {
    setLoading(true);
    createProductCheckout()
      .then((res: string) => {
        window.location.href = res;
        setLoading(false);
      })
      .catch((e: IApiError) => {
        showError(e);
        setLoading(false);
      });
  };

  return (
    <StyledCard>
      <CardHeader
        title={<Typography variant="h6">Basic Plan</Typography>}
        subheader={<SubHeader text="Upgrade to the Basic Plan to start creating contracts" active={false} />}
      ></CardHeader>
      <CardContent>
        <Grid container alignItems="center" justifyContent="space-between" gap={2}>
          <Grid container item xs={12} sm={5} spacing={1}>
            <Grid item xs={6}>
              <StyledSubsciprionTypography variant="subtitle2">Billing Cycle</StyledSubsciprionTypography>
            </Grid>
            <Grid item xs={6}>
              <StyledSubsciprionTypography variant="subtitle2">Monthly</StyledSubsciprionTypography>
            </Grid>
            <Grid item xs={6}>
              <StyledSubsciprionTypography variant="subtitle2">Amount</StyledSubsciprionTypography>
            </Grid>
            <Grid item xs={6}>
              <StyledSubsciprionTypography variant="subtitle2">
                {getPlanPricing(authContext.viewerCountry ?? '')}
              </StyledSubsciprionTypography>
            </Grid>
          </Grid>
          <StyledButtonGrid item container xs={12} sm={6}>
            <RoundButton variant="outlined" onClick={() => handleCheckout()} loading={loading}>
              Upgrade Now
            </RoundButton>
          </StyledButtonGrid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
}

import { Divider, Stack, Typography, styled, useTheme } from '@mui/material';
import serviceFeeLogo from '../../../../assets/images/shouttServiceFee.webp';
import { JustifiedBox } from 'components/common/StyledWorkroom/WorkroomStyles';
import { Link } from 'react-router-dom';
import { countries, fallbackContractCountry } from 'components/common/Select/CountrySelect';

interface IShouttServiceFeeProps {
  hourlyRate: number;
  countryCode: string;
}

const StackContainer = styled(Stack)(({ theme }) => ({
  width: '100%',
  padding: '24px',
  backgroundColor: `${theme.palette.blue[200]}40`,
  border: '1px solid',
  borderRadius: '4px',
  borderColor: theme.palette.grey[100],
  flexDirection: 'row',
  gap: '24px',
  marginTop: '24px',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const StyledDivider = styled(Divider)(() => ({
  marginBlock: '8px',
}));

export default function ShouttServiceFee({ hourlyRate, countryCode }: IShouttServiceFeeProps) {
  const stripeCommission = (hourlyRate * 3) / 100;
  const theme = useTheme();
  const country = countries.find(c => c.code === countryCode);
  const localCurrencySymbol = country?.currencySymbol ?? fallbackContractCountry.currencySymbol;

  return (
    <StackContainer>
      <img src={serviceFeeLogo} alt="Service Fee" height="120px" width="107px" loading="lazy" />

      <Stack gap={2} width="100%">
        <Typography variant="subtitle1">Shoutt is 100% Commission Free</Typography>

        <Stack>
          <JustifiedBox>
            <Typography variant="subtitle2">Hourly rate</Typography>
            <Typography variant="subtitle2">
              {hourlyRate ? `${localCurrencySymbol}${hourlyRate.toFixed(2)}` : '-'}
            </Typography>
          </JustifiedBox>

          <StyledDivider />

          <JustifiedBox>
            <Link to="https://stripe.com/pricing" target="_blank">
              <Typography variant="subtitle2" color={theme.palette.primary.dark}>
                Stripe Fees (approx 3%)
              </Typography>
            </Link>
            <Typography variant="subtitle2">
              {hourlyRate ? `-${localCurrencySymbol}${stripeCommission.toFixed(2)}` : '-'}
            </Typography>
          </JustifiedBox>

          <StyledDivider />

          <JustifiedBox>
            <Typography variant="subtitle2">You’ll receive</Typography>
            <Typography variant="subtitle2">
              {hourlyRate ? `${localCurrencySymbol}${(hourlyRate - stripeCommission).toFixed(2)}` : '-'}
            </Typography>
          </JustifiedBox>
        </Stack>
      </Stack>
    </StackContainer>
  );
}

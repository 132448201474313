import { Container, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

const Unsubscribe = () => (
  <Container maxWidth="lg">
    <Grid container flexDirection="column" alignItems="center" minHeight={'calc(100vh - 158px)'} pb={9}>
      <>
        <Grid item xs={12} md={6} pt={5}>
          <Typography textAlign="center" variant="h4">
            Unsubscribe
          </Typography>
          <Typography textAlign="center" variant="body1" pt={2}>
            Shoutt only sends emails to users that have expressly signed up with us and created an account. These are a
            mandatory part of the experience. The only way to unsubscribe from these emails is to delete your account.
            You can do this by clicking on your profile icon in the top right corner and selecting Delete Profile.
          </Typography>

          <Typography textAlign="center" variant="body1" pt={2}>
            Please note that as part of the terms & conditions that you accepted upon account creation, you will need to
            conclude any in-progress contracts no matter if you&apos;re a buyer or seller, before you can delete your
            account.
          </Typography>

          <Typography textAlign="center" variant="body1" pt={2}>
            We hope you decide to stay with us and thank you for your participation in the Shoutt community.
          </Typography>
        </Grid>
      </>
    </Grid>
  </Container>
);

export default Unsubscribe;

import {
  Box,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  styled,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Formik, FormikProps, setNestedObjectValues } from 'formik';
import { IEditableUser, IUser } from 'global/interfaces/user';
import { editUserValidation } from 'global/validations/user';
import { showError } from 'utils/errorHandler';
import useProgressBar from 'global/hooks/useProgressBar';
import IApiError from 'global/interfaces/api';
import { EditUserProfileForm } from './EditUserProfileForm';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { PillStyledTab, PillStyledTabs } from 'components/common/StyledTabs/PillTabs';
import { useCallback, useContext, useState } from 'react';
import { UserProfileTabsValue } from 'global/enums/userProfileEnum';
import { AccountTypeOptions } from 'global/enums/accountTypeOptions';
import { setInitialValues } from 'utils/editUserHelper';
import { upsertUser } from 'services/userService';
import { AuthContext } from 'contexts/AuthContext';
import { VettingStatus } from 'global/enums/vettingStatus';
import { nameof } from 'ts-simple-nameof';
import { sectionToTabs } from './TabFieldMapping';
import { pushToDataLayer } from 'utils/tagHelper';
import { adminParam, buyerSubmitProfileEvent, sellerSubmitProfileEvent } from 'global/constants';
import { UserRole } from 'global/enums/userRole';

interface IEditUserProfileProps {
  user: IUser;
  isApplyPhase: boolean;
}

export const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: '72px',

  [theme.breakpoints.down('sm')]: {
    marginTop: '32px',
  },
}));

export const EditUserProfile = ({ user, isApplyPhase }: IEditUserProfileProps) => {
  const [tabValue, setTabValue] = useState<UserProfileTabsValue>(UserProfileTabsValue.Profile);
  const [progress, showProgress, successRedirect] = useProgressBar();
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const authContext = useContext(AuthContext);
  const isAdminEdit =
    authContext.user &&
    authContext.user.roles.indexOf(UserRole.Administrator) > -1 &&
    searchParams.has(adminParam) &&
    user.id !== authContext.user.id;

  const onSubmit = (values: IEditableUser) => {
    if (values.isSubmittingForVetting && !showConfirmDialog) {
      setShowConfirmDialog(true);
      return;
    }

    setShowConfirmDialog(false);
    showProgress();
    upsertUser(values, isAdminEdit ? id : undefined)
      .then(() => {
        const returnUserId = searchParams.get('returnUserId');

        authContext.refresh().then(() => {
          if (!values.isSellingServices) {
            pushToDataLayer(buyerSubmitProfileEvent, {
              transaction_id: user.id,
            });
          }

          if (searchParams.has(adminParam)) {
            successRedirect(`/admin/users/${user.id}?${adminParam}`);
            return;
          }
          if (values.isSubmittingForVetting) {
            pushToDataLayer(sellerSubmitProfileEvent, {
              transaction_id: authContext.user?.id,
            });
            successRedirect(`/users/${user.id}/submitted?accountType=${AccountTypeOptions.Seller}`);
            return;
          }
          if (returnUserId !== null) {
            successRedirect(`/users/${returnUserId}`);
            return;
          } else {
            successRedirect(`/users/${user.id}`);
            return;
          }
        });
      })
      .catch((err: IApiError) => {
        showProgress(false);
        showError(err);
      });
  };

  const onCancel = () => {
    navigate(`/users/${user.id}`);
  };

  let initialValues: IEditableUser = {
    ...user,
    isUploading: false,
  };
  initialValues = setInitialValues(initialValues);

  const validateFields = async (form: FormikProps<IEditableUser>, tabToValidate: number): Promise<boolean> => {
    const formErrors = await form.validateForm();

    const tabToValidateEnum = tabToValidate as UserProfileTabsValue;
    const isErrors = Object.keys(form.errors).some(field => sectionToTabs[tabToValidateEnum].includes(field));
    if (isErrors) {
      sectionToTabs[tabToValidateEnum].forEach(field => {
        form.setFieldTouched(field, true);
        if (field === nameof<IUser>(x => x.fixedPackages)) {
          form.setTouched(setNestedObjectValues(formErrors, true));
        }
      });

      return false;
    }

    return true;
  };

  const scrollToFirstError = () => {
    const el = document.querySelector('.Mui-error, [data-error]');
    (el?.parentElement ?? el)?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  const validateOnTabChange = async (start: number, form: FormikProps<IEditableUser>, value: UserProfileTabsValue) => {
    for (let i = start + 1; i < value; i++) {
      const isValid = await validateFields(form, i);

      if (!isValid) {
        setTabValue(i);
        scrollToFirstError();

        return;
      }
    }

    setTabValue(value);
  };

  const handleTabChange = async (value: number, form: FormikProps<IEditableUser>) => {
    const draftSaveAvailable: boolean =
      form.values.isSellingServices &&
      (form.values.vetting.status === VettingStatus.NotSubmitted ||
        form.values.vetting.status === VettingStatus.UpdatesRequired);
    // If select previous tab item or draft save available
    if (value < tabValue || draftSaveAvailable) {
      setTabValue(value);
      window.scrollTo(0, 0);
      return;
    }

    const isValid = await validateFields(form, tabValue);
    if (isValid) {
      // If select the adjacent next tab item
      if (tabValue + 1 === value) {
        setTabValue(value);
      } else if (value > tabValue + 1) {
        // If select the next tab item from the adjacent tab
        await validateOnTabChange(tabValue, form, value);
      }
    } else {
      scrollToFirstError();
    }
  };

  const handleBackButtonClick = () => {
    if (tabValue !== UserProfileTabsValue.Profile) {
      setTabValue(tabValue - 1);
    }
  };

  const handleSellStatusChange = (form: FormikProps<IEditableUser>) => {
    form.setFieldValue('isSellingServices', !form.values.isSellingServices);
    setTabValue(UserProfileTabsValue.Profile);
  };

  const renderSellerToggleButton = useCallback(
    (form: FormikProps<IEditableUser>) => (
      <Stack direction="row" alignItems="center" gap={2}>
        <Typography variant={'subtitle1'}>Sell Services?</Typography>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={form.values.isSellingServices} sx={{ width: '49px', paddingRight: 0 }} />}
            label={undefined}
            onChange={() => handleSellStatusChange(form)}
            sx={{ mr: 0 }}
          />
        </FormGroup>
      </Stack>
    ),
    [],
  );

  return (
    <>
      {user !== null && (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={editUserValidation}
          validateOnChange={false}
        >
          {(form: FormikProps<IEditableUser>) => (
            <>
              <StyledContainer maxWidth="md">
                <Grid container>
                  <Grid item xs={12}>
                    <Box width="100%" mb={10}>
                      <Stack gap={1}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          {!isApplyPhase ? (
                            <Typography variant={isMobileScreen ? 'h5' : 'h4'}>Your Profile</Typography>
                          ) : (
                            <Typography variant={isMobileScreen ? 'h6' : 'h4'}>
                              Get listed on Shoutt’s marketplace
                            </Typography>
                          )}

                          {!isApplyPhase && !isMobileScreen && renderSellerToggleButton(form)}
                        </Stack>
                        {isApplyPhase && (
                          <Typography variant={isMobileScreen ? 'body2' : 'body1'} mb={1}>
                            <>
                              Please review our{' '}
                              <Link
                                to={process.env.REACT_APP_MODERATION_URL ?? ''}
                                target="_blank"
                                style={{
                                  color: theme.palette.primary[600],
                                }}
                              >
                                moderation policy
                              </Link>{' '}
                              first. Note, you can still use our contract, payment and workspace tools without being on
                              the marketplace.
                            </>
                          </Typography>
                        )}
                      </Stack>

                      {!isApplyPhase && isMobileScreen && renderSellerToggleButton(form)}

                      <Box my={isMobileScreen ? 2 : 4.5} maxWidth={isMobileScreen ? 'fit-content' : undefined}>
                        <PillStyledTabs
                          variant={'scrollable'}
                          scrollButtons={isMobileScreen && form.values.isSellingServices}
                          allowScrollButtonsMobile={isMobileScreen && form.values.isSellingServices}
                          value={tabValue}
                          onChange={(e, newValue) => {
                            handleTabChange(newValue, form);
                          }}
                          sx={{ width: '100%' }}
                        >
                          <PillStyledTab
                            label={<Typography variant="body1">Profile</Typography>}
                            value={UserProfileTabsValue.Profile}
                          />
                          {form.values.isSellingServices && (
                            <PillStyledTab
                              label={<Typography variant="body1">Pricing</Typography>}
                              value={UserProfileTabsValue.SellService}
                            />
                          )}
                          {form.values.isSellingServices && (
                            <PillStyledTab
                              label={<Typography variant="body1">{'Portfolio'}</Typography>}
                              value={UserProfileTabsValue.Portfolio}
                            />
                          )}

                          {form.values.isSellingServices && (
                            <PillStyledTab
                              label={<Typography variant="body1">Experience</Typography>}
                              value={UserProfileTabsValue.WorkHistory}
                            />
                          )}
                        </PillStyledTabs>
                      </Box>

                      <EditUserProfileForm
                        form={form}
                        activeTab={tabValue}
                        onCancel={onCancel}
                        onBack={handleBackButtonClick}
                        onNext={() => handleTabChange(tabValue + 1, form)}
                        scrollToFirstError={scrollToFirstError}
                        isApplyPhase={isApplyPhase}
                        navigateToTab={(tab: UserProfileTabsValue) => {
                          setTabValue(tab);
                        }}
                        showConfirmDialog={showConfirmDialog}
                        setShowConfirmDialog={setShowConfirmDialog}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </StyledContainer>
            </>
          )}
        </Formik>
      )}

      {progress}
    </>
  );
};

import { Container, Grid, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { ViewNetworkTable } from 'components/network/viewNetwork';
import { IConnection } from 'global/interfaces/connection';
import { useEffect, useState } from 'react';
import { getActiveConnections } from 'services/connectionService';
import { showError } from 'utils/errorHandler';
import { useTitle } from 'utils/router';

const TitleGrid = styled(Grid)(() => ({
  marginTop: '36px',
  marginBottom: '20px',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
}));

const ViewNetworkConnections: React.FC = () => {
  const [connections, setConnections] = useState<IConnection[]>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  useTitle('Network');

  useEffect(() => {
    getActiveConnections()
      .then(res => {
        setConnections(res);
        setRefresh(false);
      })
      .catch(showError);
  }, [setConnections, setRefresh, refresh]);

  return (
    <Container>
      <Grid container margin="auto" maxWidth="lg">
        <Grid container item xs={12}>
          <TitleGrid container item xs={12}>
            <Typography variant={isMobileScreen ? 'h5' : 'h4'}>Connections</Typography>
          </TitleGrid>

          <Grid item xs={12} sx={{ mb: 6 }}>
            {connections && connections.length > 0 ? (
              <ViewNetworkTable connections={connections} setRefresh={setRefresh} />
            ) : (
              <Typography variant="body1" color={theme.palette.grey[500]}>
                You don&apos;t have any connections yet.
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ViewNetworkConnections;

import { Box, Typography } from '@mui/material';
import { countriesWithCurrency } from 'components/common/Select/CountrySelect';
import { IChatMessageDisplay } from 'global/interfaces/chatMessage';
import { ContractType } from 'global/interfaces/contract';
import React from 'react';

export default function ChatMessageContractRefunded(props: IChatMessageDisplay): JSX.Element {
  const currencySymbol: string =
    countriesWithCurrency.find(c => c.currency === props.contract?.currency.toUpperCase())?.currencySymbol ?? '';

  return (
    <React.Fragment>
      <Box>
        <Typography variant="body2" color="grey.900">
          A refund of {currencySymbol}
          {props.contract?.refundAmount !== undefined ? -props.contract.refundAmount : ''} has been sent for{' '}
          {props.contract?.contractType == ContractType.Retainer && 'retainer '}
          {props.contract?.contractType == ContractType.FixedPrice && 'fixed price '} contract &quot;
          {props.contract?.name}
          &quot;. The total refunded for this{' '}
          {props.contract?.contractType == ContractType.Retainer ? ' period ' : ' contract'} is {currencySymbol}
          {props.contract?.refundTotal !== undefined ? props.contract.refundTotal : ''}.
        </Typography>
      </Box>
    </React.Fragment>
  );
}

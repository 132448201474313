import { SvgIcon, SvgIconProps } from '@mui/material';

export const BuyIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M13.3336 7.5V5C13.3336 3.15905 11.8412 1.66667 10.0003 1.66667C8.15932 1.66667 6.66693 3.15905 6.66693 5V7.5M2.9936 8.62664L2.4936 13.96C2.35144 15.4764 2.28036 16.2346 2.53198 16.8202C2.75302 17.3347 3.14037 17.7601 3.63195 18.0282C4.19154 18.3333 4.95307 18.3333 6.47614 18.3333H13.5244C15.0475 18.3333 15.809 18.3333 16.3686 18.0282C16.8602 17.7601 17.2475 17.3347 17.4686 16.8202C17.7202 16.2346 17.6491 15.4764 17.5069 13.96L17.0069 8.62664C16.8869 7.34612 16.8269 6.70587 16.5389 6.2218C16.2853 5.79549 15.9105 5.45426 15.4624 5.24154C14.9536 5 14.3105 5 13.0244 5L6.97614 5C5.69001 5 5.04694 5 4.53812 5.24154C4.08999 5.45426 3.71527 5.79549 3.46165 6.2218C3.17368 6.70586 3.11365 7.34612 2.9936 8.62664Z"
      stroke={props.style?.color ?? 'white'}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

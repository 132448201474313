import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { DetailContainer, CenteredBox } from 'components/terms/Containers';
import Template from 'components/terms/Template';
import { useTitle } from 'utils/router';

interface MobileProps {
  isMobile: boolean;
  isTablet: boolean;
}

const BannerContent = ({ isMobile, isTablet }: MobileProps): JSX.Element => (
  <CenteredBox>
    <Typography variant={isTablet ? (isMobile ? 'h5' : 'h3') : 'h2'}>Terms and Conditions</Typography>
  </CenteredBox>
);

export default function BuyerTerms(): JSX.Element {
  useTitle('Terms and Conditions');
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isTabletScreen = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <>
      <Template bannerContent={<BannerContent isMobile={isMobileScreen} isTablet={isTabletScreen} />}>
        <DetailContainer>
          <Typography variant={isMobileScreen ? 'h6' : 'h5'}>1. Definitions</Typography>
          <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
            1.1 Shoutt: Shoutt International Ltd, the operator and provider of the Shoutt service, with its principal
            place of business at 86-90 Paul Street, London, EC2A 4NE, UK, and reachable at email address
            support@shoutt.co.
            <br />
            <br />
            1.2 Platform: The global freelancer services marketplace website and app operated by Shoutt, enabling Buyers
            and Sellers to connect, transact, and communicate regarding digital Work, such as social media management,
            video editing, web development and more. Shoutt serves as an intermediary facilitating introductions,
            contract creation, payments, and communication between Buyers and Sellers.
            <br />
            <br />
            1.3 Work: Refers to the specific services, tasks, or projects by Sellers on the Shoutt Platform. Work
            includes, but is not limited to, social media management, video editing, web development, marketing, and
            other digital-related services.
            <br />
            <br />
            1.4 Contract Agreements: Agreements entered into between Buyers and Sellers on the Shoutt Platform,
            outlining the terms, conditions, scope, and compensation for the performance of Work. These agreements
            specify the obligations of both parties and serve as legally binding contracts.
            <br />
            <br />
            1.5 Buyers: Individuals or entities who utilize the Shoutt Platform to search for, negotiate with, and
            engage Sellers for the provision of Work. Buyers are the clients or customers seeking content-related
            services.
            <br />
            <br />
            1.6 Sellers: Individuals or entities who offer their services, skills, and expertise on the Shoutt Platform
            to provide Work to Buyers. Sellers are the service providers or freelancers offering services for
            compensation.
          </Typography>

          <Typography variant={isMobileScreen ? 'h6' : 'h5'}>2. Platform Services</Typography>
          <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
            2.1 Shoutt provides access to the Platform for viewing Seller profiles, obtaining quotes, posting work
            briefs, and facilitating communication with Sellers, leading to the creation of Work for Hire Agreements and
            the transmission of payments from Buyers to Sellers under Contract Agreements.
          </Typography>
          <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
            2.2 This product includes GeoLite2 data created by MaxMind, available from &nbsp;
            <a href="https://www.maxmind.com">https://www.maxmind.com</a>.
          </Typography>

          <Typography variant={isMobileScreen ? 'h6' : 'h5'}>3. User Account</Typography>
          <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
            3.1 Buyers must sign up for a user account on the Platform and maintain the security of their username and
            password. Unauthorised collection of personal data from other users is prohibited. Shoutt retains the right
            to disable user accounts at any time.
          </Typography>

          <Typography variant={isMobileScreen ? 'h6' : 'h5'}>4. Payments</Typography>
          <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
            4.1 Platform Fees and Transaction Model: Shoutt does not charge any commission or take rate on transactions
            facilitated between buyers and sellers on the platform. Instead, a fixed monthly platform fee is applied,
            based on the level of feature use required by the user. This monthly fee is non-refundable once charged.
            Please note that while Shoutt does not impose additional fees, all transactions are subject to a third-party
            payment processing fee of 1-3%, depending on the payment method used.
            <br />
            <br />
            4.2 Payments for services agreed between buyers and sellers must be made upfront to Shoutt, which holds
            these funds in escrow. Shoutt will release funds to sellers only after the agreed Work is completed to the
            satisfaction and approval of the buyer, ensuring a secure and trustable transaction process. Contracts
            formed via the platform are legally binding and enforceable.
            <br />
            <br />
            4.3 Handling of Disputes and Payment Holds: Shoutt reserves the right to place holds on escrow payments in
            cases of suspected breaches, such as fraud or money laundering. Shoutt does not act as a trustee for these
            payments. Any disputes regarding the services provided should be resolved directly between the buyer and
            seller, though Shoutt will provide assistance and mediation as needed. If a freelancer disappears or does
            not fulfil the terms of their contract, we can return buyer funds. Please note that we are unable to make
            subjective decisions based on the quality of work.
          </Typography>

          <Typography variant={isMobileScreen ? 'h6' : 'h5'}>5. Agreements</Typography>
          <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
            5.1 Buyers can view Seller profiles on the Platform and negotiate specific terms, including Work details and
            payment terms. Shoutt facilitates connections but does not endorse or represent Sellers. Responsibility for
            agreements lies solely between Buyer and Seller. Contracts can be terminated at any time, but escrowed funds
            may not be refunded.
          </Typography>

          <Typography variant={isMobileScreen ? 'h6' : 'h5'}>6. Third Party Websites</Typography>
          <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
            6.1 The Platform may contain links to third-party websites. Shoutt does not control the content of these
            websites, and accessing them is at your own risk.
          </Typography>

          <Typography variant={isMobileScreen ? 'h6' : 'h5'}>7. No Exclusivity</Typography>
          <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
            7.1 Shoutt does not charge commissions on transactions, nor do we restrict buyers and sellers from engaging
            directly outside the platform after an initial connection is made through Shoutt. While we provide a secure
            and feature-rich platform designed to support your transactions, we understand and respect the decision to
            conduct business directly. Therefore, there is no exclusivity period required, and users are free to manage
            their business relationships as they see fit without the obligation to report or process payments through
            Shoutt after making a connection on our platform.
          </Typography>

          <Typography variant={isMobileScreen ? 'h6' : 'h5'}>8. Reviews</Typography>
          <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
            8.1 Reviews posted on the Platform become Shoutt&apos;s property upon submission and may be used for
            advertising purposes across various media.
          </Typography>

          <Typography variant={isMobileScreen ? 'h6' : 'h5'}>9. Content Usage and Ownership Rights</Typography>
          <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
            9.1 Buyers are automatically granted full worldwide ownership rights over any Content or Work produced by
            Sellers under a Contract Agreement on the Platform only once full payment has been made.
            <br />
            <br />
            9.2 Buyers must upload only content (text, images, videos, logos, documents) for which they hold usage or
            ownership rights. Such rights are not transferred to Sellers, who receive access solely for fulfilling
            Contract Agreements.
          </Typography>

          <Typography variant={isMobileScreen ? 'h6' : 'h5'}>10. Relationship and Taxation</Typography>
          <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
            10.1 Shoutt facilitates the meeting of and collaboration between Buyers and Sellers, but neither Buyers nor
            Sellers are hired by Shoutt. All agreements are strictly between Buyers and Sellers. Consequently, you
            accept that Shoutt does not endorse any Buyer or speak for them. Shoutt&apos;s sole responsibility between
            parties is the transmission of payments between them. Sellers are not classified as contractors, employees,
            or agents of Shoutt.
            <br />
            <br />
            10.2 Sellers are responsible for paying their own income taxes in their own country of residence for any
            income derived from the use of the Platform. Shoutt does not withhold or remit taxes on behalf of Sellers.
          </Typography>

          <Typography variant={isMobileScreen ? 'h6' : 'h5'}>11. Disclaimer and Limitation of Liability</Typography>
          <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
            11.1 Use of the Platform is at your own risk. Shoutt is not liable for any device infection resulting from
            downloaded content. While we strive for 24/7 operation, maintenance may require temporary shutdowns, which
            will be communicated in advance. Shoutt is indemnified from any issues arising from platform use or Seller
            performance. Shoutt is not responsible for adverse comments or reviews from third parties regarding procured
            content.
          </Typography>

          <Typography variant={isMobileScreen ? 'h6' : 'h5'}>12. Unlawful and Prohibited Use</Typography>
          <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
            12.1 Use of the site is restricted to the outlined purpose in these terms and conditions, specifically for
            creating legitimate contract agreements with Sellers. Any other use is strictly prohibited.
          </Typography>

          <Typography variant={isMobileScreen ? 'h6' : 'h5'}>13. Term and Termination</Typography>
          <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
            13.1 Compliance with ongoing terms is required, and Shoutt may terminate or suspend Platform access for
            breaches. Escrowed or paid-out funds will not be refunded. Account cancellation can be initiated by sending
            an email to support@shoutt.co, but the terms and conditions, including payment and cancellation provisions,
            remain binding.
          </Typography>
          <Typography variant={isMobileScreen ? 'h6' : 'h5'}>14. Acceptance and Jurisdiction</Typography>
          <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
            14.1 Use of the Platform constitutes full acceptance of these terms. These terms are governed by United
            Kingdom laws, but disputes can be heard in any jurisdiction.
          </Typography>
        </DetailContainer>
      </Template>
    </>
  );
}

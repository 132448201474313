import marketplace from 'assets/images/lps/marketplacenew.webp';
import feedback from 'assets/images/lps/boostprofile.svg';
import paymentmethods from 'assets/images/lps/paymentmethods.svg';
import payments from 'assets/images/lps/contractsandinvoices.svg';
import subcontracting from 'assets/images/lps/subcontracting3.svg';
import LPSection, { LPSectionLI, LPSectionTypography, LPSectionUl } from 'components/info/LPSection';
import { Typography } from '@mui/material';

export default function SellerMainPoints() {
  return (
    <>
      <LPSection
        image={marketplace}
        chipText="MARKETPLACE"
        chipBgColor="#FED2C2"
        chipTextColor="#CA3402"
        title="Get discovered on our vetted marketplace"
        backgroundColor="#FAEBE1"
      >
        <>
          <LPSectionTypography>
            No bidding necessary. We&apos;ll match you up with jobs and send you personalised invites too.
            <LPSectionUl>
              <LPSectionLI>Get discovered by new clients that are searching for quality freelancers</LPSectionLI>
              <LPSectionLI>Showcase your work and links in one place</LPSectionLI>
              <LPSectionLI>Support for retainers and fixed price projects - always 100% commission free</LPSectionLI>
            </LPSectionUl>
          </LPSectionTypography>
        </>
      </LPSection>
      <LPSection
        backgroundColor="#F5F3FF"
        image={feedback}
        chipText="PROFILE BOOST"
        imageOnRight={true}
        title="Invoice your existing clients to boost your Shoutt profile"
        chipBgColor="#DCD6FB"
        chipTextColor="#652DD1"
      >
        <>
          <LPSectionTypography>
            You might be new to Shoutt but that doesn’t mean you&apos;re new to doing awesome work.
            <br />
            <LPSectionUl>
              <LPSectionLI>
                Kick start your profile by using our secure payment escrow tools to invoice any of your non-Shoutt
                clients.
              </LPSectionLI>
              <LPSectionLI>
                You’ll gain reviews from clients you already trust, boost your profile and close even more deals.
              </LPSectionLI>
            </LPSectionUl>
          </LPSectionTypography>
        </>
      </LPSection>
      <LPSection
        backgroundColor="#F9FAFB"
        image={subcontracting}
        chipText="SUB-CONTRACTING"
        title="Go beyond solo operator with seamless sub-contracting"
      >
        <>
          <LPSectionTypography>
            With Shoutt you can buy and sell via one account, making it easy to boost your efficiency and free up time
            for things that really move the needle.
            <br />
            <LPSectionUl>
              <LPSectionLI>Hire other freelancers and subcontract work out</LPSectionLI>
              <LPSectionLI>Choose whether you want it to be transparent or in the background</LPSectionLI>
              <LPSectionLI>Maintain full control of your client relationship</LPSectionLI>
            </LPSectionUl>
          </LPSectionTypography>
        </>
      </LPSection>

      <LPSection
        image={payments}
        title="Protect your income and make payment issues a thing of the past"
        backgroundColor="#F0F4FE"
        imageOnRight={true}
      >
        <>
          <LPSectionTypography>
            Invoice your clients via Shoutt and forget about chasing payments and awkward conversations. Always 100%
            commission free.
            <br />
            <LPSectionUl>
              <LPSectionLI>Your clients pay 100% upfront</LPSectionLI>
              <LPSectionLI>Funds are held in escrow and then paid to you</LPSectionLI>
              <LPSectionLI>We provide support if there are any issues</LPSectionLI>
            </LPSectionUl>
            <Typography variant="body2" fontSize={{ xs: '12px', md: '14px' }}>
              * A small Stripe processing fee of 1-3% applies
            </Typography>
          </LPSectionTypography>
          <img src={paymentmethods} loading="lazy" style={{ width: '90%', marginBottom: '16px' }} />
        </>
      </LPSection>
    </>
  );
}

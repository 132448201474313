import { IChatMessageDisplay } from 'global/interfaces/chatMessage';

import Typography from '@mui/material/Typography';
import { ContractType } from 'global/interfaces/contract';
import { countriesWithCurrency } from 'components/common/Select/CountrySelect';

interface IChatMessagePaymentProps extends IChatMessageDisplay {
  currentUserId?: string;
}

export default function ChatMessagePaymentSuccessful(props: IChatMessagePaymentProps): JSX.Element {
  const isSeller = props.currentUserId !== props.contract?.buyerUserId;
  const currencySymbol: string =
    countriesWithCurrency.find(c => c.currency === props.contract?.currency.toUpperCase())?.currencySymbol ?? '';
  return (
    <>
      {props.contract?.contractType == ContractType.FixedPrice &&
        (props.contract?.firstPayment == null || props.contract?.firstPayment) && (
          <Typography variant="body2" color="grey.900">
            Payment of {currencySymbol}
            {props.contract?.amount} has been received for the fixed price contract &quot;{props.contract?.name}&quot;.
            The contract is now in progress.
          </Typography>
        )}
      {props.contract?.contractType == ContractType.Retainer &&
        (props.contract?.firstPayment == null || props.contract?.firstPayment) && (
          <Typography variant="body2" color="grey.900">
            The first payment of {currencySymbol}
            {props.contract?.amount} has been received for the retainer contract &quot;{props.contract?.name}&quot;. The
            contract is now in progress.
          </Typography>
        )}
      {props.contract?.contractType == ContractType.Retainer &&
        props.contract?.firstPayment != null &&
        !props.contract?.firstPayment && (
          <Typography variant="body2" color="grey.900">
            {props.contract?.recurrence} payment of {currencySymbol}
            {props.contract?.amount} has been received for the retainer contract &quot;{props.contract?.name}&quot;.
          </Typography>
        )}
      {isSeller && props.contract?.contractType == ContractType.FixedPrice && (
        <Typography variant="caption" color="grey.500" fontStyle="italic" fontWeight={600}>
          You can submit work for approval using the deliverables icon or option when uploading files.
        </Typography>
      )}
    </>
  );
}

import { FormGroup, Grid, Stack, styled, useMediaQuery, useTheme } from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import { UserProfileTabsValue } from 'global/enums/userProfileEnum';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import SendIcon from '@mui/icons-material/Send';

import { FormikProps } from 'formik';
import { IEditableUser } from 'global/interfaces/user';
import { VettingStatus } from 'global/enums/vettingStatus';
import { isAdminEdit } from 'utils/adminUtil';

interface IButtonProps {
  isMobileScreen: boolean;
  activeTab?: UserProfileTabsValue;
  disabled?: boolean;
  onClick?: () => void;
}

interface IProfileFormButtonsProps {
  form: FormikProps<IEditableUser>;
  isApplyPhase: boolean;
  activeTab: UserProfileTabsValue;
  onCancel: () => void;
  onBack: () => void;
  onNext: () => void;
  saveAndSubmit: (submit: boolean) => void;
  saveAsDraft: () => void;
}

const SubmitButton = styled(RoundButton)`
  color: ${({ theme }) => theme.palette.common.white};
`;

const StyledSaveDraftButton = styled(RoundButton)(({ theme }) => ({
  color: theme.palette.primary.dark,
}));

const BackButton = ({ activeTab, isMobileScreen, onClick }: IButtonProps) => (
  <Grid item xs={isMobileScreen && 6}>
    <FormGroup>
      <RoundButton
        fullWidth={isMobileScreen}
        disabled={UserProfileTabsValue.Profile === activeTab}
        variant="outlined"
        size="large"
        onClick={onClick}
        startIcon={<ArrowBackIosNewOutlinedIcon />}
      >
        Back
      </RoundButton>
    </FormGroup>
  </Grid>
);

const NextButton = ({ isMobileScreen, onClick }: IButtonProps) => (
  <Grid item xs={isMobileScreen && 6}>
    <FormGroup>
      <RoundButton
        fullWidth={isMobileScreen}
        variant="contained"
        size="large"
        onClick={onClick}
        endIcon={<ArrowForwardIosOutlinedIcon />}
      >
        Next
      </RoundButton>
    </FormGroup>
  </Grid>
);

const CancelButton = ({ isMobileScreen, onClick }: IButtonProps) => (
  <Grid item xs={isMobileScreen && 6}>
    <FormGroup>
      <RoundButton fullWidth={isMobileScreen} variant="outlined" size="large" onClick={onClick}>
        Cancel
      </RoundButton>
    </FormGroup>
  </Grid>
);

const SaveButton = ({ isMobileScreen, onClick }: IButtonProps) => (
  <Grid item xs={isMobileScreen && 6}>
    <FormGroup>
      <RoundButton fullWidth={isMobileScreen} variant="contained" size="large" onClick={onClick}>
        Save
      </RoundButton>
    </FormGroup>
  </Grid>
);

const SaveDraftButton = ({ onClick }: IButtonProps) => (
  <Grid item>
    <FormGroup row={true} sx={{ justifyContent: 'center' }}>
      <StyledSaveDraftButton variant="outlined" size="large" onClick={onClick}>
        Save as Draft
      </StyledSaveDraftButton>
    </FormGroup>
  </Grid>
);

const SubmitForApprovalButton = ({ isMobileScreen, onClick, disabled }: IButtonProps) => (
  <Grid item xs={isMobileScreen && 6}>
    <FormGroup>
      <SubmitButton
        color="primary"
        variant="contained"
        size="large"
        fullWidth={isMobileScreen}
        disabled={disabled}
        startIcon={<SendIcon />}
        onClick={onClick}
      >
        {isMobileScreen ? 'Submit' : 'Submit Application'}
      </SubmitButton>
    </FormGroup>
  </Grid>
);

export default function ProfileFormButtons({
  form,
  isApplyPhase,
  activeTab,
  onBack,
  onCancel,
  onNext,
  saveAndSubmit,
  saveAsDraft,
}: IProfileFormButtonsProps) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const adminEdit: boolean = isAdminEdit();

  const submitViable: boolean =
    (isApplyPhase ||
      form.values.vetting.status === VettingStatus.UpdatesRequired ||
      (form.values.vetting.status == VettingStatus.NotSubmitted && (form.values.needsVettingValidation ?? false))) &&
    form.values.isSellingServices &&
    !adminEdit;

  const isLastTab =
    (form.values.isSellingServices && activeTab === UserProfileTabsValue.WorkHistory) ||
    (!form.values.isSellingServices && activeTab === UserProfileTabsValue.Portfolio);

  const showNavButtons = isApplyPhase && !isLastTab;

  return (
    <Grid container justifyContent="right" mt={5} spacing={1}>
      <Stack width="100%" direction={isMobileScreen ? 'column' : 'row'} gap={2} justifyContent="flex-end">
        <Stack direction={'row'} gap={2} width="100%" justifyContent={submitViable ? 'space-between' : 'flex-end'}>
          {!isMobileScreen && submitViable && <SaveDraftButton isMobileScreen={isMobileScreen} onClick={saveAsDraft} />}
          {showNavButtons && (
            <Stack direction={'row'} gap={2} width={isMobileScreen ? '100%' : 'auto'}>
              <BackButton isMobileScreen={isMobileScreen} activeTab={activeTab} onClick={onBack} />
              <NextButton isMobileScreen={isMobileScreen} onClick={onNext} />
            </Stack>
          )}
          {!showNavButtons && (
            <Stack direction={'row'} gap={2} width={isMobileScreen ? '100%' : 'auto'}>
              <CancelButton isMobileScreen={isMobileScreen} onClick={onCancel} />
              {!submitViable && <SaveButton isMobileScreen={isMobileScreen} onClick={() => saveAndSubmit(false)} />}
              {submitViable && (
                <SubmitForApprovalButton
                  isMobileScreen={isMobileScreen}
                  disabled={!form.values.isSellingServices}
                  onClick={() => saveAndSubmit(true)}
                />
              )}
            </Stack>
          )}
        </Stack>
        {isMobileScreen && submitViable && <SaveDraftButton isMobileScreen={isMobileScreen} onClick={saveAsDraft} />}
      </Stack>
    </Grid>
  );
}

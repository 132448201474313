import { Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import ItemEarnings from './ItemEarnings';
import ItemEscrow from './ItemEscrow';
import ItemConnections from './ItemConnections';
import ItemActionRequired from './ItemActionRequired';
import BiteSizeTips from './BiteSizeTips/BiteSizeTips';
import { IFrontUser } from 'global/interfaces/user';

interface IMainSectionProps {
  user: IFrontUser;
}

export default function MainSection(props: IMainSectionProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid container mt={2} spacing={2}>
      {isMobile && (
        <Grid item container xs={12} md={4}>
          <Stack direction="column" rowGap={2} width="100%">
            <ItemActionRequired />
            <ItemEscrow />
          </Stack>
        </Grid>
      )}
      <Grid item container xs={12} md={4}>
        <Stack direction="column" rowGap={2} width="100%">
          <ItemEarnings user={props.user} />

          <ItemConnections />
        </Stack>
      </Grid>

      {!isMobile && (
        <Grid item container xs={12} md={4}>
          <Stack direction="column" rowGap={2} width="100%">
            <ItemActionRequired />
            <ItemEscrow />
          </Stack>
        </Grid>
      )}

      <Grid item container xs={12} md={4}>
        <BiteSizeTips />
      </Grid>
    </Grid>
  );
}

import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { SelectItem } from 'global/interfaces/selects';

interface CountrySelectProps {
  label: string;
  error?: boolean;
  helperText?: string;
  size: 'small' | 'medium';
  onChange?: (event: React.SyntheticEvent, value: { label: string; code: string } | null) => void;
  value?: string;
  disabled?: boolean;
}

export default function CountrySelect(props: CountrySelectProps): JSX.Element {
  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option.code === value.code && option.label === value.label}
      value={countryCodeValues[props.value ?? ''] ?? null}
      options={countryList}
      onChange={props.onChange}
      autoHighlight
      size={props.size || 'medium'}
      getOptionLabel={option => option.label ?? ''}
      disabled={props.disabled}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.label}
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          label={props.label}
          error={props.error}
          helperText={props.helperText}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

interface CountryType {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
  currency?: string;
  currencySymbol?: string;
  conversionRate?: number; //Conversion rate to usd must match the backend for each currency in Constants.cs
}

const gbpToUsdRate = 1.27;
const eurToUsdRate = 1.17;

export const fallbackContractCountry = {
  currency: 'USD',
  currencySymbol: '$',
  code: 'US',
};

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
// EU list from https://www.gov.uk/eu-eea#:~:text=The%20EU%20countries%20are%3A,%2C%20Slovenia%2C%20Spain%20and%20Sweden
export const countries: readonly CountryType[] = [
  {
    code: 'AT',
    label: 'Austria',
    phone: '43',
    currency: 'EUR',
    currencySymbol: '€',
    conversionRate: eurToUsdRate,
  },
  {
    code: 'AU',
    label: 'Australia',
    phone: '61',
    suggested: true,
  },
  {
    code: 'BE',
    label: 'Belgium',
    phone: '32',
    currency: 'EUR',
    currencySymbol: '€',
    conversionRate: eurToUsdRate,
  },
  { code: 'BG', label: 'Bulgaria', phone: '359', currency: 'EUR', currencySymbol: '€', conversionRate: eurToUsdRate },
  {
    code: 'CA',
    label: 'Canada',
    phone: '1',
    suggested: true,
  },
  { code: 'HR', label: 'Croatia', phone: '385', currency: 'EUR', currencySymbol: '€', conversionRate: eurToUsdRate },
  {
    code: 'CY',
    label: 'Cyprus',
    phone: '357',
    currency: 'EUR',
    currencySymbol: '€',
    conversionRate: eurToUsdRate,
  },
  {
    code: 'CZ',
    label: 'Czech Republic',
    phone: '420',
    currency: 'EUR',
    currencySymbol: '€',
    conversionRate: eurToUsdRate,
  },
  { code: 'DK', label: 'Denmark', phone: '45', currency: 'EUR', currencySymbol: '€', conversionRate: eurToUsdRate },
  {
    code: 'EE',
    label: 'Estonia',
    phone: '372',
    currency: 'EUR',
    currencySymbol: '€',
    conversionRate: eurToUsdRate,
  },
  {
    code: 'FI',
    label: 'Finland',
    phone: '358',
    currency: 'EUR',
    currencySymbol: '€',
    conversionRate: eurToUsdRate,
  },
  {
    code: 'FR',
    label: 'France',
    phone: '33',
    suggested: true,
    currency: 'EUR',
    currencySymbol: '€',
    conversionRate: eurToUsdRate,
  },
  {
    code: 'DE',
    label: 'Germany',
    phone: '49',
    suggested: true,
    currency: 'EUR',
    currencySymbol: '€',
    conversionRate: eurToUsdRate,
  },
  { code: 'GI', label: 'Gibraltar', phone: '350' },
  {
    code: 'GR',
    label: 'Greece',
    phone: '30',
    currency: 'EUR',
    currencySymbol: '€',
    conversionRate: eurToUsdRate,
  },
  { code: 'HK', label: 'Hong Kong', phone: '852' },
  { code: 'HU', label: 'Hungary', phone: '36', currency: 'EUR', currencySymbol: '€', conversionRate: eurToUsdRate },
  {
    code: 'IE',
    label: 'Ireland',
    phone: '353',
    currency: 'EUR',
    currencySymbol: '€',
    conversionRate: eurToUsdRate,
  },
  {
    code: 'IT',
    label: 'Italy',
    phone: '39',
    currency: 'EUR',
    currencySymbol: '€',
    conversionRate: eurToUsdRate,
  },
  {
    code: 'JP',
    label: 'Japan',
    phone: '81',
    suggested: true,
  },
  {
    code: 'LV',
    label: 'Latvia',
    phone: '371',
    currency: 'EUR',
    currencySymbol: '€',
    conversionRate: eurToUsdRate,
  },
  {
    code: 'LI',
    label: 'Liechtenstein',
    phone: '423',
    currency: 'EUR',
    currencySymbol: '€',
    conversionRate: eurToUsdRate,
  },
  {
    code: 'LT',
    label: 'Lithuania',
    phone: '370',
    currency: 'EUR',
    currencySymbol: '€',
    conversionRate: eurToUsdRate,
  },
  {
    code: 'LU',
    label: 'Luxembourg',
    phone: '352',
    currency: 'EUR',
    currencySymbol: '€',
    conversionRate: eurToUsdRate,
  },
  {
    code: 'MT',
    label: 'Malta',
    phone: '356',
    currency: 'EUR',
    currencySymbol: '€',
    conversionRate: eurToUsdRate,
  },
  { code: 'MX', label: 'Mexico', phone: '52' },
  {
    code: 'NL',
    label: 'Netherlands',
    phone: '31',
    currency: 'EUR',
    currencySymbol: '€',
    conversionRate: eurToUsdRate,
  },
  { code: 'NZ', label: 'New Zealand', phone: '64' },
  { code: 'NO', label: 'Norway', phone: '47' },
  { code: 'PL', label: 'Poland', phone: '48' },
  {
    code: 'PT',
    label: 'Portugal',
    phone: '351',
    currency: 'EUR',
    currencySymbol: '€',
    conversionRate: eurToUsdRate,
  },
  { code: 'RO', label: 'Romania', phone: '40', currency: 'EUR', currencySymbol: '€', conversionRate: eurToUsdRate },
  { code: 'SG', label: 'Singapore', phone: '65' },
  { code: 'SK', label: 'Slovakia', phone: '421', currency: 'EUR', currencySymbol: '€', conversionRate: eurToUsdRate },
  {
    code: 'SI',
    label: 'Slovenia',
    phone: '386',
    currency: 'EUR',
    currencySymbol: '€',
    conversionRate: eurToUsdRate,
  },
  {
    code: 'ES',
    label: 'Spain',
    phone: '34',
    currency: 'EUR',
    currencySymbol: '€',
    conversionRate: eurToUsdRate,
  },
  { code: 'SE', label: 'Sweden', phone: '46', currency: 'EUR', currencySymbol: '€', conversionRate: eurToUsdRate },
  { code: 'CH', label: 'Switzerland', phone: '41' },
  { code: 'TH', label: 'Thailand', phone: '66' },
  { code: 'AE', label: 'United Arab Emirates', phone: '971', suggested: true },
  {
    code: 'GB',
    label: 'United Kingdom',
    phone: '44',
    suggested: true,
    currency: 'GBP',
    currencySymbol: '£',
    conversionRate: gbpToUsdRate,
  },
  {
    code: 'US',
    label: 'United States',
    phone: '1',
    suggested: true,
    currency: fallbackContractCountry.currency,
    currencySymbol: fallbackContractCountry.currencySymbol,
    conversionRate: 1,
  },
];

const countryList: ReadonlyArray<{ label: string; code: string }> = countries.map(function (i) {
  return {
    code: i.code,
    label: i.label,
  };
});

const countryCodeValues: { [key: string]: { label: string; code: string } } = Object.assign(
  {},
  ...countries.map(x => ({ [x.code]: { label: x.label, code: x.code } })),
);

/**
Filters the list of countries to include only those with unique currency and currency symbol.
@typedef {Object} CountryType - Represents a country with optional currency and currency symbol properties.
@property {string} name - The name of the country.
@property {string} [currency] - The currency of the country.
@property {string} [currencySymbol] - The symbol of the currency.
*/
/**

A list of countries with unique currency and currency symbol.
@constant
@type {CountryType[]}
/
export const countriesWithCurrency = countries.filter(/*
Filter callback to determine if the country has a unique currency and currency symbol.
@param {CountryType} country - The current country being processed.
@param {number} index - The index of the current country being processed.
@param {CountryType[]} self - The array of all countries.
@returns {boolean} - Returns true if the country has a unique currency and currency symbol.
*/
export const countriesWithCurrency: CountryType[] = countries.filter((country, index, self) => {
  if (country.currency && country.currencySymbol) {
    return (
      index === self.findIndex(c => c.currency === country.currency && c.currencySymbol === country.currencySymbol)
    );
  }
  return false;
});

export const getCountryCurrencySelectItems = () => {
  const arr: SelectItem[] = [];
  for (const country of countries) {
    if (country.currency && country.currencySymbol && !arr.find(x => x.id === country.currency)) {
      arr.push({
        id: country.currency!,
        label: country.currency!,
      });
    }
  }

  return arr;
};

export const getCurrencyDetails = (contractCurrency: string) => {
  const countryWithCurrency =
    countriesWithCurrency.find(c => c.currency === contractCurrency) !== undefined
      ? countriesWithCurrency.find(c => c.currency === contractCurrency)
      : fallbackContractCountry;

  return countryWithCurrency;
};

export const getCurrencySymbol = (contractCurrency: string) => {
  const countryWithCurrency = countriesWithCurrency.find(c => c.currency === contractCurrency);
  if (countryWithCurrency !== undefined) {
    return countryWithCurrency.currencySymbol ?? countryWithCurrency.currency;
  }
  return contractCurrency;
};

export const getCurrencySymbolByCountryCode = (countryCode: string) =>
  countries.find(c => c.code === countryCode)?.currencySymbol;

// import { Link, useNavigate } from 'react-router-dom'
import Typography from '@mui/material/Typography';
import { WelcomeGraphicContainer } from 'components/common/WelcomeGraphicContainer';
import { Box, Button, styled, useMediaQuery, useTheme } from '@mui/material';
import { useTitle } from 'utils/router';

const ContainerBox = styled(Box)(() => ({
  paddingLeft: '24px',
  paddingRight: '24px',
  maxWidth: '400px',
}));

const StyledHomeButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary[600],
  marginLeft: '-16px',
}));

function ErrorPage() {
  useTitle('Error');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <WelcomeGraphicContainer>
      <ContainerBox>
        <Typography textAlign="center" variant={isMobile ? 'h5' : 'h4'} color={theme.palette.grey[900]} marginTop={1}>
          Sorry
        </Typography>
        <Typography textAlign="center" variant="body1" color={theme.palette.grey[500]} marginTop={2}>
          Something has went wrong. Please contact support@shoutt.co if this reoccurs.
        </Typography>
        <Box marginTop={2} display="flex" justifyContent="center">
          <StyledHomeButton size="medium" variant="text" onClick={() => (window.location.href = '/')}>
            Return to home page
          </StyledHomeButton>
        </Box>
      </ContainerBox>
    </WelcomeGraphicContainer>
  );
}

export default ErrorPage;

import { GlobalStateContext } from 'contexts/GlobalStateContext';
import { DependencyList, useContext, useEffect } from 'react';
import { Location, NavigateFunction } from 'react-router-dom';

export const redirectToLogin = (navigate: NavigateFunction, location: Location) => {
  navigate('/login', { replace: true, state: { redirectTo: location } });
};

export const redirectToSignUp = (navigate: NavigateFunction, location: Location) => {
  navigate('/signup/details?accountType=0', { replace: true, state: { redirectTo: location } });
};

type RedirectLocationState = {
  redirectTo: Location;
};

export const getRedirectUrl = (location: Location) => {
  if (location.state) {
    const { redirectTo } = location.state as RedirectLocationState;
    return `${redirectTo.pathname}${redirectTo.search}`;
  }
  return '/';
};

export const useTitle = (title?: string, deps: DependencyList = []) => {
  const { titlePrefix } = useContext(GlobalStateContext);
  useEffect(() => {
    const prefix = titlePrefix !== '' ? `${titlePrefix} ` : titlePrefix;
    if (title?.startsWith('Shoutt')) {
      document.title = `${prefix}${title}`;
    } else {
      const subTitle = title ? `${title} | ` : '';
      document.title = `${prefix}${subTitle}Shoutt`;
    }
  }, [...deps, titlePrefix]);
};

import * as React from 'react';
import Typography from '@mui/material/Typography';
import {
  IContractPaymentSecret,
  ContractPaymentSecretType,
  ContractType,
  RecurrenceType,
} from 'global/interfaces/contract';
import { getContractInvoiceSecret, getContractPaymentSecret } from 'services/contractService';
import IApiError from 'global/interfaces/api';
import { showError } from 'utils/errorHandler';
import Box from '@mui/material/Box';
import { Dialog, DialogContentText, Grid, styled, useMediaQuery, useTheme } from '@mui/material';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import { ViewContractIcon } from 'components/icon/ViewContractIcon';
import { BillingAddressDialog } from 'components/common/Stripe/BillingAddressDialog';
import { FormTermsAndConditions } from 'components/common/form/FormTermsAndConditions';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import { RoundButton } from 'components/common/Button/RoundButton';
import { LoadingButton } from '@mui/lab';
import useProgressBar from 'global/hooks/useProgressBar';
import { pushToDataLayer } from 'utils/tagHelper';
import { beginCheckoutEvent } from 'global/constants';

interface IContractPaymentButtonProps {
  contractId: string;
  contractName: string;
  firstPayment: boolean;
  contractType: ContractType;
  largeWidth?: number;
  recurrence?: RecurrenceType;
}

const StyledFormTermsAndConditions = styled(FormTermsAndConditions)(() => ({
  marginBottom: '8px',
}));

export const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  padding: '12px 18px',
  borderRadius: theme.spacing(6),
  height: '35px',
}));

export default function ContractPaymentButtons(props: IContractPaymentButtonProps): JSX.Element {
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [secret, setSecret] = React.useState<IContractPaymentSecret | undefined>(undefined);
  const [billingDialogOpen, setBillingDialogOpen] = React.useState<boolean>(false);
  const [isRequestInvoice, setIsRequestInvoice] = React.useState<boolean>(false);

  const [showConfirmDialog, setShowConfirmDialog] = React.useState<boolean>(false);

  const [termsAccepted, setTermsAccepted] = React.useState<boolean>();

  const [progress, showProgress] = useProgressBar();

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const recurrenceText = props.recurrence === RecurrenceType.Monthly ? 'month' : 'week';

  const handleMakePaymentClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    event.preventDefault();
    if (termsAccepted) {
      setIsRequestInvoice(false);
      if (props.contractType === ContractType.Retainer && props.firstPayment) {
        setShowConfirmDialog(true);
      } else {
        makePayment();
      }
    } else {
      setTermsAccepted(false);
    }
  };

  const handleRequestInvoiceClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    event.preventDefault();
    if (termsAccepted) {
      setIsRequestInvoice(true);
      setShowConfirmDialog(true);
    } else {
      setTermsAccepted(false);
    }
  };

  const requestInvoice = (): void => {
    setShowConfirmDialog(false);
    setBillingDialogOpen(false);
    showProgress(true);
    getContractInvoiceSecret(props.contractId)
      .then((res: IContractPaymentSecret) => {
        if (res.type === ContractPaymentSecretType.Url) {
          pushToDataLayer(beginCheckoutEvent, {
            transaction_id: props.contractId,
          });
          setSecret(res);
          window.location.href = res.value;
          showProgress(false);
        } else if (res.type === ContractPaymentSecretType.AddressRequired) {
          showProgress(false);
          setBillingDialogOpen(true);
        } else {
          showProgress(false);
          setSecret(res);
          setDialogOpen(true);
        }
      })
      .catch((err: IApiError) => {
        showProgress(false);
        showError(err);
      });
  };

  const makePayment = (): void => {
    setShowConfirmDialog(false);
    setBillingDialogOpen(false);
    showProgress(true);
    getContractPaymentSecret(props.contractId)
      .then((res: IContractPaymentSecret) => {
        if (res.type === ContractPaymentSecretType.Url) {
          pushToDataLayer(beginCheckoutEvent, {
            transaction_id: props.contractId,
          });
          window.location.href = res.value;
          showProgress(false);
        } else if (res.type === ContractPaymentSecretType.AddressRequired) {
          showProgress(false);
          setBillingDialogOpen(true);
        } else {
          showProgress(false);
          setSecret(res);
          setDialogOpen(true);
        }
      })
      .catch((err: IApiError) => {
        showProgress(false);
        showError(err);
      });
  };

  const handleDialogClose = (): void => {
    setDialogOpen(false);
  };

  const handleConfirmDialogClose = (): void => {
    setShowConfirmDialog(false);
  };

  const handleBillingCancel = (): void => {
    setBillingDialogOpen(false);
  };

  return (
    <>
      <Box>
        <StyledFormTermsAndConditions
          isAccepted={termsAccepted ?? false}
          onChange={(_, checked) => setTermsAccepted(checked)}
          touched={termsAccepted != undefined}
          error={!termsAccepted}
          isSeller={false}
        />
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          {props.firstPayment && (
            <Grid item xs={6} lg={props.largeWidth ?? 4}>
              <StyledLoadingButton
                variant="contained"
                type="button"
                fullWidth
                size="small"
                onClick={e => handleMakePaymentClick(e)}
                startIcon={<DoneAllOutlinedIcon />}
              >
                Pay Now
              </StyledLoadingButton>
            </Grid>
          )}

          <Grid item xs={6} lg={props.largeWidth ?? 4}>
            <StyledLoadingButton
              variant={!props.firstPayment ? 'contained' : 'outlined'}
              type="button"
              size="small"
              fullWidth
              startIcon={!props.firstPayment ? <DoneAllOutlinedIcon /> : <ViewContractIcon />}
              onClick={e => handleRequestInvoiceClick(e)}
            >
              Pay via Invoice
            </StyledLoadingButton>
          </Grid>
        </Grid>
      </Box>
      <BillingAddressDialog
        handleCancel={handleBillingCancel}
        afterSaveCompletes={isRequestInvoice ? requestInvoice : makePayment}
        open={billingDialogOpen}
        message="You must provide a billing address prior to making payment"
      />
      <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="xs" fullScreen={isMobileScreen}>
        {secret?.type === ContractPaymentSecretType.AlreadyPaid && (
          <React.Fragment>
            <StyledDialogTitle>
              <Typography variant="h6" color="grey.900">
                Already Paid
              </Typography>
            </StyledDialogTitle>
            <StyledDialogContent dividers>
              <DialogContentText>
                <Typography variant="body1">{`You have already paid contract ${props.contractName}`}</Typography>
              </DialogContentText>
            </StyledDialogContent>
            <StyledDialogActions>
              <RoundButton onClick={handleDialogClose} autoFocus>
                OK
              </RoundButton>
            </StyledDialogActions>
          </React.Fragment>
        )}
        {secret?.type === ContractPaymentSecretType.Cancelled && (
          <React.Fragment>
            <StyledDialogTitle>
              <Typography variant="h6" color="grey.900">
                Cancelled
              </Typography>
            </StyledDialogTitle>
            <StyledDialogContent dividers>
              <DialogContentText>
                <Typography variant="body1">{`Contract ${props.contractName} has been cancelled and no longer requires payment.`}</Typography>
              </DialogContentText>
            </StyledDialogContent>
            <StyledDialogActions>
              <RoundButton variant="contained" onClick={handleDialogClose} autoFocus>
                Got It
              </RoundButton>
            </StyledDialogActions>
          </React.Fragment>
        )}
      </Dialog>
      <Dialog open={showConfirmDialog} onClose={handleConfirmDialogClose} maxWidth="xs" fullScreen={isMobileScreen}>
        <React.Fragment>
          <StyledDialogTitle>
            <Typography variant="h6" color="grey.900">
              Confirm
            </Typography>
          </StyledDialogTitle>
          <StyledDialogContent dividers>
            <DialogContentText>
              {props.contractType == ContractType.Retainer && props.firstPayment && (
                <Typography variant="body1">
                  {isRequestInvoice
                    ? `You will be emailed an invoice each ${recurrenceText}, which you can pay with via the seller's enabled payment methods. Payments should be made within 7 days in order to keep the contract running smoothly. The invoice will open in the current tab so you can pay it directly or download a PDF.`
                    : `Your card will be automatically debited each ${recurrenceText} until the end of the contract. You can cancel the contract at anytime to prevent further payments. Your funds will be held in escrow according to the agreed release schedule.`}
                </Typography>
              )}
              {(props.contractType == ContractType.FixedPrice ||
                (props.contractType == ContractType.Retainer && !props.firstPayment)) && (
                <Typography variant="body1">
                  {`The invoice will open in the current tab so you can pay it directly or download a PDF. You will also be emailed the invoice to your registered email address.`}
                </Typography>
              )}
            </DialogContentText>
          </StyledDialogContent>
          <StyledDialogActions>
            <RoundButton onClick={handleConfirmDialogClose} variant="outlined">
              Cancel
            </RoundButton>
            <RoundButton onClick={isRequestInvoice ? requestInvoice : makePayment} variant="contained" autoFocus>
              Continue
            </RoundButton>
          </StyledDialogActions>
        </React.Fragment>
      </Dialog>
      {progress}
    </>
  );
}

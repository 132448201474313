import { Box, styled, Typography } from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.primary[50],
  borderRadius: '12px',
  padding: '16px 20px 16px 20px',
  position: 'relative',
}));

interface IActionBoxProps {
  title: string;
  body: string;
  children: React.ReactNode;
}

export default function ActionBox(props: IActionBoxProps) {
  return (
    <StyledBox>
      <Typography variant={'h6'} fontSize="18px">
        {props.title}
      </Typography>
      <Typography variant="body2" mt={1} mb={2} pb={'32px'}>
        {props.body}
      </Typography>
      <Box sx={{ position: 'absolute', bottom: '20px' }}>{props.children}</Box>
    </StyledBox>
  );
}

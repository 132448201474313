import { authorizedPostForm } from './baseApiService';
import { nameof } from 'ts-simple-nameof';
import { IConciergeForm } from 'global/interfaces/concierge';

const resource = 'Concierge';

export async function sendConciergeDetails(conciergeDetails: IConciergeForm) {
  const formData = new FormData();
  formData.append(
    nameof<IConciergeForm>(x => x.name),
    conciergeDetails.name,
  );
  formData.append(
    nameof<IConciergeForm>(x => x.email),
    conciergeDetails.email,
  );
  formData.append(
    nameof<IConciergeForm>(x => x.description),
    conciergeDetails.description,
  );
  formData.append(
    nameof<IConciergeForm>(x => x.title),
    conciergeDetails.title,
  );
  formData.append(
    nameof<IConciergeForm>(x => x.budget),
    conciergeDetails.budget,
  );
  formData.append(
    nameof<IConciergeForm>(x => x.currency),
    conciergeDetails.currency,
  );
  if (conciergeDetails.userId) {
    formData.append(
      nameof<IConciergeForm>(x => x.userId),
      conciergeDetails.userId,
    );
  }
  if (conciergeDetails.token) {
    formData.append(
      nameof<IConciergeForm>(x => x.token),
      conciergeDetails.token,
    );
  }

  const fileBlob = conciergeDetails.file
    ? new Blob([conciergeDetails.file], { type: conciergeDetails.file.type })
    : null;

  if (fileBlob && conciergeDetails?.file !== null && conciergeDetails.file !== undefined) {
    formData.append(
      nameof<IConciergeForm>(x => x.file),
      fileBlob,
      conciergeDetails.file.name,
    );
  } else {
    formData.append(
      nameof<IConciergeForm>(x => x.file),
      '',
    );
  }

  return await authorizedPostForm(`${resource}`, formData);
}
